import api from '@sar/http'
import service from '@services/out'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  data: any

  get width() {
    return this.sprites.panel.width
  }

  get height() {
    return this.sprites.panel.height + this.sprites.close.height + 6
  }

  get halfWidth() {
    return this.sprites.panel.halfWidth
  }

  get halfHeight() {
    return this.sprites.panel.halfHeight + this.sprites.close.halfHeight + 3
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1445
  }

  renderItem(data) {
    this.data = data

    if(data) {
      this.sprites.content.remove(...this.sprites.content.children)

      this.sprites.additem.alpha = 0
      this.sprites.additem.interactive = false

      const item = this.app.sprite(`${this.game.profiles.oss}/${data.shopUrl}`)

      item.interactive = true
      item.width = this.game.getReal(216, this.scale)
      item.height = this.game.getReal(216, this.scale)

      item.on('tap', () => this.game.modals.items.render(true, 1, true, []).then(data => this.renderItem(data)).catch(e => console.log('取消选择')))

      this.sprites.content.add(item)
    }
  }

  render() {
    const { app, game, scale } = this

    this.data = null

    const main = app.group()
    const content = app.group()

    const mask = app.sprite('shade.png')
    const panel = app.sprite('goout_panel.png')

    const confirm = app.sprite('goout_btn.png')
    const close = app.sprite('close_btn_up.png')
    const store = app.sprite('goout_btn_shop.png')
    const additem = app.sprite('stage_contest_additem.png')

    main.add(panel)
    main.add(close)
    main.add(store)
    main.add(confirm)
    main.add(additem)
    main.add(content)

    mask.interactive = true
    store.interactive = true
    close.interactive = true
    additem.interactive = true
    confirm.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    game.setRealSize(panel, scale * 1.0)
    game.setRealSize(store, scale * 1.0)
    game.setRealSize(close, scale * 1.2)
    game.setRealSize(additem, scale * 1.0)
    game.setRealSize(content, scale * 1.0)
    game.setRealSize(confirm, scale * 1.0)

    store.x = panel.width - store.width - game.getReal(60, scale)
    store.y = panel.height - store.height - game.getReal(400, scale)

    close.x = main.halfWidth - close.halfWidth
    close.y = panel.height + 6

    main.x = game.halfWidth - panel.halfWidth
    main.y = game.halfHeight - panel.halfHeight - close.halfHeight - 3

    additem.x = panel.halfWidth - additem.halfWidth + game.getReal(4, scale)
    additem.y = panel.halfHeight - additem.halfHeight + game.getReal(8, scale)

    content.x = game.getReal(340, scale)
    content.y = game.getReal(640, scale)

    confirm.x = panel.halfWidth - confirm.halfWidth
    confirm.y = panel.height - confirm.height - game.getReal(120, scale)

    game.setAnchor(store)
    game.enableButton(store, () => game.modals.store.render())

    game.setAnchor(close)
    game.enableButton(close, () => this.hide().then(() => this.destroy()), 'close')

    game.setAnchor(additem)
    game.enableButton(additem, () => game.modals.items.render(true, 1, true, []).then(data => this.renderItem(data)).catch(e => console.log('取消选择')))

    game.setAnchor(confirm)
    game.enableButton(confirm, () => service(api).add(this.data ? { shopId: this.data?.shopId } : {}).then(() => game.refreshOutData()).then(() => game.refreshUserData()).then(() => this.hide()).then(() => this.destroy()))

    this.sprites = {
      mask,
      main,
      panel,
      close,
      content,
      additem,
    }

    this.root = app.group(
      mask,
      main,
    )

    return this.show()
  }
}
