import url from '@core/url'
import hexi from '@core/hexi'
import game from '@core/game'
import socket from '@core/socket'

import http from '@sar/http'
import out from '@services/out'
import user from '@services/user'
import applet from '@services/applet'

import cache from '@proxies/cache'
import author from '@proxies/author'
import notify from '@proxies/notify'
import formatter from '@proxies/formatter'

import MatchStage from '@pages/match'
import AnswerStage from '@pages/answer'
import BattleStage from '@pages/battle'
import ContestStage from '@pages/contest'
import HomeStage from '@pages/home'
import ShareStage from '@pages/share'
import OutdoorStage from '@pages/outdoor'
import LoadingStage from '@pages/loading'

import NameDialog from '@modals/name'
import CardDialog from '@modals/card'
import ItemsDialog from '@modals/items'
import RulesDialog from '@modals/rules'
import RewardDialog from '@modals/reward'
import TasksDialog from '@modals/tasks'
import ToastDialog from '@modals/toast'
import GuideDialog from '@modals/guide'
import GooutDialog from '@modals/goout'
import GohomeDialog from '@modals/gohome'
import HelperDialog from '@modals/helper'
import EmailDialog from '@modals/email'
import StarsDialog from '@modals/stars'
import StoreDialog from '@modals/store'
import StatusDialog from '@modals/status'
import SettingDialog from '@modals/setting'
import SpecialDialog from '@modals/special'
import MessageDialog from '@modals/message'
import ExchangeDialog from '@modals/exchange'
import GonehomeDialog from '@modals/gonehome'
import GuidebookDialog from '@modals/guidebook'
import SettlementDialog from '@modals/settlement'

const assets = [
  ...R.times((i: number) => `${game.profiles.prefix}/resources/common_menu_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/common_transfer_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/stage_home_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/stage_share_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/stage_contest_${i}.json`, 3),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/stage_outdoor_${i}.json`, 2),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_task_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_name_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_email_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_items_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_goout_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_guide_${i}.json`, 3),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_store_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_stars_${i}.json`, 2),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_status_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_reward_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_helper_${i}.json`, 4),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_message_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/modal_exchange_${i}.json`, 1),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_rest_${i}.json`, 11),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_stand_${i}.json`, 8),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_sleep_${i}.json`, 17),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_bathe_${i}.json`, 13),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_eat_${i}.json`, 13),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_sad_${i}.json`, 12),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_shy_${i}.json`, 5),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_swim_${i}.json`, 12),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_think_${i}.json`, 10),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_greet_${i}.json`, 6),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_reading_${i}.json`, 12),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_drink_tea_${i}.json`, 15),
  ...R.times((i: number) => `${game.profiles.prefix}/resources/animate_brush_teeth_${i}.json`, 8),

  `${game.profiles.prefix}/music/normal.mp3`,
  `${game.profiles.prefix}/music/contest.mp3`,
  `${game.profiles.prefix}/audios/buy.mp3`,
  `${game.profiles.prefix}/audios/close.mp3`,
  `${game.profiles.prefix}/audios/cutover.mp3`,
  `${game.profiles.prefix}/audios/reward.mp3`,
  `${game.profiles.prefix}/audios/shy.mp3`,
  `${game.profiles.prefix}/audios/tap.mp3`,
  `${game.profiles.prefix}/audios/defeat.mp3`,
  `${game.profiles.prefix}/audios/victory.mp3`,
  `${game.profiles.prefix}/audios/upgrade.mp3`,
  `${game.profiles.prefix}/fonts/JingnanBoBoH.ttf`,
]

url.prefix = R.defaultTo('', APP_ENV.prefix)

const fix = new VConsole()
const hammer = new Hammer(document.body)
const app = hexi(game, assets, load, setup)

http.setting('adapter', game.profiles.adapter)
http.setting('timeout', game.profiles.timeout)

http.useProxy('request', author(game))
http.useProxy('request', formatter(game))

http.useProxy('response', cache(game))
http.useProxy('response', notify(game))

moment.locale('en-au')
moment.locale('zh-cn')

hammer.get('pinch').set({ enable: true })
hammer.get('rotate').set({ enable: true })

hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL })
hammer.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL })

game.url = url
game.app = app
game.api = http
game.hammer = hammer

game.socket = socket
game.utils = app.gameUtilities

game.useStage('match', new MatchStage())
game.useStage('answer', new AnswerStage(app, game))
game.useStage('battle', new BattleStage(app, game))
game.useStage('contest', new ContestStage(app, game))
game.useStage('home', new HomeStage(app, game))
game.useStage('loading', new LoadingStage(app, game))
game.useStage('outdoor', new OutdoorStage(app, game))
game.useStage('share', new ShareStage(app, game))

game.useModal('card', new CardDialog(app, game))
game.useModal('name', new NameDialog(app, game))
game.useModal('items', new ItemsDialog(app, game))
game.useModal('rules', new RulesDialog(app, game))
game.useModal('reward', new RewardDialog(app, game))
game.useModal('email', new EmailDialog(app, game))
game.useModal('tasks', new TasksDialog(app, game))
game.useModal('stars', new StarsDialog(app, game))
game.useModal('store', new StoreDialog(app, game))
game.useModal('toast', new ToastDialog(app, game))
game.useModal('guide', new GuideDialog(app, game))
game.useModal('goout', new GooutDialog(app, game))
game.useModal('helper', new HelperDialog(app, game))
game.useModal('gohome', new GohomeDialog(app, game))
game.useModal('status', new StatusDialog(app, game))
game.useModal('special', new SpecialDialog(app, game))
game.useModal('setting', new SettingDialog(app, game))
game.useModal('message', new MessageDialog(app, game))
game.useModal('exchange', new ExchangeDialog(app, game))
game.useModal('gonehome', new GonehomeDialog(app, game))
game.useModal('guidebook', new GuidebookDialog(app, game))
game.useModal('settlement', new SettlementDialog(app, game))

app.fps = 60
app.border = 'none'
app.backgroundColor = 'black'

app.scaleToWindow()
app.start()

async function load() {
  await game.stages.loading.render()
}

async function setup() {
  const normal = app.sound(`${game.profiles.prefix}/music/normal.mp3`)
  const contest = app.sound(`${game.profiles.prefix}/music/contest.mp3`)

  normal.loop(true)
  contest.loop(true)

  game.music = {
    normal,
    contest,
  }

  const shy = app.sound(`${game.profiles.prefix}/audios/shy.mp3`)
  const tap = app.sound(`${game.profiles.prefix}/audios/tap.mp3`)
  const buy = app.sound(`${game.profiles.prefix}/audios/buy.mp3`)
  const close = app.sound(`${game.profiles.prefix}/audios/close.mp3`)
  const reward = app.sound(`${game.profiles.prefix}/audios/reward.mp3`)
  const cutover = app.sound(`${game.profiles.prefix}/audios/cutover.mp3`)
  const upgrade = app.sound(`${game.profiles.prefix}/audios/upgrade.mp3`)
  const defeat = app.sound(`${game.profiles.prefix}/audios/defeat.mp3`)
  const victory = app.sound(`${game.profiles.prefix}/audios/victory.mp3`)

  shy.loop(false)
  tap.loop(false)
  buy.loop(false)
  close.loop(false)
  reward.loop(false)
  defeat.loop(false)
  victory.loop(false)
  cutover.loop(false)
  upgrade.loop(false)

  game.sound = {
    shy,
    tap,
    buy,
    close,
    reward,
    defeat,
    victory,
    cutover,
    upgrade,
  }

  game.states.music ? game.enableMusic() : game.disableMusic()
  game.states.sound ? game.enableSound() : game.disableSound()

  // 用户静默登陆
  const result = await user(http).login({
    token: url.searchParams.get('token')
  })

  // 获取 jssdk 配置
  // const config = await applet(http).getJsConfig({
  //   params: {
  //     url: encodeURIComponent(url.value.href.split('#').shift()),
  //   }
  // })

  // wx.config({ ...config.data, debug: true, jsApiList: [ 'updateTimelineShareData', 'updateAppMessageShareData' ], openTagList: ['wx-open-launch-weapp'] })
  // wx.config({ ...config.data, debug: true, jsApiList: [ 'updateTimelineShareData', 'updateAppMessageShareData' ], openTagList: ['wx-open-launch-weapp'] })

  game.setIdentity('user', result.data)
  game.setIdentity('identifier', result.data.identifier)

  game.setIdentity('token', url.searchParams.get('token'))
  game.setIdentity('dialog', url.searchParams.get('dialog'))

  await game.refreshOutData()
  await game.refreshUserData()
  await game.refreshFishingData()

  await game.socket.init(result.data.identifier)

  await game.stages.loading.over()
  await game.stages.loading.destroy()

  // 主场景路由
  if(url.name.replace(game.profiles.prefix, '').replace(/\//g, '') == 'contest') {
    await game.stages.contest.render().then(() => contest.play())
  }

  else if(url.name.replace(game.profiles.prefix, '').replace(/\//g, '') == 'outdoor') {
    await game.stages.outdoor.render().then(() => normal.play())
  }

  else {
    await game.stages.home.render().then(() => normal.play())
  }

  // 对话框路由
  if(url.searchParams.get('dialog') == 'name') {
    await game.modals.name.render()
  }

  else if(url.searchParams.get('dialog') == 'tasks') {
    await game.modals.tasks.render()
  }

  else if(url.searchParams.get('dialog') == 'items') {
    await game.modals.items.render()
  }

  else if(url.searchParams.get('dialog') == 'email') {
    await game.modals.email.render()
  }

  else if(url.searchParams.get('dialog') == 'store') {
    await game.modals.store.render()
  }

  else if(url.searchParams.get('dialog') == 'stars') {
    await game.modals.stars.render()
  }

  else if(url.searchParams.get('dialog') == 'status') {
    await game.modals.status.render()
  }

  // 展示新手指引
  if(game.identity.user.isNew == 1) {
    await game.modals.guide.render()
  }

  /**
   * 轮询是否有奖励可领取, 每 3 秒执行一次
   */
  game.enableClock('update.awards', 3000, () => out(http).status().then(result => {
    const award = R.compose(
      R.lt(0),
      R.prop('count'),
      R.prop('data'),
    )

    const emails = R.compose(
      R.defaultTo(0),
      R.prop('count'),
      R.prop('data'),
    )

    game.setIdentity('award', award(result))
    game.setIdentity('emails', emails(result))
  }))

  /**
   * 轮询外出状态, 每 3 秒执行一次
   */
  game.enableClock('update.out', 3000, () => out(http).list({ type: 0, page: 1, size: 1 }).then(result => {
    const out = R.compose(
      R.nth(0),
      R.prop('list'),
      R.prop('data'),
    )

    const outing = R.compose(
      R.not,
      R.isNil,
      R.nth(0),
      R.prop('list'),
      R.prop('data'),
    )

    game.setIdentity('out', out(result))
    game.setIdentity('outing', outing(result))
  }))

  /**
   * 轮询消息队列
   */
  game.enableClock('update.messages', 3e3, () => socket.send({ type: 'msg', action: 'list', uid: game.identity.user.id }))

  /**
   * 监听消息队列
   */
  socket.listen('msg', R.prop('id'), R.compose(R.head, R.propOr([], 'list')), R.compose(R.not, R.isNil), e => Promise.resolve(socket.received.add(e.id)).then(() => game.modals.gonehome.render(e.content)).then(() => socket.send({ type: 'msg', action: 'update', data: { msgId: e.id } })))
}
