import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Couch extends Component {
  show = R.cond([
    [R.equals('active'), () => this.root.playAnimation([0, 269])],
    [R.equals('normal'), () => this.root.playAnimation([270, 270])],
  ])

  constructor(app, game) {
    super()

    const animate = app.sprite(R.times((i: number) => `animate_sleep_${numeral(i).format('000')}.png`, 271))

    animate.fps = 26
    animate.loop = true

    animate.width = animate.width * game.scale
    animate.height = animate.height * game.scale

    this.app = app
    this.game = game
    this.root = animate

    this.states = mobx.observable({ status: 'normal' })
    this.clear = mobx.reaction(() => mobx.toJS(this.states), states => this.show(states.status))
  }

  onDestroyed() {
    this.clear()
  }
}
