const { reaction, observable } = mobx

const lens = {
  head: R.nth(0),
  tail: R.nth(1),
}

enum Status {
  success,
  processing,
}

export default class View {
  async render(app, game, options) {
    const rollback = app.sprite('stage_contest_btn15.png')

    const frames = [
      app.circle(160, '#363533'),
      app.circle(160, '#363533'),
    ]

    const clips = [
      app.circle(140, '#fff'),
      app.circle(140, '#fff'),
    ]

    const titles = [
      app.text('', 'bold 12px sans', '#333333'),
      app.text('', 'bold 12px sans', '#333333'),
    ]

    const tips = [
      app.sprite('stage_contest_level_panel.png'),
      app.sprite('stage_contest_level_panel.png'),
    ]

    const status = [
      app.text('匹配中...', 'bold 24px sans', '#333333'),
      app.text('匹配成功', 'bold 24px sans', '#333333'),
    ]

    rollback.width = rollback.width * game.scale * 0.8
    rollback.height = rollback.height * game.scale * 0.8

    R.ap([ e => Object.assign(e, { width: e.width * game.scale, height: e.height * game.scale }) ], frames)
    R.ap([ e => Object.assign(e, { width: e.width * game.scale, height: e.height * game.scale }) ], clips)
    R.ap([ e => Object.assign(e, { width: e.width * game.scale, height: e.height * game.scale }) ], tips)

    lens.head(status).visible = true
    lens.tail(status).visible = false

    lens.head(tips).x = 0
    lens.head(tips).y = 0

    lens.tail(tips).x = lens.head(tips).x + lens.head(tips).width + 30
    lens.tail(tips).y = 0

    lens.head(frames).x = lens.head(tips).x + lens.head(tips).halfWidth
    lens.head(frames).y = lens.head(tips).y + lens.head(tips).height + lens.head(frames).halfHeight + 8

    lens.tail(frames).x = lens.tail(tips).x + lens.tail(tips).halfWidth
    lens.tail(frames).y = lens.tail(tips).y + lens.tail(tips).height + lens.tail(frames).halfHeight + 8

    lens.head(clips).x = lens.head(frames).x
    lens.head(clips).y = lens.head(frames).y

    lens.tail(clips).x = lens.tail(frames).x
    lens.tail(clips).y = lens.tail(frames).y

    lens.head(titles).x = lens.head(tips).x + lens.head(tips).halfWidth - lens.head(titles).halfWidth
    lens.head(titles).y = lens.head(tips).y + lens.head(tips).halfHeight - lens.head(titles).halfHeight - 3

    lens.tail(titles).x = lens.tail(tips).x + lens.tail(tips).halfWidth - lens.tail(titles).halfWidth
    lens.tail(titles).y = lens.tail(tips).y + lens.tail(tips).halfHeight - lens.tail(titles).halfHeight - 3

    const element = app.group(
      ...frames,
      ...clips,
      ...tips,
      ...status,
      ...titles,
      rollback,
    )

    lens.head(status).x = element.halfWidth - lens.head(status).halfWidth
    lens.head(status).y = lens.tail(frames).y + lens.tail(frames).height + 16

    lens.tail(status).x = element.halfWidth - lens.tail(status).halfWidth
    lens.tail(status).y = lens.tail(frames).y + lens.tail(frames).height + 16

    rollback.x = element.halfWidth - rollback.halfWidth
    rollback.y = lens.head(status).y + rollback.height + 32

    element.x = game.halfWidth - element.halfWidth
    element.y = game.halfHeight - element.halfHeight

    const states = observable.box(Status.processing)
    const players = observable.array(R.repeat(null, 2))

    const playerElements = []

    const statesReactionClear = reaction(() => states.get(), states => {
      if(states == Status.processing) {
        lens.head(status).visible = true
        lens.tail(status).visible = false
      }

      else {
        lens.head(status).visible = false
        lens.tail(status).visible = true
      }
    })

    const playersReactionClear = reaction(() => players.slice(), players => {
      // 更新名称
      players.forEach((e, index) => {
        if(e == null) {
          R.nth(index, titles).text = '???'
        }

        else {
          R.nth(index, titles).text = e.name
        }

        R.nth(index, titles).x = R.nth(index, tips).x + R.nth(index, tips).halfWidth - R.nth(index, titles).halfWidth
        R.nth(index, titles).y = R.nth(index, tips).y + R.nth(index, tips).halfHeight - R.nth(index, titles).halfHeight - 3
      })

      // 更新头像
      players.forEach((e, index) => {
        if(playerElements[index] != null) app.remove(...playerElements.splice(index, 1, null));

        if(e == null) {
          const elm = app.sprite('stage_contest_icon_match.png')

          elm.width = 180 * game.scale
          elm.height = 180 * game.scale

          elm.x = R.nth(index, clips).x - elm.halfWidth
          elm.y = R.nth(index, clips).y - elm.halfHeight

          element.add(elm)
          playerElements.splice(index, 1, elm)
        }

        else {
          const elm = app.sprite(e.url)

          elm.width = 140 * game.scale
          elm.height = 140 * game.scale

          elm.x = R.nth(index, clips).x - elm.halfWidth
          elm.y = R.nth(index, clips).y - elm.halfHeight

          elm.mask = R.nth(index, clips)

          element.add(elm)
          playerElements.splice(index, 1, elm)
        }
      })
    })

    players.replace([
      {
        id: game.identity.user.id,
        url: game.identity.user.avatarUrl,
        name: game.identity.user.nickname,
      },
      null
    ])

    game.socket.listen('question', R.identity, R.identity, R.T, (e, res) => {
      if(res.status == 0 && e.fid != null && e.favater != null && e.fname != null) {
        game.socket.unsubscribe('question')

        states.set(
          Status.success
        )

        players.replace([
          {
            id: game.identity.user.id,
            url: game.identity.user.avatarUrl,
            name: game.identity.user.nickname,
          },
          {
            id: e.fid,
            url: e.favater,
            name: e.fname,
          }
        ])

        // 1秒后游戏开始
        setTimeout(async () => {
          game.url.push('/contest', {
            fid: e.fid,
            room: e.roomId,
            mode: 0,
            battle: 1,
            token: game.url.searchParams.get('token'),
          })

          game.hide(element).then(() => game.stages.battle.render(options.scale, options.circular, {
            roles: players.slice(),
            items: options.items.slice(),
          })).then(() => {
            app.remove(element)
          })
        }, 1000)
      }
    })

    game.socket.send({
      data: {
        uid: game.identity.user.id,
      },
      type: 'question',
    })

    rollback.interactive = true

    game.setAnchor(rollback)
    game.enableButton(rollback, () => {
      game.socket.unsubscribe('question')

      game.hide(element).then(() => game.stages.battle.render(options.scale, options.circular, {
        roles: players.slice(),
        items: options.items.slice(),
      })).then(() => {
        app.remove(element)
      })
    })

    return game.show(element).then(() => element.on('remove', () => {
      const clear = R.ap([ R.call ])

      clear([
        statesReactionClear,
        playersReactionClear,
      ])
    }))
  }
}
