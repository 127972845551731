import api from '@sar/http'
import service from '@services/user'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1400
  }

  render() {
    const { app, game, scale } = this

    this.states = mobx.observable({
      page: 1,
      finished: false,
    })

    const main = app.group()
    const mask = app.sprite('shade.png')

    const panel = app.sprite('lesson_panel.png')
    const content = app.group(app.sprite(`lesson_page${this.states.page}.png`))

    const prev = app.sprite('lesson_btn1.png')
    const next = app.sprite('lesson_btn2.png')
    const confirm = app.sprite('lesson_btn3.png')

    main.add(panel)
    main.add(prev)
    main.add(next)
    main.add(confirm)
    main.add(content)

    confirm.alpha = 0
    content.alpha = 1

    mask.interactive = true
    next.interactive = true
    prev.interactive = true
    panel.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    game.setRealSize(prev, scale)
    game.setRealSize(next, scale)
    game.setRealSize(panel, scale)
    game.setRealSize(content, scale)
    game.setRealSize(confirm, scale)

    prev.x = panel.x + game.getReal(64, scale)
    prev.y = panel.height - prev.height - game.getReal(64, scale)

    next.x = panel.x + panel.width - next.width - game.getReal(64, scale)
    next.y = panel.height - next.height - game.getReal(64, scale)

    confirm.x = panel.x + panel.halfWidth - confirm.halfWidth
    confirm.y = panel.y + panel.height + game.getReal(32, scale)

    main.x = game.halfWidth - main.halfWidth
    main.y = game.halfHeight - panel.halfHeight - game.getReal(120, scale)

    game.setAnchor(confirm)
    game.enableButton(confirm, () => service(api).setNew({}).then(() => this.hide()).then(() => this.destroy()), 'close')

    game.setAnchor(prev)
    game.enableButton(prev, () => Object.assign(this.states, { page: R.dec(this.states.page) < 1 ? 1 : R.dec(this.states.page) }))

    game.setAnchor(next)
    game.enableButton(next, () => Object.assign(this.states, { page: R.inc(this.states.page) > 5 ? 5 : R.inc(this.states.page) }))

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    this.clear = mobx.reaction(() => this.states.page, async () => {
      await new Promise(resolve => (app.fadeOut(content, 8)).onComplete = () => resolve(null))

      content.remove(...content.children)

      const data = app.sprite(`lesson_page${this.states.page}.png`)

      content.add(data)

      if(this.states.page == 5){
        confirm.interactive = true
        new Promise(resolve => (app.fadeIn(confirm, 8)).onComplete = () => resolve(null))
      }

      new Promise(resolve => (app.fadeIn(content, 8)).onComplete = () => resolve(null))
    })

    return this.show().finally(() => this.root.on('removed', () => this.clear()))
  }
}
