import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Menu extends Component {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game

    const panel = app.sprite('menu_panel.png')
    const putup = app.sprite('menu_btn_putup.png')
    const items = app.sprite('menu_btn_items.png')
    const helper = app.sprite('menu_btn_helper.png')
    const setting = app.sprite('menu_btn_setting.png')
    const exchange = app.sprite('menu_btn_exchange.png')

    putup.interactive = true
    items.interactive = true
    helper.interactive = true
    setting.interactive = true
    exchange.interactive = true

    game.setRealSize(panel, game.scale * 1.0)
    game.setRealSize(putup, game.scale * 0.96)
    game.setRealSize(items, game.scale * 0.96)
    game.setRealSize(helper, game.scale * 0.96)
    game.setRealSize(setting, game.scale * 0.96)
    game.setRealSize(exchange, game.scale * 0.96)

    panel.x = 0
    panel.y = game.getReal(-148)

    exchange.x = panel.halfWidth - exchange.halfWidth - game.getReal(8)
    exchange.y = game.getReal(30)

    items.x = panel.halfWidth - items.halfWidth - game.getReal(8)
    items.y = exchange.y + exchange.height + game.getReal(48)

    setting.x = panel.halfWidth - setting.halfWidth - game.getReal(8)
    setting.y = items.y + items.height + game.getReal(48)

    helper.x = panel.halfWidth - helper.halfWidth - game.getReal(8)
    helper.y = setting.y + setting.height + game.getReal(48)

    putup.x = panel.halfWidth - putup.halfWidth - game.getReal(6)
    putup.y = panel.y + panel.height - putup.height - game.getReal(48)

    game.setAnchor(items)
    game.enableButton(items, () => game.modals.items.render())

    game.setAnchor(helper)
    game.enableButton(helper, () => game.modals.helper.render())

    game.setAnchor(setting)
    game.enableButton(setting, () => game.modals.setting.render())

    game.setAnchor(exchange)
    game.enableButton(exchange, () => game.modals.exchange.render())

    game.setAnchor(putup)
    game.enableButton(putup, () => Object.assign(game.states, { expand: false }))

    this.sprites = {
      panel,
      putup,
      items,
      helper,
      setting,
      exchange,
    }

    this.root = app.group(
      panel,
      putup,
      items,
      helper,
      setting,
      exchange,
    )
  }
}
