import api from '@sar/http'
import service from '@services/out'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  data: any

  get width() {
    return this.sprites.panel.width
  }

  get height() {
    return this.sprites.panel.height + this.sprites.confirm.height + this.sprites.cancel.height + 24
  }

  get halfWidth() {
    return this.sprites.panel.halfWidth
  }

  get halfHeight() {
    return this.sprites.panel.halfHeight + this.sprites.confirm.halfHeight + this.sprites.cancel.halfHeight + 12
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1600
  }

  render(value, type = 'single') {
    const { app, game, scale } = this

    const main = app.group()
    const content = app.group()

    const mask = app.sprite('shade.png')
    const panel = app.sprite((value.status == 'fail' || value.status == 'tie') ? 'stage_contest_solo_lose.png' : 'stage_contest_solo_win.png')

    const cancel = app.sprite('stage_contest_btn9.png')
    const confirm = app.sprite('stage_contest_btn5.png')

    const cover = app.sprite('exchange_icon_shell.png')
    const border = app.roundRectangle(360, 360, 16, '#fff', '#666', 6)

    const lable = app.create(() => new PIXI.Text((
      type == 'single'
        ? (value.status == 'fail' ? '挑战失败' : '通过挑战!!')
        : (value.status == 'fail' ? '失败' : value.status == 'tie' ? '平局' : '胜利')
    ), { fontSize: 64, fontFamily: 'JingnanBoBoH', align: 'center', fill: '#fff', leading: 6 }))

    const exp = app.create(() => new PIXI.Text(`经验+${value.exp}`, { fontSize: 14, align: 'center', fill: '#666', leading: 6 }))
    const shell = app.create(() => new PIXI.Text(`贝壳x${value.shell}`, { fontSize: 14, align: 'center', fill: '#666', leading: 6 }))

    const title = app.create(() => new PIXI.Text((
      type == 'single'
        ? (value.status == 'fail' ? '你的财商知识储备还需要提升\n虽然没有礼物, 但获得了一些经验' : '你的财商知识储备好丰富啊\n这是你应得的礼物')
        : (value.status == 'fail' ? '别气馁\n去训练营积累财商知识再来过吧！' : value.status == 'tie' ? '平手了\n遇到了旗鼓相当的对手呢！' : '恭喜你\n靠丰富的财商知识储备赢得了胜利！')
    ), { fontSize: 14, align: 'center', fill: '#666', leading: 6 }))

    content.add(lable)
    content.add(border)
    content.add(cover)
    content.add(exp)
    content.add(title)
    content.add(shell)

    main.add(panel)
    main.add(cancel)
    main.add(confirm)
    main.add(content)

    mask.visible = true
    content.visible = true

    if(value.shell > 0) {
      cover.visible = true
      border.visible = true
    }

    mask.interactive = true
    cancel.interactive = true
    confirm.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    game.setRealSize(lable, scale)
    game.setRealSize(panel, scale)
    game.setRealSize(border, scale)
    game.setRealSize(cover, scale)
    game.setRealSize(cancel, scale)
    game.setRealSize(confirm, scale)

    lable.x = panel.halfWidth - lable.halfWidth
    lable.y = game.getReal(488, scale)

    title.x = panel.halfWidth - title.halfWidth
    title.y = game.getReal(640, scale)

    border.x = panel.halfWidth - border.halfWidth
    border.y = title.y + title.height + 12

    cover.x = border.x + border.halfWidth - cover.halfWidth
    cover.y = border.y + border.halfHeight - cover.halfHeight

    shell.x = panel.halfWidth - shell.halfWidth
    shell.y = border.y + border.height + 6

    exp.x = panel.halfWidth - exp.halfWidth
    exp.y = shell.y + shell.height + 6

    confirm.x = panel.halfWidth - cancel.halfWidth - 6
    confirm.y = panel.y + panel.height + 12

    cancel.x = panel.halfWidth - cancel.halfWidth - 6
    cancel.y = confirm.y + confirm.height + 12

    main.x = game.halfWidth - panel.halfWidth
    main.y = game.halfHeight - panel.halfHeight - cancel.halfHeight - confirm.halfHeight - 12

    game.setAnchor(cancel)
    game.enableButton(cancel, () => this.hide().then(() => this.destroy()).then(() => value.onCancel()))

    game.setAnchor(confirm)
    game.enableButton(confirm, () => this.hide().then(() => this.destroy()).then(() => value.onConfirm()))

    this.sprites = {
      mask,
      main,
      panel,
      cancel,
      confirm,
    }

    this.root = app.group(
      mask,
      main,
    )

    return this.show()
  }
}
