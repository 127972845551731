import api from '@sar/http'
import service from '@services/out'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1400
  }

  render(index = 1) {
    const { app, game, scale } = this

    const main = app.group()
    const handles = app.group()

    const mask = app.sprite('shade.png')
    const panel = app.sprite('help_panel.png')
    const content = app.sprite(`help_data${index}.png`)
    const close = app.button(['close_btn_up.png', 'close_btn_down.png'])

    main.add(close)
    main.add(panel)
    main.add(content)
    main.add(handles)

    mask.interactive = true
    close.interactive = true
    panel.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    close.width = game.scale * close.width * 1.25
    close.height = game.scale * close.height * 1.25

    game.setRealSize(panel, scale)
    game.setRealSize(content, scale)

    close.x = panel.halfWidth - close.halfWidth
    close.y = panel.height + 6

    main.x = game.halfWidth - main.halfWidth
    main.y = game.halfHeight - panel.halfHeight - close.halfHeight - 3

    game.setAnchor(close)
    game.enableButton(close, () => this.hide().then(() => this.destroy()), 'close')

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    return this.show()
  }
}
