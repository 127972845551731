import api from '@sar/http'
import service from '@services/out'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = game.screenWidth / 1080
  }

  render(data) {
    const { app, game, scale } = this

    const main = app.group()
    const mask = app.rectangle(100, 100, '#000')

    const cover = app.sprite(`${game.profiles.oss}/${data.icon}`)
    const close = app.sprite('collection_details_close_hint.png')

    main.add(close)
    main.add(cover)

    mask.interactive = true
    cover.interactive = true
    close.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    cover.width = 1080 * scale
    cover.height = 810 * scale

    close.width = close.width * scale
    close.height = close.height * scale

    close.x = cover.halfWidth - close.halfWidth
    close.y = cover.y + cover.height + 16

    main.x = 0
    main.y = game.halfHeight - main.halfHeight - 30

    mask.on('tap', () => this.hide().then(() => this.destroy()))
    close.on('tap', () => this.hide().then(() => this.destroy()))

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    return this.show()
  }
}
