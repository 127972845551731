import Menu from '@components/menu'
import Hints from '@components/hints'
import Couch from '@components/couch'
import Table from '@components/table'
import Settee from '@components/settee'
import Lutris from '@components/lutris'
import Bathtub from '@components/bathtub'

import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Page extends Stage {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
  }

  render() {
    const { app, game } = this

    const tub = app.sprite('stage_home_tub.png')
    const mat = app.sprite('stage_home_mat.png')
    const lamp = app.sprite('stage_home_lamp.png')
    const door = app.sprite('stage_home_door.png')
    const desk = app.sprite('stage_home_desk.png')
    const decorate = app.sprite('stage_home_decorate.png')
    const floor = app.sprite('stage_home_floor.png')
    const stage = app.sprite('stage_home_bg.png')
    const shelf = app.sprite('stage_home_shelf.png')
    const stairs = app.sprite('stage_home_stairs.png')
    const shower = app.sprite('stage_home_shower.png')
    const umbrella = app.sprite('stage_home_umbrella.png')
    const chair = app.sprite('stage_home_chair_small.png')
    const conch = app.sprite('stage_home_decorate_small.png')
    const carpet = app.sprite('stage_home_carpet.png')
    const curtains = app.sprite('stage_home_curtains.png')
    const railing = app.sprite('stage_home_railing.png')
    const roundtable = app.sprite('stage_home_table_round.png')
    const papers = app.sprite('stage_home_papers.png')

    const bags = app.sprite([ 'stage_home_bags_full.png', 'stage_home_bags_empty.png' ])
    const window = app.sprite([ 'stage_home_window_day.png', 'stage_home_window_night.png' ])
    const notebook = app.sprite([ 'stage_home_notebook_on.png', 'stage_home_notebook_off.png' ])

    game.setRealSize(bags)
    game.setRealSize(mat)
    game.setRealSize(tub)
    game.setRealSize(door)
    game.setRealSize(desk)
    game.setRealSize(chair)
    game.setRealSize(conch)
    game.setRealSize(carpet)
    game.setRealSize(curtains)
    game.setRealSize(floor)
    game.setRealSize(shelf)
    game.setRealSize(stairs)
    game.setRealSize(shower)
    game.setRealSize(window)
    game.setRealSize(decorate)
    game.setRealSize(umbrella)
    game.setRealSize(railing)
    game.setRealSize(roundtable)
    game.setRealSize(notebook)
    game.setRealSize(papers)
    game.setRealSize(lamp)

    stage.width = stage.width * game.scale * 2
    stage.height = stage.height * game.scale * 2

    mat.x = game.getReal(582)
    mat.y = game.getReal(740)

    tub.x = game.getReal(460)
    tub.y = game.getReal(690)

    door.x = game.getReal(572)
    door.y = game.getReal(970)

    desk.x = game.getReal(630)
    desk.y = game.getReal(1216)

    floor.x = game.getReal(0)
    floor.y = game.getReal(705)

    chair.x = game.getReal(542)
    chair.y = game.getReal(1360)

    conch.x = game.getReal(590)
    conch.y = game.getReal(364)

    carpet.x = game.getReal(400)
    carpet.y = game.getReal(1342)

    curtains.x = game.getReal(772)
    curtains.y = game.getReal(325)

    window.x = game.getReal(215)
    window.y = game.getReal(336)

    shelf.x = game.getReal(128)
    shelf.y = game.getReal(470)

    stairs.x = game.getReal(716)
    stairs.y = game.getReal(796)

    shower.x = game.getReal(50)
    shower.y = game.getReal(305)

    umbrella.x = game.getReal(510)
    umbrella.y = game.getReal(1056)

    decorate.x = game.getReal(77)
    decorate.y = game.getReal(970)

    railing.x = game.getReal(0)
    railing.y = game.getReal(760)

    roundtable.x = game.getReal(326)
    roundtable.y = game.getReal(1138)

    bags.x = game.getReal(62)
    bags.y = game.getReal(1484)

    notebook.x = game.getReal(712)
    notebook.y = game.getReal(1172)

    papers.x = game.getReal(830)
    papers.y = game.getReal(1325)

    lamp.x = game.getReal(932)
    lamp.y = game.getReal(1242)

    door.interactive = true
    decorate.interactive = true
    notebook.interactive = true

    door.on('tap', async () => {
      await this.game.sound.cutover.play()
      await game.stages.home.hide()
      await game.stages.outdoor.render()
      await this.game.music.normal.pause()
      await this.game.music.normal.play()
      await game.stages.home.destroy()
    })

    this.sprites = {
      stage,
      conch,
      carpet,
      shelf,
      shower,
      stairs,
      door,
      desk,
      notebook,
      papers,
      lamp,
      floor,
      railing,
      tub,
      mat,
      curtains,
      umbrella,
      decorate,
      chair,
      roundtable,
      bags,
      window,
    }

    this.root = app.group(
      stage,
      conch,
      carpet,
      shelf,
      shower,
      stairs,
      door,
      desk,
      notebook,
      papers,
      lamp,
      floor,
      railing,
      tub,
      mat,
      curtains,
      umbrella,
      decorate,
      chair,
      roundtable,
      bags,
      window,
    )

    this.root.x = 0
    this.root.y = game.offset

    const menu = new Menu(app, game)
    const hints = new Hints(app, game)
    const couch = new Couch(app, game)
    const table = new Table(app, game)
    const settee = new Settee(app, game)
    const lutris = new Lutris(app, game)
    const bathtub = new Bathtub(app, game)

    menu.x = 0
    menu.y = Math.abs(game.offset)

    hints.x = game.getReal(32)
    hints.y = game.getReal(32)

    couch.x = game.getReal(520)
    couch.y = game.getReal(1600)

    table.x = game.getReal(30)
    table.y = game.getReal(1600)

    settee.x = game.getReal(0)
    settee.y = game.getReal(1060)

    lutris.x = game.getReal(340)
    lutris.y = game.getReal(1360)

    bathtub.x = game.getReal(60)
    bathtub.y = game.getReal(500)

    this.root.add(menu.root)
    this.root.add(hints.root)
    this.root.add(couch.root)
    this.root.add(table.root)
    this.root.add(settee.root)
    this.root.add(lutris.root)
    this.root.add(bathtub.root)

    menu.layer = 2
    hints.layer = 2
    lutris.layer = 2

    couch.layer = 1
    table.layer = 1
    settee.layer = 1
    railing.layer = 1

    couch.interactive = true
    table.interactive = true
    lutris.interactive = true
    settee.interactive = true

    this.states = mobx.observable({
      playing: false,
      status: null,
      timeout: null,
    })

    this.clear = mobx.reaction(() => this.states.status, async () => {
      await Promise.resolve([
        couch.show('normal'),
        table.show('normal'),
        settee.show('normal'),
        lutris.show('normal'),
        bathtub.show('normal'),
      ])

      const dispatch = R.cond([
        [R.equals('sleep'), () => couch.show('active')],
        [R.equals('drink'), () => table.show('drink')],
        [R.equals('stand'), () => lutris.show('stand')],
        [R.equals('sad'), () => settee.show('sad')],
        [R.equals('happy'), () => settee.show('happy')],
        [R.equals('reading'), () => settee.show('reading')],
        [R.equals('eat'), () => table.show('eat').then(() => Object.assign(this.states, { status: 'stand' }))],
        [R.equals('breakfast'), () => table.show('eat').then(() => Object.assign(this.states, { status: 'stand' }))],
        [R.equals('luncheon'), () => table.show('eat').then(() => Object.assign(this.states, { status: 'stand' }))],
        [R.equals('dinner'), () => table.show('eat').then(() => Object.assign(this.states, { status: 'stand' }))],
        [R.equals('bath'), () => bathtub.show('active').then(() => Object.assign(this.states, { status: 'stand' }))],
        [R.equals('brush'), () => lutris.show('brush').then(() => Object.assign(this.states, { status: 'stand' }))],
      ])

      dispatch(this.states.status)
    })

    const normal = R.cond([
      [random => random == 1, R.always('drink')],
      [random => random == 2, R.always('sleep')],
      [random => random == 3, R.always('happy')],
      [random => random == 4, R.always('reading')],
    ])

    const status = R.cond([
      [time => time.isBetween(moment('06:00', 'HH:mm'), moment('07:00', 'HH:mm')), R.always('brush')],
      [time => time.isBetween(moment('07:00', 'HH:mm'), moment('08:00', 'HH:mm')), R.always('breakfast')],
      [time => time.isBetween(moment('12:00', 'HH:mm'), moment('13:00', 'HH:mm')), R.always('luncheon')],
      [time => time.isBetween(moment('15:00', 'HH:mm'), moment('18:00', 'HH:mm')), R.always('dinner')],
      [time => time.isBetween(moment('20:00', 'HH:mm'), moment('21:00', 'HH:mm')), R.always('bath')],
    ])

    const special = R.cond([
      [time => time.isBetween(moment('06:00', 'HH:mm'), moment('07:00', 'HH:mm')), R.always('sad')],
      [time => time.isBetween(moment('07:00', 'HH:mm'), moment('08:00', 'HH:mm')), R.always('sad')],
      [time => time.isBetween(moment('12:00', 'HH:mm'), moment('13:00', 'HH:mm')), R.always('sad')],
      [time => time.isBetween(moment('15:00', 'HH:mm'), moment('18:00', 'HH:mm')), R.always('sad')],
      [time => time.isBetween(moment('20:00', 'HH:mm'), moment('21:00', 'HH:mm')), R.always('sad')],
      [time => time.isBetween(moment('22:00', 'HH:mm'), moment('24:00', 'HH:mm')), R.always('sleep')],
      [time => time.isBetween(moment('00:00', 'HH:mm'), moment('06:00', 'HH:mm')), R.always('sleep')],
    ])

    game.enableClock('clock', 1000 * 60, () => {
      const time = moment()
      const key = status(time)
      const value = special(time)
      const random = app.randomInt(1, 4)

      const data = JSON.parse(localStorage.getItem(`lutris-${moment().format('YYYY-MM-DD')}`) ?? '{}')

      // 出行状态不显示海懒
      if(game.identity?.user?.isOut == 2) {
        couch.show('normal')
        table.show('normal')
        settee.show('normal')
        lutris.show('normal')
        bathtub.show('normal')
      }

      else if(value && data?.[key] != true) {
        if(this.states.playing) {
          return 0
        }

        this.states.status = value

        // 澡盆里加上水
        if(time.isBetween(moment('20:00', 'HH:mm'), moment('21:00', 'HH:mm'))) {
          bathtub.show('ready')
        }
      }

      else if(this.states.status == null) {
        this.states.status = normal(random)
      }
    })

    couch.on('tap', () => this.states.status == 'sleep' ? this.states.status = 'stand' : this.states.status)
    table.on('tap', () => this.states.status == 'drink' ? this.states.status = 'stand' : this.states.status)

    settee.on('tap', () => {
      if(this.states.status == 'happy' || this.states.status == 'reading') {
        this.states.status = 'stand'
      }

      // 特殊状态弹窗
      else if(this.states.status == 'sad'){
        const time = moment()

        if(time.isBetween(moment('06:00', 'HH:mm'), moment('07:00', 'HH:mm'))) {
          game.modals.special.render(
            '该刷牙了',
            '渤仔是不太开心, 因为还没刷牙吗？\n赶紧让他刷刷牙吧！\n不过在这期间就不能外出了哦...',
            () => {
              const data = JSON.parse(localStorage.getItem(`lutris-${moment().format('YYYY-MM-DD')}`) ?? '{}')

              localStorage.setItem(`lutris-${moment().format('YYYY-MM-DD')}`, JSON.stringify(Object.assign(data, { brush: true })))

              Object.assign(this.states, {
                status: 'brush',
                playing: true,
              })
            },
          )
        }

        else if(time.isBetween(moment('07:00', 'HH:mm'), moment('08:00', 'HH:mm'))) {
          game.modals.special.render(
            '该吃饭了',
            '渤仔是不太开心, 因为还没吃饭吗？\n吃饱才能有力气，快点让他吃饭吧！\n不过在这期间就不能外出了哦...',
            () => {
              const data = JSON.parse(localStorage.getItem(`lutris-${moment().format('YYYY-MM-DD')}`) ?? '{}')

              localStorage.setItem(`lutris-${moment().format('YYYY-MM-DD')}`, JSON.stringify(Object.assign(data, { brushbreakfast: true })))

              Object.assign(this.states, {
                status: 'breakfast',
                playing: true,
              })
            },
          )
        }

        else if(time.isBetween(moment('12:00', 'HH:mm'), moment('13:00', 'HH:mm'))) {
          game.modals.special.render(
            '该吃饭了',
            '渤仔是不太开心, 因为还没吃饭吗？\n吃饱才能有力气，快点让他吃饭吧！\n不过在这期间就不能外出了哦...',
            () => {
              const data = JSON.parse(localStorage.getItem(`lutris-${moment().format('YYYY-MM-DD')}`) ?? '{}')

              localStorage.setItem(`lutris-${moment().format('YYYY-MM-DD')}`, JSON.stringify(Object.assign(data, { luncheon: true })))

              Object.assign(this.states, {
                status: 'luncheon',
                playing: true,
              })
            },
          )
        }

        else if(time.isBetween(moment('15:00', 'HH:mm'), moment('18:00', 'HH:mm'))) {
          game.modals.special.render(
            '该吃饭了',
            '渤仔是不太开心, 因为还没吃饭吗？\n吃饱才能有力气，快点让他吃饭吧！\n不过在这期间就不能外出了哦...',
            () => {
              const data = JSON.parse(localStorage.getItem(`lutris-${moment().format('YYYY-MM-DD')}`) ?? '{}')

              localStorage.setItem(`lutris-${moment().format('YYYY-MM-DD')}`, JSON.stringify(Object.assign(data, { dinner: true })))

              Object.assign(this.states, {
                status: 'dinner',
                playing: true,
              })
            },
          )
        }

        else if(time.isBetween(moment('20:00', 'HH:mm'), moment('21:00', 'HH:mm'))) {
          game.modals.special.render(
            '该洗澡了',
            '渤仔是不太开心, 因为还没洗澡吗？\n赶紧让他洗澡变干净吧！\n不过在这期间就不能外出了哦...',
            () => {
              const data = JSON.parse(localStorage.getItem(`lutris-${moment().format('YYYY-MM-DD')}`) ?? '{}')

              localStorage.setItem(`lutris-${moment().format('YYYY-MM-DD')}`, JSON.stringify(Object.assign(data, { bath: true })))

              Object.assign(this.states, {
                status: 'bath',
                playing: true,
              })
            },
          )
        }
      }
    })

    if(moment().isBefore(`${moment().format('YYYY-MM-DD')} 06:00`) || moment().isAfter(`${moment().format('YYYY-MM-DD')} 19:00`)) {
      window.show(1)
    }

    else {
      window.show(0)
    }
    //默认分享的地址
    wx.miniProgram.postMessage({data:{url:"/home"}})

    return this.show().then(() => {
      menu.onDidMount()
      couch.onDidMount()
      table.onDidMount()
      settee.onDestroyed()
      lutris.onDidMount()
      bathtub.onDidMount()

      this.root.on('removed', () => menu.onDestroyed())
      this.root.on('removed', () => couch.onDestroyed())
      this.root.on('removed', () => table.onDestroyed())
      this.root.on('removed', () => settee.onDestroyed())
      this.root.on('removed', () => lutris.onDestroyed())
      this.root.on('removed', () => bathtub.onDestroyed())

      this.root.on('removed', () => this.clear())
      this.root.on('removed', () => game.removeClock('clock'))
    })
  }
}
