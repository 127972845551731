import Modal from '@prototype/modal'

export default class Message extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.5) / 1200
  }

  render(msg = '') {
    const { app, game, scale } = this

    const format = R.compose(
      R.join('\n'),
      R.map(R.join('')),
      R.splitEvery(12),
      R.split(''),
      R.defaultTo(''),
    )

    const main = app.group()
    const mask = app.sprite('shade.png')

    const panel = app.sprite('message_panel.png')
    const content = app.create(() => new PIXI.Text(format(msg), { fontSize: 64, fontFamily: 'sans', fill: '#666', leading: 16, align: 'center' }))

    mask.interactive = true
    main.interactive = true

    main.add(panel)
    main.add(content)

    game.setRealSize(panel, scale)
    game.setRealSize(content, scale)

    content.x = panel.halfWidth - content.halfWidth
    content.y = panel.halfHeight - content.halfHeight

    main.x = game.halfWidth - main.halfWidth + game.getReal(24, scale)
    main.y = game.halfHeight - main.halfHeight - game.getReal(56, scale)

    mask.on('tap', () => this.hide().then(() => this.destroy()))
    main.on('tap', () => this.hide().then(() => this.destroy()))

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    return this.show()
  }
}
