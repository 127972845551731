import api from '@sar/http'
import service from '@services/exam'

import Stage from '@prototype/stage'
import Component from '@prototype/component'

import Item from '@components/item'
import Option from '@components/option'
import Avatar from '@components/avatar'
import Select from '@components/select'
import Caption from '@components/caption'
import Countdown from '@components/countdown'
import Scoreboard from '@components/scoreboard'

class Match extends Component {
  init() {
    this.sprites.match.visible = true
    this.sprites.matching.visible = true
    this.sprites.friend.visible = false
    this.sprites.matched.visible = false
  }

  complet() {
    this.sprites.friend.visible = true
    this.sprites.matched.visible = true
    this.sprites.match.visible = false
    this.sprites.matching.visible = false
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game

    const icons = app.group()
    const texts = app.group()

    const panel = app.sprite('stage_contest_vs_panel.png')
    const matched = app.text('匹配完成', '48px sans', '#333')
    const matching = app.text('正在匹配...', '48px sans', '#333')

    const my = new Avatar(app, game, 'my', `${game.identity.user.nickname}`)
    const match = new Avatar(app, game, 'match', '????')
    const friend = new Avatar(app, game, 'friend', '企鹅君')

    match.visible = true
    friend.visible = false

    matched.visible = false
    matching.visible = true

    texts.add(matched)
    texts.add(matching)

    icons.add(my.root)
    icons.add(match.root)
    icons.add(friend.root)

    my.x = 0
    my.y = 0

    panel.x = 0
    panel.y = 162

    match.x = my.width + 120
    match.y = 0

    friend.x = my.width + 120
    friend.y = 0

    icons.x = panel.halfWidth - icons.halfWidth
    icons.y = 0

    matched.x = panel.halfWidth - matched.halfWidth
    matched.y = icons.height + 32

    matching.x = panel.halfWidth - matching.halfWidth
    matching.y = icons.height + 32

    this.root = app.group(
      panel,
      icons,
      texts,
    )

    this.sprites = {
      match,
      texts,
      icons,
      friend,
      matched,
      matching,
    }
  }

  move() {
    return new Promise(resolve => (this.app.slide(this.root, this.root.x, 30, 32)).onComplete = () => resolve(null))
  }

  show() {
    return new Promise(resolve => (this.app.fadeIn(this.root, 16)).onComplete = () => resolve(null)).then(() => this.root.interactiveChildren = true)
  }

  hide() {
    return new Promise(resolve => (this.app.fadeOut(this.root, 16)).onComplete = () => resolve(null)).then(() => this.root.interactiveChildren = false)
  }
}

class Questions extends Component {
  items: Item[]
  options: Option[]

  states = mobx.observable({
    list: [],
    total: 0,
    examId: null,
    init: false,
    answered: false,
    submitted: false,
    settled: new Map(),
    finished: new Map(),
  })

  geneItem(index) {
    const item = new Item(this.app, this.game)

    return item
  }

  geneOption(index) {
    const option = new Option(this.app, this.game)

    return option
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.root = app.group()

    this.items = R.times(this.geneItem.bind(this), 3)
    this.options = R.times(this.geneOption.bind(this), 4)

    const caption = new Caption(app, game)
    const countdown = new Countdown(app, game)
    const scoreboard = new Scoreboard(app, game)

    const items = app.grid(3, 1, 260, 190, true, 0, 0, index => this.items[index].root)
    const options = app.grid(1, 4, 755, 180, true, 0, 0, index => this.options[index].root)

    items.visible = false

    countdown.suffix = '秒'
    countdown.prefix = '剩余时间: '
    countdown.number = 60

    scoreboard.x = caption.halfWidth - scoreboard.halfWidth
    scoreboard.y = 90

    caption.x = 0
    caption.y = scoreboard.y + scoreboard.height + 90

    countdown.x = caption.x + 200
    countdown.y = caption.y + 586

    options.x = caption.halfWidth - options.halfWidth + 60
    options.y = caption.y + caption.height + 120

    items.x = caption.halfWidth - items.halfWidth - 35
    items.y = options.y + options.height + 180

    this.root.add(items)
    this.root.add(options)

    this.root.add(caption.root)
    this.root.add(countdown.root)
    this.root.add(scoreboard.root)

    this.sprites = {
      items,
      options,
      caption,
      countdown,
      scoreboard,
    }
  }

  next() {
    const result = this.states.list.shift()

    if(result == null) {
      return 0
    }

    this.sprites.countdown.start(60).then(async () => {
      if(this.states.finished.has(result.id)){
        return 0
      }

      if(this.states.settled.get(this.states.examId)) {
        return 0
      }

      // 标识已完成
      this.states.finished.set(result.id, true)

      if(this.states.list.length === 0 && this.states.settled.get(this.states.examId) != true) {
        // 标识已结算
        this.states.settled.set(this.states.examId, true)

        const result = await service(api).finish({
          examId: this.states.examId,
        })

        const onClose = async () => {
          await this.game.stages.contest.hide()
          await this.game.stages.home.render()
          await this.game.music.contest.stop()
          await this.game.music.normal.play()
          await this.game.stages.contest.destroy()
        }

        if(result.data.status == 'fail') {
          this.game.sound.defeat.play()

          this.game.modals.settlement.render({
            exp: result.data.exp,
            shell: result.data.conch,
            status: 'fail',
            onCancel: onClose,
            onConfirm: async () => {
              await Promise.all([
                new Promise(resolve => (this.app.fadeOut(this.sprites.options, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeOut(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
              ])

              this.sprites.scoreboard.reset()
              this.options.forEach(o => o.reset())
              this.states.init = false
              this.states.finished.clear()
              await this.start()

              await Promise.all([
                new Promise(resolve => (this.app.fadeIn(this.sprites.options, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeIn(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
              ])
            },
          })
        }

        else {
          this.game.sound.victory.play()

          this.game.modals.settlement.render({
            exp: result.data.exp,
            shell: result.data.conch,
            status: 'victory',
            onCancel: onClose,
            onConfirm: async () => {
              await Promise.all([
                new Promise(resolve => (this.app.fadeOut(this.sprites.options, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeOut(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
              ])

              this.sprites.scoreboard.reset()
              this.options.forEach(o => o.reset())
              this.states.init = false
              this.states.finished.clear()
              await this.start()

              await Promise.all([
                new Promise(resolve => (this.app.fadeIn(this.sprites.options, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
                new Promise(resolve => (this.app.fadeIn(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
              ])
            },
          })
        }

        return 0
      }

      await Promise.all([
        new Promise(resolve => (this.app.fadeOut(this.sprites.options, 24)).onComplete = () => resolve(0)),
        new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
        new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
        new Promise(resolve => (this.app.fadeOut(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
      ])

      R.toPairs(JSON.parse(result.content)).forEach(async (_, index) => this.options[index].reset())
      this.next()

      await Promise.all([
        new Promise(resolve => (this.app.fadeIn(this.sprites.options, 24)).onComplete = () => resolve(0)),
        new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
        new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
        new Promise(resolve => (this.app.fadeIn(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
      ])
    })

    this.states.answered = false
    this.sprites.caption.content = result.title
    this.sprites.caption.title = `第${this.states.total - this.states.list.length}题（共${this.states.total}题）`

    R.toPairs(JSON.parse(result.content)).forEach(([key, value], index) => {
      const option = this.options[index]

      option.alpha = 1
      option.interactive = true
      option.text = `${key}: ${value}`

      const handle = async () => {
        if(this.states.answered) {
          return 0
        }

        this.states.answered = true
        option.selected()

        // 标识已完成
        this.states.finished.set(result.id, true)

        const response = await service(api).answer({
          userResult: key,
          qid: result.id,
          examId: this.states.examId,
        })

        await new Promise(resolve => {
          if(response.data.status) {
            option.success().then(resolve)
            this.sprites.scoreboard.addPlayerScore()
          }

          else {
            option.wrong().then(resolve)
          }
        })

        if(this.states.list.length === 0 && this.states.settled.get(this.states.examId) != true) {
          // 标识已结算
          this.states.settled.set(this.states.examId, true)

          const result = await service(api).finish({
            examId: this.states.examId,
          })

          const onClose = async () => {
            await this.game.stages.contest.hide()
            await this.game.stages.home.render()
            await this.game.music.contest.stop()
            await this.game.music.normal.play()
            await this.game.stages.contest.destroy()
          }

          if(result.data.status == 'fail') {
            this.game.sound.defeat.play()

            this.game.modals.settlement.render({
              exp: result.data.exp,
              shell: result.data.conch,
              status: 'fail',
              onCancel: onClose,
              onConfirm: async () => {
                await Promise.all([
                  new Promise(resolve => (this.app.fadeOut(this.sprites.options, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeOut(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
                ])

                option.reset()
                this.sprites.scoreboard.reset()
                this.states.init = false
                this.states.finished.clear()
                await this.start()

                await Promise.all([
                  new Promise(resolve => (this.app.fadeIn(this.sprites.options, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeIn(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
                ])
              },
            })
          }

          else {
            this.game.sound.victory.play()

            this.game.modals.settlement.render({
              exp: result.data.exp,
              shell: result.data.conch,
              status: 'victory',
              onCancel: onClose,
              onConfirm: async () => {
                await Promise.all([
                  new Promise(resolve => (this.app.fadeOut(this.sprites.options, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeOut(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
                ])

                option.reset()
                this.sprites.scoreboard.reset()
                this.states.init = false
                this.states.finished.clear()
                await this.start()

                await Promise.all([
                  new Promise(resolve => (this.app.fadeIn(this.sprites.options, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
                  new Promise(resolve => (this.app.fadeIn(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
                ])
              },
            })
          }

          return 0
        }

        await Promise.all([
          new Promise(resolve => (this.app.fadeOut(this.sprites.options, 24)).onComplete = () => resolve(0)),
          new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
          new Promise(resolve => (this.app.fadeOut(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
          new Promise(resolve => (this.app.fadeOut(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
        ])

        option.reset()
        this.next()

        await Promise.all([
          new Promise(resolve => (this.app.fadeIn(this.sprites.options, 24)).onComplete = () => resolve(0)),
          new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.text, 24)).onComplete = () => resolve(0)),
          new Promise(resolve => (this.app.fadeIn(this.sprites.caption.sprites.title, 24)).onComplete = () => resolve(0)),
          new Promise(resolve => (this.app.fadeIn(this.sprites.countdown.root, 24)).onComplete = () => resolve(0)),
        ])
      }

      option.root.removeAllListeners()
      option.root.addListener('tap', handle)
    })

    if(R.toPairs(JSON.parse(result.content)).length < 4) {
      this.options[3].alpha = 0
      this.options[3].interactive = false
    }
  }

  async start() {
    if(this.states.init){
      return 0
    }

    const result = await service(api).start({})

    this.states.init = true
    this.states.list = result.data.list
    this.states.examId = result.data.examId
    this.states.total = result.data.list.length

    this.next()
  }

  show() {
    return new Promise(resolve => (this.app.fadeIn(this.root, 24)).onComplete = () => resolve(null)).then(() => this.root.interactiveChildren = true)
  }

  hide() {
    return new Promise(resolve => (this.app.fadeOut(this.root, 24)).onComplete = () => resolve(null)).then(() => this.root.interactiveChildren = false)
  }
}

class Prepare extends Component {
  start() {
    return this.sprites.countdown.start(100)
  }

  constructor(app, game) {
    super()

    const root = app.group()
    const items = app.group()

    const select = new Select(app, game)
    const countdown = new Countdown(app, game)

    const rollback = app.sprite('stage_contest_btn9.png')
    const ready = app.sprite('stage_contest_btn8.png')

    const title = app.sprite('stage_contest_item_title.png')
    const panel = app.sprite('stage_contest_item_panel.png')

    ready.interactive = true
    rollback.interactive = true

    countdown.suffix = ''
    countdown.prefix = '准备倒计时: '
    countdown.number = 100

    select.x = panel.halfWidth - select.halfWidth
    select.y = panel.halfHeight - select.halfHeight

    ready.x = panel.halfWidth - ready.halfWidth
    ready.y = panel.height + 90

    rollback.x = panel.halfWidth - rollback.halfWidth
    rollback.y = ready.y + ready.height + 60

    countdown.x = panel.halfWidth - countdown.halfWidth
    countdown.y = panel.y + 30

    title.x = panel.halfWidth - title.halfWidth
    title.y = countdown.y + countdown.height + 30

    this.app = app
    this.game = game
    this.root = root

    root.add(panel)
    root.add(items)
    root.add(title)
    root.add(ready)
    root.add(rollback)

    root.add(select.root)
    root.add(countdown.root)

    this.sprites = {
      panel,
      items,
      title,
      ready,
      rollback,
      countdown,
    }
  }

  show() {
    return new Promise(resolve => (this.app.fadeIn(this.root, 24)).onComplete = () => resolve(null)).then(() => this.root.interactiveChildren = true)
  }

  hide() {
    return new Promise(resolve => (this.app.fadeOut(this.root, 24)).onComplete = () => resolve(null)).then(() => this.root.interactiveChildren = false)
  }
}

class Helper extends Component {
  constructor(app, game) {
    super()

    const icon = app.sprite('stage_contest_arrow.png')
    const title = app.text('查看规则', '40px sans', '#fff')

    icon.x = 0
    icon.y = title.halfHeight - icon.halfHeight - 3

    title.x = 16 + icon.width
    title.y = 0

    this.sprites = {
      icon,
      title,
    }

    this.root = app.group(
      icon,
      title,
    )
  }
}

class Handle extends Component {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game

    const line = app.sprite('stage_contest_line.png')
    const match = app.sprite('stage_contest_btn10.png')
    const invite = app.sprite('stage_contest_btn4.png')
    const leader = app.sprite('stage_contest_btn1.png')
    const rollback = app.sprite('stage_contest_btn7.png')

    match.interactive = true
    invite.interactive = true
    leader.interactive = true
    rollback.interactive = true

    invite.x = line.halfWidth - invite.halfWidth
    invite.y = 0

    match.x = line.halfWidth - match.halfWidth
    match.y = 30 + invite.y + invite.height

    line.x = 0
    line.y = 48 + match.y + match.height

    leader.x = line.halfWidth - leader.halfWidth
    leader.y = 48 + line.y + line.height

    rollback.x = line.halfWidth - rollback.halfWidth
    rollback.y = 30 + leader.y + leader.height

    this.sprites = {
      line,
      match,
      invite,
      leader,
      rollback,
    }

    this.root = app.group(
      line,
      match,
      invite,
      leader,
      rollback,
    )
  }

  show() {
    return new Promise(resolve => (this.app.fadeIn(this.root, 16)).onComplete = () => resolve(null)).then(() => this.root.interactiveChildren = true)
  }

  hide() {
    return new Promise(resolve => (this.app.fadeOut(this.root, 16)).onComplete = () => resolve(null)).then(() => this.root.interactiveChildren = false)
  }
}

export default class Page extends Stage {
  charm: any
  scale: any
  destroyStatus: boolean

  transitionAnimation = {
    hide: () => {
      this.components.helper.root.interactive = false
      this.components.helper.root.interactiveChildren = false

      return Promise.all([
        new Promise(resolve => (this.app.fadeOut(this.sprites.panel, 16)).onComplete = () => resolve(0)),
        new Promise(resolve => (this.app.fadeOut(this.components.helper.root, 16)).onComplete = () => resolve(0)),
      ])
    },
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.charm = app.charm
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1600
  }

  async hideHandle() {
    this.components.handle.root.interactiveChildren = false

    return Promise.all([
      this.components.handle.hide(),
      this.transitionAnimation.hide(),
    ])
  }

  async showHandle() {
    return Promise.all([
      this.components.handle.show(),
      new Promise(resolve => (this.app.fadeIn(this.sprites.panel, 16)).onComplete = () => resolve(0)),
      new Promise(resolve => (this.app.fadeIn(this.components.helper.root, 16)).onComplete = () => {
        this.components.helper.root.interactive = true
        this.components.helper.root.interactiveChildren = true
        resolve(0)
      }),
    ])
  }

  async handleMatch() {
    await this.components.handle.hide()
    await this.transitionAnimation.hide()

    if(this.destroyStatus) {
      return 0
    }

    await this.components.questions.start()
    await this.components.questions.show()
  }

  async handleReady() {
    await this.components.questions.start()
    await this.components.questions.show()
  }

  async handleReturn() {
    this.game.url.push('/', {
      token: this.game.url.searchParams.get('token'),
    })

    await this.game.stages.contest.hide()
    await this.game.stages.home.render()
    await this.game.music.contest.stop()
    await this.game.music.normal.play()
    await this.game.stages.contest.destroy()
  }

  destroy() {
    this.destroyStatus = true

    return super.destroy()
  }

  render(battle) {
    this.destroyStatus = false

    const { app, game, scale } = this

    const header = app.sprite('stage_contest_bg1.png')
    const footer = app.sprite('stage_contest_bg2.png')
    const panel = app.sprite('stage_contest_title.png')

    panel.width = panel.width * scale * 1
    panel.height = panel.height * scale * 1

    header.width = header.width * game.scale * 2
    header.height = header.height * game.scale * 2

    footer.width = footer.width * game.scale * 2
    footer.height = footer.height * game.scale * 2

    header.x = 0
    header.y = 0

    footer.x = 0
    footer.y = game.screenHeight - footer.height

    panel.x = game.halfWidth - panel.halfWidth
    panel.y = game.getReal(380, scale)

    const helper = new Helper(app, game)
    const handle = new Handle(app, game)
    const questions = new Questions(app, game)

    game.setRealSize(helper.root, scale)
    game.setRealSize(handle.root, scale)
    game.setRealSize(questions.root, scale)

    this.sprites = {
      header,
      footer,
      panel,
    }

    this.components = {
      helper,
      handle,
      questions,
    }

    this.root = app.group(
      header,
      footer,
      panel,
    )

    helper.interactive = true
    handle.interactive = false
    handle.interactiveChildren = true

    handle.alpha = 1
    questions.alpha = 0

    helper.x = panel.x + game.getReal(180, scale)
    helper.y = panel.y + game.getReal(340, scale)


    handle.x = game.halfWidth - handle.halfWidth
    handle.y = game.getReal(60, scale) + panel.y + panel.height

    questions.x = game.halfWidth - questions.halfWidth
    questions.y = 0

    this.root.add(helper.root)
    this.root.add(handle.root)
    this.root.add(questions.root)

    game.setPivot(helper.root)
    game.enableButton(helper.root, () => game.modals.rules.render())

    game.setAnchor(handle.sprites.match)
    game.enableButton(handle.sprites.match, this.handleMatch.bind(this))

    game.setAnchor(handle.sprites.rollback)
    game.enableButton(handle.sprites.rollback, this.handleReturn.bind(this))

    game.setAnchor(handle.sprites.leader)
    game.enableButton(handle.sprites.leader, () => game.modals.status.render(app, scale))

    game.setAnchor(handle.sprites.invite)
    game.enableButton(handle.sprites.invite, () => this.hideHandle().then(() => game.stages.battle.render(scale, this.showHandle.bind(this))))

    const url = new URL(location.href)

    if(parseInt(url.searchParams.get('battle')) == 1) {
      this.sprites.panel.alpha = 0
      this.components.handle.root.alpha = 0
      this.components.helper.root.alpha = 0

      this.components.handle.root.interactiveChildren = false
      this.components.helper.root.interactiveChildren = false

      game.stages.battle.render(scale, this.showHandle.bind(this))
    }

    return this.show().then(() => {
      this.root.on('removed', () => helper.onDestroyed())
      this.root.on('removed', () => handle.onDestroyed())
    })
  }
}
