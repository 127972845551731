export default class Dialog {
  app: any
  game: any

  constructor(app, game) {
    this.app = app
    this.game = game
  }

  destroy(id) {
    return new Promise(resolve => resolve(this.app.remove(id)))
  }

  show(id) {
    return new Promise(resolve => (this.app.fadeIn(id, 1)).onComplete = () => resolve(null))
  }

  hide(id) {
    return new Promise(resolve => (this.app.fadeOut(id, 16)).onComplete = () => resolve(null))
  }

  render(msg: string) {
    const { app, game } = this

    const root = app.group()
    const content = app.text(msg, '30px sans', '#fff')
    const panel = app.roundRectangle(content.width + 32, content.height + 16, 8, '#333')

    game.setRealSize(panel)
    game.setRealSize(content)

    root.add(panel)
    root.add(content)

    root.alpha = 0
    panel.alpha = 1
    content.aplha = 1

    panel.x = 0
    panel.y = 0

    content.x = game.getReal(16)
    content.y = game.getReal(8)

    root.x = game.halfWidth - root.halfWidth
    root.y = game.halfHeight - root.halfHeight

    return this.show(root).then(async () => {
      await app.charm.wait(1000)
      await app.charm.wait(1000)
      await app.charm.wait(1000)

      await this.hide(root)
      await this.destroy(root)
    })
  }
}
