import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1600
  }

  render(titleText = '', contentText = '', callback = R.identity) {
    const { app, game, scale } = this

    const main = app.group()

    const mask = app.sprite('shade.png')
    const panel = app.sprite('goout_back_panel.png')
    const icon = app.sprite('stage_home_pop_icon_bad.png')

    const title = app.create(() => new PIXI.Text(titleText, { fontSize: 64, fontWeight: 'bold', fill: '#fff' }))
    const content = app.create(() => new PIXI.Text(contentText, { fontSize: 40, fontWeight: 'bold', fill: '#666', align: 'center', leading: 12 }))

    const cancel = app.group(...R.call(() => {
      const panel = app.sprite('stage_contest_sel1.png')
      const text = app.create(() => new PIXI.Text('稍等一会儿', { fontSize: 40, fontWeight: 'bold', fill: '#333' }))

      game.setRealSize(text, scale)
      game.setRealSize(panel, scale)

      text.x = panel.halfWidth - text.halfWidth
      text.y = panel.halfHeight - text.halfHeight

      return [
        panel,
        text,
      ]
    }))

    const confirm = app.group(...R.call(() => {
      const panel = app.sprite('stage_contest_sel1.png')
      const text = app.create(() => new PIXI.Text('好的', { fontSize: 40, fontWeight: 'bold', fill: '#333' }))

      game.setRealSize(text, scale)
      game.setRealSize(panel, scale)

      text.x = panel.halfWidth - text.halfWidth
      text.y = panel.halfHeight - text.halfHeight

      return [
        panel,
        text,
      ]
    }))

    game.setRealSize(icon, scale)
    game.setRealSize(panel, scale)
    game.setRealSize(title, scale)
    game.setRealSize(content, scale)

    main.add(panel)
    main.add(title)
    main.add(icon)
    main.add(content)
    main.add(confirm)
    main.add(cancel)

    mask.interactive = true
    cancel.interactive = true
    confirm.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    title.x = panel.halfWidth - title.halfWidth
    title.y = game.getReal(308, scale)

    icon.x = panel.halfWidth - icon.halfWidth
    icon.y = title.y + title.height + 16

    content.x = panel.halfWidth - content.halfWidth
    content.y = icon.y + icon.height + 16

    confirm.x = panel.halfWidth - confirm.halfWidth - 6
    confirm.y = panel.y + panel.height + 16

    cancel.x = panel.halfWidth - cancel.halfWidth - 6
    cancel.y = confirm.y + confirm.height + 16

    main.x = game.halfWidth - main.halfWidth
    main.y = game.halfHeight - main.halfHeight

    game.setPivot(cancel)
    game.enableButton(cancel, () => this.hide().then(() => this.destroy()), 'close')

    game.setPivot(confirm)
    game.enableButton(confirm, () => this.hide().then(() => this.destroy()).then(() => callback()))

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    return this.show()
  }
}
