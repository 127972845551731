import Client from 'sockette'

const client = R.set(R.lensProp('client'), R.__, {})
const message = R.set(R.lensProp('onmessage'), R.__, {})

Object.assign(R, {
  parse: R.curry((str: string) => JSON.parse(str)),
})

export default new class Socket {
  public books
  public client
  public received = new Set()
  public disabled = new Set()
  public subscribe = new Map()

  init = (token: string) => Object.assign(this, client(new Client(`${APP_ENV.socket}/ws?token=${token}`, message(e => {
    const res = R.parse(e.data)

    if(this.subscribe.has(res.type) == false) {
      console.log(`unsubscribe event type for <${res.type}>.`)
    }

    else if(this.disabled.has(res.type)) {
      console.log(`unsubscribe event type for <${res.type}>. because the event handler has been disabled.`)
    }

    else {
      const id = R.nth(0, this.subscribe.get(res.type))
      const fmt = R.nth(1, this.subscribe.get(res.type))

      const verify = R.nth(2, this.subscribe.get(res.type))
      const action = R.nth(3, this.subscribe.get(res.type))

      const data = R.call(R.compose(fmt), res.data)
      const identity = R.call(R.compose(id, fmt), res.data)

      if(R.and(verify(data), this.received.has(identity) == false)) {
        action(data, res)
      }
    }
  }))))

  listen = (name: string, id, fmt, verify, action) => this.subscribe.set(name, [
    id,
    fmt,
    verify,
    action,
  ])

  disable = e => this.disabled.add(e)

  enable = e => this.disabled.delete(e)

  send = e => this.client.json(e)

  unsubscribe = e => this.subscribe.delete(e)
}
