import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Scoreboard extends Component {
  async addPlayerScore() {
    const { app, root, sprites } = this

    const title = app.sprite('stage_contest_plus3.png')

    title.alpha = 0

    title.x = sprites.opponentScore.x
    title.y = sprites.opponentScore.y

    root.add(title)

    await Promise.all([
      new Promise(async resolve => {
        await new Promise(res => (app.fadeIn(title, 32)).onComplete = () => res(0))
        await new Promise(res => (app.fadeOut(title, 32)).onComplete = () => res(0))
        resolve(0)
      }),
      new Promise(resolve => (app.slide(title, title.x, title.y - 90, 60)).onComplete = () => resolve(0)),
    ])

    root.remove(title)

    const prev = parseInt(sprites.opponentScore.text)

    sprites.opponentScore.text = prev + 3
  }

  reset() {
    this.sprites.opponentScore.text = 0
  }

  // async addOpponentScore() {
  //   const { app, root, sprites } = this

  //   const title = app.sprite('stage_contest_plus1.png')

  //   title.alpha = 0

  //   title.x = sprites.opponentScore.x
  //   title.y = sprites.opponentScore.y

  //   root.add(title)

  //   await Promise.all([
  //     new Promise(async resolve => {
  //       await new Promise(res => (app.fadeIn(title, 32)).onComplete = () => res(0))
  //       await new Promise(res => (app.fadeOut(title, 32)).onComplete = () => res(0))
  //       resolve(0)
  //     }),
  //     new Promise(resolve => (app.slide(title, title.x, title.y - 90, 60)).onComplete = () => resolve(0)),
  //   ])

  //   root.remove(title)

  //   sprites.opponentScore.content = parseInt(sprites.opponentScore.content) + 1
  // }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game

    const clip = this.app.circle(105, '#fff')

    const panel = app.sprite('stage_contest_solo_panel.png')
    const player = app.sprite(game.identity.user.avatarUrl)
    const opponent = app.sprite('stage_contest_icon_friend.png')

    const playerScore = app.text('0', 'bold 48px sans', '#333')
    const opponentScore = app.text('0', 'bold 64px sans', '#333')

    player.width = 105
    player.height = 105

    opponent.visible = false
    playerScore.visible = false

    player.mask = clip

    clip.x = 60 + clip.halfWidth
    clip.y = panel.halfHeight

    player.x = 60
    player.y = panel.halfHeight - player.halfHeight

    opponent.x = panel.width - opponent.width - 30
    opponent.y = panel.halfHeight - opponent.halfHeight

    playerScore.x = player.x + player.width + 30
    playerScore.y = panel.halfHeight - playerScore.halfHeight

    opponentScore.x = opponent.x
    opponentScore.y = panel.halfHeight - opponentScore.halfHeight + 6

    this.sprites = {
      clip,
      panel,
      player,
      opponent,
      playerScore,
      opponentScore,
    }

    this.root = app.group(
      panel,
      clip,
      player,
      opponent,
      playerScore,
      opponentScore,
    )
  }
}
