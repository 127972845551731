export default new class Url {
  public prefix = ''

  get value() {
    return new URL(location.href)
  }

  get name() {
    return new URL(location.href).pathname
  }

  get searchParams() {
    return new URL(location.href).searchParams
  }

  public pop() {
    return history.back()
  }

  public push(e = '', pararms = {}) {
    return history.pushState(null, '', location.origin + this.prefix + e + '?' + R.toPairs(pararms).map(R.join('=')).join('&'))
  }
}
