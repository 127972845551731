import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Option extends Component {
  set text(value) {
    const format = R.compose(
      R.join('\n'),
      R.map(R.join('')),
      R.splitEvery(20),
      R.split(''),
      R.defaultTo(''),
    )

    this.sprites.text.content = format(value)
    this.sprites.text.x = this.sprites.panel.halfWidth - this.sprites.text.halfWidth
    this.sprites.text.y = this.sprites.panel.halfHeight - this.sprites.text.halfHeight
  }

  reset() {
    this.sprites.msg.show(0)
    this.sprites.panel.show(0)
    this.sprites.msg.alpha = 0
    this.sprites.text.alpha = 1
    this.sprites.player.alpha = 0
    this.sprites.opponent.alpha = 0
  }

  async wrong() {
    this.sprites.msg.show(1)
    this.sprites.panel.show(1)
    this.sprites.msg.alpha = 1
    this.sprites.text.alpha = 0

    await new Promise(resolve => (this.app.fadeOut(this.sprites.msg, 8, 0.5)).onComplete = () => resolve(0))
    await new Promise(resolve => (this.app.fadeIn(this.sprites.msg, 8, 1)).onComplete = () => resolve(0))

    await new Promise(resolve => (this.app.fadeOut(this.sprites.msg, 8, 0.5)).onComplete = () => resolve(0))
    await new Promise(resolve => (this.app.fadeIn(this.sprites.msg, 8, 1)).onComplete = () => resolve(0))

    await new Promise(resolve => (this.app.fadeOut(this.sprites.msg, 8, 0.5)).onComplete = () => resolve(0))
    await new Promise(resolve => (this.app.fadeIn(this.sprites.msg, 8, 1)).onComplete = () => resolve(0))
  }

  async success() {
    this.sprites.msg.show(0)
    this.sprites.panel.show(2)
    this.sprites.msg.alpha = 1
    this.sprites.text.alpha = 0

    await new Promise(resolve => (this.app.fadeOut(this.sprites.msg, 8, 0.5)).onComplete = () => resolve(0))
    await new Promise(resolve => (this.app.fadeIn(this.sprites.msg, 8, 1)).onComplete = () => resolve(0))

    await new Promise(resolve => (this.app.fadeOut(this.sprites.msg, 8, 0.5)).onComplete = () => resolve(0))
    await new Promise(resolve => (this.app.fadeIn(this.sprites.msg, 8, 1)).onComplete = () => resolve(0))

    await new Promise(resolve => (this.app.fadeOut(this.sprites.msg, 8, 0.5)).onComplete = () => resolve(0))
    await new Promise(resolve => (this.app.fadeIn(this.sprites.msg, 8, 1)).onComplete = () => resolve(0))
  }

  selected() {
    this.sprites.player.alpha = 1
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game

    const text = app.text('', '32px sans', '#333')
    const player = app.sprite('stage_contest_icon_my.png')
    const opponent = app.sprite('stage_contest_icon_friend.png')

    const msg = app.sprite([ 'stage_contest_right.png', 'stage_contest_wrong.png' ])
    const panel = app.sprite([ 'stage_contest_sel1.png', 'stage_contest_sel2.png', 'stage_contest_sel3.png' ])

    msg.alpha = 0
    player.alpha = 0
    opponent.alpha = 0

    msg.x = -60
    msg.y = 0

    player.x = 30
    player.y = panel.halfHeight - player.halfHeight

    opponent.x = 90
    opponent.y = panel.halfHeight - opponent.halfHeight

    text.x = panel.halfWidth - text.halfWidth
    text.y = panel.halfHeight - text.halfHeight

    this.sprites = {
      panel,
      msg,
      text,
      opponent,
      player,
    }

    this.root = app.group(
      panel,
      text,
      msg,
      opponent,
      player,
    )
  }
}
