import api from '@sar/http'
import service from '@services/applet'

import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Page extends Stage {
  scale: any

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1800
  }

  async load() {
    const { game } = this

    const result = await service(api).shareLink({
      params: {
        area: encodeURIComponent('/home'),
      }
    })

    Object.assign(this.states, {
      url: result.data.url,
      qrcode: result.data.qrc,
    })

    wx.ready(() => {
      wx.updateTimelineShareData({
        link: result.data.url,
        desc: '从小学会财商, 掌舵一生财富',
        title: `渤学多财`,
        imgUrl: `${location.host}${game.profiles.prefix}/images/stage_share_icon.png`,
      })

      wx.updateAppMessageShareData({
        link: result.data.url,
        desc: '从小学会财商, 掌舵一生财富',
        title: `渤学多财`,
        imgUrl: `${location.host}${game.profiles.prefix}/images/stage_share_icon.png`,
      })
    })
  }

  async render(cover) {
    this.load()

    const { app, game, scale } = this

    this.states = mobx.observable({
      url: null,
      qrcode: null,
    })

    const text = app.group()
    const main = app.group()
    const marker = app.group()
    const content = app.group()
    const handles = app.group()

    const mainmask = app.rectangle(846, 1364, '#fff')
    const markermask = app.rectangle(846, 156, '#fff')

    const title = app.text('渤学多财', '36px sans', '#333')
    const detail = app.text('从小学会财商, 掌舵一生财富', '36px sans', '#333')

    const icon = app.sprite('stage_share_icon_circle.png')
    const qrcode = app.sprite('stage_share_QR.png')

    icon.width = 92
    icon.height = 92

    const panel = app.sprite('stage_share_panel.png')
    const cancel = app.sprite('stage_share_back.png')
    const header = app.sprite('stage_contest_bg1.png')
    const footer = app.sprite('stage_contest_bg2.png')

    const share2 = app.sprite('stage_share_btn2.png')
    const share3 = app.sprite('stage_share_btn3.png')
    const share4 = app.sprite('stage_share_btn4.png')

    share2.interactive = true
    share3.interactive = true
    share4.interactive = true
    cancel.interactive = true
    header.interactive = true
    footer.interactive = true

    cover.mask = mainmask

    cover.width = 846 / (game.screenWidth * game.ratio) * (game.screenWidth * game.ratio)
    cover.height = 846 / (game.screenWidth * game.ratio) * (game.screenHeight * game.ratio)

    panel.width = panel.width * scale * 1
    panel.height = panel.height * scale * 1

    header.width = header.width * game.scale * 2
    header.height = header.height * game.scale * 2

    footer.width = footer.width * game.scale * 2
    footer.height = footer.height * game.scale * 2

    text.add(title)
    text.add(detail)

    marker.add(markermask)
    marker.add(text)
    marker.add(icon)
    marker.add(qrcode)

    content.add(mainmask)
    content.add(cover)

    main.add(content)
    main.add(marker)

    handles.add(share2)
    handles.add(share3)
    handles.add(share4)

    game.setRealSize(cancel, scale)
    game.setRealSize(marker, scale)
    game.setRealSize(content, scale)
    game.setRealSize(handles, scale)

    share2.putRight(share3, 24, 0)
    share3.putRight(share4, 24, 0)

    header.x = 0
    header.y = 0

    footer.x = 0
    footer.y = game.screenHeight - footer.height

    panel.x = game.halfWidth - panel.halfWidth
    panel.y = 30

    handles.x = game.halfWidth - handles.halfWidth
    handles.y = panel.y + panel.height + 24

    cancel.x = game.halfWidth - cancel.halfWidth
    cancel.y = handles.y + handles.height + 24

    markermask.x = 0
    markermask.y = 0

    icon.x = 24
    icon.y = markermask.halfHeight - icon.halfHeight

    title.x = 0
    title.y = 0

    detail.x = 0
    detail.y = title.y + title.height

    qrcode.x = markermask.width - qrcode.width - 24
    qrcode.y = markermask.halfHeight - qrcode.halfHeight

    text.x = icon.x + icon.width + 24
    text.y = markermask.halfHeight - text.halfHeight

    marker.x = panel.x + game.getReal(126, scale)
    marker.y = panel.y + game.getReal(1490, scale)

    content.x = panel.x + game.getReal(126, scale)
    content.y = panel.y + game.getReal(260, scale)

    this.sprites = {
      header,
      footer,
      main,
      panel,
      cancel,
      handles,
    }

    this.root = app.group(
      header,
      footer,
      main,
      panel,
      cancel,
      handles,
    )

    cover.alpha = 1

    game.setAnchor(share2)
    game.enableButton(share2, () => {
      game.modals.message.render('请点击右上角进行分享')
    })

    game.setAnchor(share3)
    game.enableButton(share3, () => {
      game.modals.message.render('请点击右上角进行分享')
    })

    game.setAnchor(share4)
    game.enableButton(share4, () => {
      const value = this.states.url
      const textarea = document.getElementById('textarea') as HTMLInputElement

      textarea.innerText = value

      textarea.setAttribute('value', value)

      textarea.setSelectionRange(0, value.length)

      textarea.select()

      if(document.execCommand) {
        document.execCommand('copy')
      }

      // navigator.clipboard.writeText(Math.random().toString(32).substring(2))

      game.modals.message.render('链接已复制到剪贴板')
    })

    game.setAnchor(cancel)
    game.enableButton(cancel, () => this.destroy())

    this.clear = mobx.autorun(() => {
      if(this.states.qrcode) {
        const code = app.sprite(`https://cdn.hemei.info/${this.states.qrcode}`)

        code.width = 112
        code.height = 112

        code.x = qrcode.x
        code.y = qrcode.y

        marker.add(code)
      }
    })

    this.root.on('removed', () => {
      this.clear()
    })
  }
}
