import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Bathtub extends Component {
  show(value) {
    const dispatch = R.cond([
      [R.equals('active'), () => this.root.playAnimation([1, 200])],
      [R.equals('normal'), () => this.root.playAnimation([201, 201])],
      [R.equals('ready'), () => this.root.playAnimation([202, 202])],
    ])

    dispatch(value)

    return new Promise(resolve => this.root.onComplete = resolve)
  }

  constructor(app, game) {
    super()

    const animate = app.sprite(R.times((i: number) => `animate_bathe_${numeral(i + 1).format('000')}.png`, 203))

    animate.fps = 26
    animate.loop = false

    animate.width = animate.width * game.scale * 0.8
    animate.height = animate.height * game.scale * 0.8

    this.app = app
    this.game = game
    this.root = animate

    this.states = mobx.observable({ status: 'normal' })
    this.clear = mobx.reaction(() => mobx.toJS(this.states), states => this.show(states.status))
  }

  onDestroyed() {
    this.clear()
  }
}
