import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Table extends Component {
  show(status: string) {
    if(status == 'normal') {
      this.sprites.eat.visible = false
      this.sprites.drink.visible = false
      this.sprites.normal.visible = true

      this.sprites.eat.stopAnimation([0, 199])
      this.sprites.drink.stopAnimation([0, 223])
      this.sprites.normal.playAnimation([200, 200])
    }

    else if (status == 'eat') {
      this.sprites.eat.visible = true
      this.sprites.drink.visible = false
      this.sprites.normal.visible = false

      this.sprites.eat.playAnimation([0, 199])
      this.sprites.drink.stopAnimation([0, 223])
      this.sprites.normal.stopAnimation([200, 200])
    }

    else if (status == 'drink') {
      this.sprites.eat.visible = false
      this.sprites.drink.visible = true
      this.sprites.normal.visible = false

      this.sprites.eat.stopAnimation([0, 199])
      this.sprites.drink.playAnimation([0, 223])
      this.sprites.normal.stopAnimation([200, 200])
    }

    return new Promise(resovle => {
      if(status == 'eat') {
        this.sprites.eat.onComplete = resovle
      }

      else if(status == 'drink') {
        this.sprites.drink.onComplete = resovle
      }

      else if(status == 'normal') {
        this.sprites.normal.onComplete = resovle
      }
    })
  }

  constructor(app, game) {
    super()

    const eat = app.sprite(R.times((i: number) => `animate_eat_${numeral(i + 1).format('000')}.png`, 201))
    const normal = app.sprite(R.times((i: number) => `animate_eat_${numeral(i + 1).format('000')}.png`, 201))
    const drink = app.sprite(R.times((i: number) => `animate_drink_tea_${numeral(i + 1).format('000')}.png`, 225))

    eat.fps = 26
    drink.fps = 26
    normal.fps = 1

    eat.visible = false
    drink.visible = false
    normal.visible = false

    eat.loop = false
    drink.loop = false
    normal.loop = false

    game.setRealSize(eat, game.scale)
    game.setRealSize(drink, game.scale)
    game.setRealSize(normal, game.scale)

    this.app = app
    this.game = game

    this.sprites = { eat, drink, normal }
    this.root = app.group( eat, drink, normal )

    this.states = mobx.observable({ status: 'normal' })
    this.clear = mobx.reaction(() => mobx.toJS(this.states), states => this.show(states.status))
  }

  onDestroyed() {
    this.clear()
  }
}
