export default api => ({
  list: function (options) {
    return api.post(`${APP_ENV.api}/api/email/list`, options)
  },

  receive: function (options) {
    return api.post(`${APP_ENV.api}/api/email/receive`, options)
  },

  receiveAll: function (options) {
    return api.post(`${APP_ENV.api}/api/email/receive_all`, options)
  },
})
