import api from '@sar/http'
import service from '@services/user'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

class Identity extends Component {
  get width() {
    return this.sprites.panel.width
  }

  get height() {
    return this.sprites.panel.height
  }

  get halfWidth() {
    return this.sprites.panel.halfWidth
  }

  get halfHeight() {
    return this.sprites.panel.halfHeight
  }

  constructor(app, game, scale, states) {
    super()

    this.app = app
    this.game = game
    this.scale = scale

    const outGroup = app.group()
    const expGroup = app.group()
    const shellGroup = app.group()

    const panel = app.sprite('status_part1_id.png')
    const photo = app.sprite('status_part1_icon.png')

    const expSplitLine = app.dash('#333', 0.5, 1, 0, 0, game.getReal(40, scale), 0)
    const shellSplitLine = app.dash('#333', 0.5, 1, 0, 0, game.getReal(40, scale), 0)

    const name = app.create(() => new PIXI.Text('', { fontSize: 40, fontFamily: 'sans', fontWeight: 'bold', fill: '#333', leading: 12 }))
    const grade = app.create(() => new PIXI.Text('', { fontSize: 24, fontFamily: 'sans', fontWeight: 'bold', fill: '#333', leading: 12 }))

    const outNumber = app.create(() => new PIXI.Text('', { fontSize: 26, fontFamily: 'sans', fill: '#333', leading: 12 }))
    const answerNumber = app.create(() => new PIXI.Text('', { fontSize: 26, fontFamily: 'sans', fill: '#333', leading: 12 }))

    const expTitle = app.create(() => new PIXI.Text('', { fontSize: 30, fontFamily: 'sans', fill: '#333', leading: 12 }))
    const shellTitle = app.create(() => new PIXI.Text('', { fontSize: 30, fontFamily: 'sans', fill: '#333', leading: 12 }))

    const expNumber = app.create(() => new PIXI.Text('', { fontSize: 28, fontFamily: 'serif', fontWeight: 'bold', fill: '#333', leading: 12 }))
    const shellNumber = app.create(() => new PIXI.Text('', { fontSize: 28, fontFamily: 'serif', fontWeight: 'bold', fill: '#333', leading: 12 }))

    expSplitLine.visible = false
    shellSplitLine.visible = false

    outGroup.add(outNumber)
    outGroup.add(answerNumber)

    expGroup.add(expTitle)
    expGroup.add(expSplitLine)
    expGroup.add(expNumber)

    shellGroup.add(shellTitle)
    shellGroup.add(shellSplitLine)
    shellGroup.add(shellNumber)

    expTitle.text = '升级需要经验'
    shellTitle.text = '需要贝壳'

    name.text = `${game.identity.user.nickname ?? ''}`
    grade.text = `Lv. ${game.identity.user.level ?? 1}`

    outNumber.text = `外出次数: ${game.identity.user.outNum >= 10000 ? (game.identity.user.outNum / 10000).toFixed(2)  + 'w' : game.identity.user.outNum}次`
    answerNumber.text = `答题次数: ${game.identity.user.questionRound >= 10000 ? (game.identity.user.questionRound / 10000).toFixed(2)  + 'w' : game.identity.user.questionRound}次`

    expNumber.text = `${game.identity.user.exp >= 10000 ? (game.identity.user.exp / 10000).toFixed(2) + 'w' : game.identity.user.exp}/${game.identity.user.levelInfo.exp >= 10000 ? (game.identity.user.levelInfo.exp / 10000).toFixed(2) + 'w' : game.identity.user.levelInfo.exp}`
    shellNumber.text = `${game.identity.user.financialCount >= 10000 ? (game.identity.user.financialCount / 10000).toFixed(2) + 'w' : game.identity.user.financialCount}/${game.identity.user.levelInfo.financialCount >= 10000 ? (game.identity.user.levelInfo.financialCount / 10000).toFixed(2) + 'w' : game.identity.user.levelInfo.financialCount}`

    game.setRealSize(photo, scale * 0.94)
    game.setRealSize(panel, scale)
    game.setRealSize(name, scale)
    game.setRealSize(grade, scale)

    game.setRealSize(expTitle, scale)
    game.setRealSize(shellTitle, scale)

    game.setRealSize(expNumber, scale)
    game.setRealSize(shellNumber, scale)

    game.setRealSize(outNumber, scale)
    game.setRealSize(answerNumber, scale)

    photo.x = game.getReal(66, scale)
    photo.y = game.getReal(66, scale)

    grade.x = photo.x + photo.width - grade.width - 3
    grade.y = photo.y + 3

    name.x = game.getReal(288, scale) + game.getReal(420 * 0.5, scale) - name.halfWidth
    name.y = game.getReal(80, scale)

    outNumber.x = 0
    outNumber.y = 0

    answerNumber.x = outNumber.x + outNumber.width + game.getReal(32, scale)
    answerNumber.y = outNumber.y

    expTitle.x = 0
    expTitle.y = 0

    expSplitLine.x = game.getReal(200, scale)
    expSplitLine.y = expTitle.y + expTitle.halfHeight - expSplitLine.halfHeight

    expNumber.x = game.getReal(288, scale) + game.getReal(96, scale) - expNumber.width
    expNumber.y = 0

    shellTitle.x = 0
    shellTitle.y = 0

    shellSplitLine.x = game.getReal(200, scale)
    shellSplitLine.y = shellTitle.y + shellTitle.halfHeight - shellSplitLine.halfHeight

    shellNumber.x = game.getReal(288, scale) + game.getReal(96, scale) - shellNumber.width
    shellNumber.y = 0

    outGroup.x = game.getReal(288, scale) + game.getReal(420 * 0.5, scale) - outGroup.halfWidth
    outGroup.y = game.getReal(32, scale) + name.height + name.y

    expGroup.x = game.getReal(300, scale)
    expGroup.y = game.getReal(224, scale)

    shellGroup.x = game.getReal(300, scale)
    shellGroup.y = game.getReal(286, scale)

    this.sprites = {
      panel,
      photo,
      name,
      grade,
    }

    this.root = app.group(
      panel,
      photo,
      name,
      grade,
      outGroup,
      expGroup,
      shellGroup,
    )

    this.clear = mobx.autorun(() => {
      name.text = `${game.identity.user.nickname ?? ''}`
      grade.text = `Lv. ${game.identity.user.level ?? 1}`

      outNumber.text = `外出次数: ${game.identity.user.outNum >= 10000 ? (game.identity.user.outNum / 10000).toFixed(2)  + 'w' : game.identity.user.outNum}次`
      answerNumber.text = `答题次数: ${game.identity.user.questionRound >= 10000 ? (game.identity.user.questionRound / 10000).toFixed(2)  + 'w' : game.identity.user.questionRound}次`

      expNumber.text = `${game.identity.user.exp >= 10000 ? (game.identity.user.exp / 10000).toFixed(2) + 'w' : game.identity.user.exp}/${game.identity.user.levelInfo.exp >= 10000 ? (game.identity.user.levelInfo.exp / 10000).toFixed(2) + 'w' : game.identity.user.levelInfo.exp}`
      shellNumber.text = `${game.identity.user.financialCount >= 10000 ? (game.identity.user.financialCount / 10000).toFixed(2) + 'w' : game.identity.user.financialCount}/${game.identity.user.levelInfo.financialCount >= 10000 ? (game.identity.user.levelInfo.financialCount / 10000).toFixed(2) + 'w' : game.identity.user.levelInfo.financialCount}`
    })
  }

  onDestroyed(){
    this.clear()
  }
}

class Tabs extends Component {
  constructor(app, game, scale, states) {
    super()

    const outcard = app.sprite([ 'status_part2_btn1a.png', 'status_part2_btn1b.png' ])
    const answercard = app.sprite([ 'status_part2_btn2a.png', 'status_part2_btn2b.png' ])

    answercard.show(1)
    outcard.show(0)

    outcard.interactive = true
    answercard.interactive = true

    outcard.width = outcard.width * scale * 0.9
    outcard.height = outcard.height * scale * 0.9

    answercard.width = answercard.width * scale * 0.9
    answercard.height = answercard.height * scale * 0.9

    answercard.x = 0
    answercard.y = 0

    outcard.x = answercard.width + 16 * scale
    outcard.y = 0

    this.sprites = {
      answercard,
      outcard,
    }

    this.root = app.group(
      answercard,
      outcard,
    )

    this.clear = mobx.reaction(() => states.selected, async selected => {
      if(selected == 'outcard') {
        outcard.show(1)
        answercard.show(0)
      }

      else {
        outcard.show(0)
        answercard.show(1)
      }

      Promise.all([
        service(api).myrank({ type: selected == 'answercard' ? 1 : 2 }),
        service(api).rank({ page: 1, size: 30, type: selected == 'answercard' ? 1 : 2 }),
      ]).then(result => {
        const [ user, rank ] = result

        Object.assign(states, {
          user: user.data,
          list: rank.data.list,
        })
      })
    })

    Promise.all([
      service(api).myrank({ type: 1 }),
      service(api).rank({ page: 1, size: 30, type: 1 }),
    ]).then(result => {
      const [ user, rank ] = result

      Object.assign(states, {
        user: user.data,
        list: rank.data.list,
      })
    })

    game.setAnchor(outcard)
    game.setAnchor(answercard)

    game.enableButton(outcard, () => states.selected = 'outcard')
    game.enableButton(answercard, () => states.selected = 'answercard')
  }

  onDidMount() {

  }

  onDestroyed() {
    this.clear()
  }
}

class Content extends Component {
  get width() {
    return this.sprites.panel.width
  }

  get height() {
    return this.sprites.panel.height
  }

  get halfWidth() {
    return this.sprites.panel.halfWidth
  }

  get halfHeight() {
    return this.sprites.panel.halfHeight
  }

  geneItem(data, index, type, isLast) {
    const { app } = this

    const content = app.group()

    const split = this.app.dash('#999', 3, 12, 0, 0, 658, 0)
    const panel = this.app.sprite('status_part2_list_panel.png')

    const mask = this.app.circle(103, '#fff')
    const border = this.app.circle(103, '#fff')
    const avatar = this.app.sprite(data.avatarUrl)

    const name = app.create(() => new PIXI.Text('', { fontSize: 38, fontFamily: 'sans', fill: '#333' }))
    const rank = app.create(() => new PIXI.Text('', { fontSize: 48, fontFamily: 'sans', fill: '#333' }))
    const answernum = app.create(() => new PIXI.Text('', { fontSize: 32, fontFamily: 'sans', fill: '#666' }))

    content.add(name)
    content.add(answernum)

    rank.text = data.ranking
    name.text = data.nickname

    if(type == 'answercard') {
      answernum.text = `已答题${data.questionRound ?? 0}次`
    }

    else {
      answernum.text = `已走${numeral(data.mileNum ?? 0).format('0,0')}海里`
    }

    if(isLast) {
      split.alpha = 0
    }

    avatar.mask = mask

    avatar.width = 103
    avatar.height = 103

    panel.x = 0
    panel.y = 18

    split.x = 0
    split.y = panel.height + panel.y + 18

    border.x = 186 + 51
    border.y = panel.y + panel.halfHeight

    mask.x = border.x + border.halfWidth - mask.halfWidth
    mask.y = border.y + border.halfHeight - mask.halfHeight

    avatar.x = 186
    avatar.y =  panel.y + panel.halfHeight - avatar.halfHeight

    rank.x = 120 - rank.halfWidth
    rank.y = avatar.y + avatar.halfHeight - rank.halfHeight

    name.x = 0
    name.y = 0

    answernum.x = 0
    answernum.y = name.height + name.y + 12

    content.x = avatar.x + avatar.width + 30
    content.y = avatar.y + avatar.halfHeight - content.halfHeight

    return this.app.group(
      split,
      panel,
      rank,
      content,
      mask,
      border,
      avatar,
    )
  }

  constructor(app, game, scale, states) {
    super()

    this.app = app
    this.game = game
    this.scale = scale

    this.states = mobx.observable({
      dragging: false,
      touchend: null,
      touchstart: null,
      lastlocation: null,
      uplocation: null,
      downlocation: null,
    })

    const content = app.group()
    const panel = app.sprite('status_part2_panel.png')

    const myrank = R.call(() => {
      const content = app.group()
      const mask = this.app.circle(103, '#fff')
      const border = this.app.circle(103, '#fff')
      const container = app.sprite('status_my_panel.png')
      const panel = this.app.sprite('status_part2_list_panel.png')
      const avatar = this.app.group()

      avatar.width = 103
      avatar.height = 103

      const name = app.create(() => new PIXI.Text('', { fontSize: 38, fontFamily: 'sans', fill: '#333' }))
      const rank = app.create(() => new PIXI.Text('', { fontSize: 48, fontFamily: 'sans', fill: '#333' }))
      const answernum = app.create(() => new PIXI.Text('', { fontSize: 32, fontFamily: 'sans', fill: '#666' }))

      content.add(name)
      content.add(answernum)

      panel.x = 30
      panel.y = container.halfHeight - panel.halfHeight

      border.x = 186 + 30 + border.halfWidth
      border.y = panel.y + panel.halfHeight

      mask.x = border.x + border.halfWidth - mask.halfWidth
      mask.y = border.y + border.halfHeight - mask.halfHeight

      avatar.x = 186 + 30
      avatar.y = panel.halfHeight - avatar.halfHeight + panel.y

      rank.x = 120 - rank.halfWidth + 30
      rank.y = avatar.y + avatar.halfHeight - rank.halfHeight

      name.x = 0
      name.y = 0

      answernum.x = 0
      answernum.y = name.height + name.y + 12

      content.x = avatar.x + avatar.width + 30
      content.y = avatar.y + avatar.halfHeight - content.halfHeight

      return Object.assign(app.group(
        container,
        panel,
        rank,
        mask,
        border,
        avatar,
        content,
      ), {
        update: (data) => {
          const icon = this.app.sprite(data.avatarUrl)

          icon.width = 103
          icon.height = 103

          avatar.mask = mask

          avatar.remove(...avatar.children)
          avatar.add(icon)

          rank.text = `${data.ranking}`
          name.text = `${data.nickname.length > 5 ? data.nickname.substring(0, 5) + '...' : data.nickname}(我自己)`

          if(states.selected == 'answercard') {
            answernum.text = `已答题${data.questionRound ?? 0}次`
          }

          else {
            answernum.text = `已走${numeral(data.mileNum ?? 0).format('0,0')}海里`
          }

          panel.x = 30
          panel.y = container.halfHeight - panel.halfHeight

          avatar.x = 186 + 30
          avatar.y = panel.halfHeight - avatar.halfHeight + panel.y

          rank.x = 120 + 30 - rank.halfWidth
          rank.y = avatar.y + avatar.halfHeight - rank.halfHeight

          content.x = avatar.x + avatar.width + 30
          content.y = avatar.y + avatar.halfHeight - content.halfHeight
        }
      })
    })

    game.setRealSize(panel, scale)
    game.setRealSize(myrank, scale)
    game.setRealSize(content, scale)

    panel.alpha = 1
    content.alpha = 0

    this.states.modifylocation = 0
    this.states.uplocation = 0
    this.states.downlocation = -(Math.max(content.height, panel.height - myrank.height) - Math.min(content.height, panel.height - myrank.height) + 6)

    game.hammer.on('panstart', event => {
      if(app.hitTestPoint(event.center, panel, true) == false) {
        return 0
      }

      this.states.dragging = true
      this.states.touchstart = event
    })

    game.hammer.on('panmove', event => {
      if(this.states.dragging == false) {
        return 0
      }

      content.y = game.getReal(panel.toLocal(event.center).y - panel.toLocal(this.states.touchstart.center).y, scale) + this.states.modifylocation
    })

    game.hammer.on('panend', event => {
      if(this.states.dragging == false) {
        return 0
      }

      const { app } = this
      const { states } = this

      const reachedup = R.gt(content.y, states.uplocation)
      const reacheddown = R.lt(content.y, states.downlocation)

      if(reachedup) {
        app.slide(content, 0, states.uplocation, 8, 'acceleration')
      }

      else if(reacheddown) {
        app.slide(content, 0, states.downlocation, 8, 'acceleration')
      }

      states.dragging = false
      states.touchend = event
      states.modifylocation = reachedup ? states.uplocation : reacheddown ? states.downlocation : content.y
    })

    this.sprites = {
      panel,
      content,
      myrank,
    }

    this.root = app.group(
      panel,
      content,
      myrank,
    )

    myrank.y = this.root.y + this.root.height - myrank.height

    this.clear = mobx.reaction(() => states.list, () => {
      const content = app.grid(1, states.list.length, 730, 164, true, 0, 0, index => this.geneItem(states.list[index], index + 1, states.selected, index == states.list.length - 1))

      game.setRealSize(content, scale)

      this.sprites.content.remove(...this.sprites.content.children)
      this.sprites.content.add(content)

      this.states.modifylocation = 0
      this.states.uplocation = 0
      this.states.downlocation = -(Math.max(content.height, panel.height - myrank.height) - Math.min(content.height, panel.height - myrank.height) + 6)

      if(this.sprites.content.height < this.sprites.panel.height - myrank.height) {
        states.downlocation = 0
      }

      this.sprites.myrank.update(states.user)
    })
  }

  onDidMount() {
    this.sprites.content.mask = this.app.rectangle(
      this.width - 8,
      this.height - 8,

      '#fff',
      '#fff',

      0,

      this.gx + 4,
      this.gy + 4,
    )

    this.app.fadeIn(this.sprites.content, 8)
  }

  onDestroyed() {
    this.clear()
    this.game.hammer.off('panstart')
    this.game.hammer.off('panmove')
    this.game.hammer.off('panend')

    this.app.remove(this.sprites.content)
    this.app.remove(this.sprites.content.mask)
  }
}

export default class Dialog extends Modal {
  get width() {
    return this.sprites.panel.width
  }

  get height() {
    return this.sprites.panel.height + this.sprites.invite + this.sprites.close.height + 6
  }

  get halfWidth() {
    return this.sprites.panel.halfWidth
  }

  get halfHeight() {
    return this.sprites.panel.halfHeight + this.sprites.invite.halfHeight + this.sprites.close.halfHeight + 3
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.2) / 1800
  }

  async render() {
    const { app, game, scale } = this

    const states = mobx.observable({
      list: [],
      user: null,
      selected: 'answercard',
    })

    const main = app.group()

    const mask = app.sprite('shade.png')
    const panel = app.sprite('status_panel.png')

    const close = app.button([ 'close_btn_up.png', 'close_btn_down.png' ])
    const invite = app.button([ 'status_invite_btn.png', 'status_invite_btn.png' ])
    const upgrade = app.button([ 'status_part1_btn1.png', 'status_part1_btn1.png' ])

    const upgradeText = app.create(() => new PIXI.Text('火速升级', { fontSize: 14, fontFamily: 'JingnanBoBoH', fill: '#fff', stroke: '#333', strokeThickness: 2 }))

    const tabs = new Tabs(app, game, scale, states)
    const content = new Content(app, game, scale, states)
    const identity = new Identity(app, game, scale, states)

    main.add(panel)
    main.add(close)
    main.add(upgrade)
    main.add(invite)
    main.add(upgradeText)

    main.add(tabs.root)
    main.add(content.root)
    main.add(identity.root)

    mask.interactive = true
    close.interactive = true
    upgrade.interactive = true
    invite.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    game.setRealSize(panel, scale * 1)
    game.setRealSize(close, scale * 1.25)
    game.setRealSize(upgrade, scale * 1)
    game.setRealSize(invite, scale * 1)

    main.x = game.halfWidth - panel.halfWidth
    main.y = game.halfHeight - panel.halfHeight - close.halfHeight - 6

    invite.x = main.halfWidth - invite.halfWidth
    invite.y = panel.height

    close.x = main.halfWidth - close.halfWidth
    close.y = invite.y + invite.height + 6

    identity.x = panel.halfWidth - identity.halfWidth
    identity.y = game.getReal(60, scale)

    upgrade.x = panel.halfWidth - upgrade.halfWidth
    upgrade.y = identity.height + identity.y + game.getReal(18, scale)

    upgradeText.x = upgrade.x + upgrade.halfWidth - upgradeText.halfWidth
    upgradeText.y = upgrade.y + upgrade.halfHeight - upgradeText.halfHeight

    tabs.x = panel.halfWidth - tabs.halfWidth
    tabs.y = upgrade.height + upgrade.y + game.getReal(80, scale)

    content.x = panel.halfWidth - content.halfWidth
    content.y = tabs.height + tabs.y - game.getReal(10, scale)

    game.setAnchor(close)
    game.setAnchor(upgrade)

    game.enableButton(close, async () => {
      await this.hide()
      await this.destroy()
    }, 'close')

    game.enableButton(upgrade, async () => {
      await service(api).level({})
      await game.refreshUserData()
      await game.modals.message.render('恭喜您升级成功')
      game.sound.upgrade.play()
    })

    game.setAnchor(invite)
    game.enableButton(invite, () => null)

    game.refreshUserData()

    this.sprites = {
      mask,
      main,
      close,
      panel,
      upgrade,
      invite,
      upgradeText,
    }

    this.root = app.group(
      mask,
      main,
    )

    this.root.on('removed', () => {
      tabs.onDestroyed()
      content.onDestroyed()
      identity.onDestroyed()
    })

    return this.show().then(() => {
      tabs.onDidMount()
      content.onDidMount()
      identity.onDidMount()
    })
  }
}
