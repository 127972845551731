import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
  }

  render(msg = '') {
    const { app, game } = this

    const main = app.group()
    const mask = app.sprite('shade.png')

    const panel = app.sprite('goout_pop_panel.png')
    const message = app.text(msg, '24px sans', '#fff')

    main.add(panel)
    main.add(message)

    mask.interactive = true
    main.interactive = false

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    panel.width =  game.scale * panel.width
    panel.height = game.scale * panel.height

    message.x = panel.halfWidth - message.halfWidth
    message.y = panel.halfHeight - message.halfHeight + game.getReal(6)

    main.x = game.halfWidth - main.halfWidth
    main.y = game.halfHeight - main.halfHeight

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    return new Promise(resolve => this.show().then(() => mask.on('tap', () => this.hide().then(() => this.destroy()).then(resolve))))
  }
}
