import api from '@sar/http'
import service from '@services/email'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  get width() {
    return this.sprites.panel.width
  }

  get height() {
    return this.sprites.panel.height + this.sprites.close.height + 6
  }

  get halfWidth() {
    return this.sprites.panel.halfWidth
  }

  get halfHeight() {
    return this.sprites.panel.halfHeight + this.sprites.close.halfHeight + 3
  }

  geneItem(data) {
    const { app, game } = this

    const awards = [
      {
        count: data.fishCount,
        cover: 'mailbox_list_item1.png',
      },
      {
        count: data.financialCount,
        cover: 'mailbox_list_item2.png',
      },
      {
        count: 0,
        cover: 'mailbox_list_item3.png',
      },
      {
        count: data.cardType == 2 ? data.cardNum : 0,
        cover: game.profiles.oss + '/' + data.cardUrl,
      },
      {
        count: data.cardType == 1 ? data.cardNum : 0,
        cover: game.profiles.oss + '/' + data.cardUrl,
      },
    ]

    const items = app.group()
    const content = app.group()

    const icon = app.sprite('mailbox_list_icon.png')
    const panel = app.sprite('mailbox_list_panel.png')

    const title = app.create(() => new PIXI.Text(data.title, { fontSize: 40, fill: '#666' }))
    const time = app.create(() => new PIXI.Text(moment().from(data.createTime), { fontSize: 40, fill: '#666' }))

    content.add(time)
    content.add(title)
    content.add(items)

    awards.filter(data => data.count > 0).reduce<any>((prev, data, index) => {
      const cover = app.create(() => app.sprite(data.cover))
      const number = app.create(() => new PIXI.Text(`× ${data.count}`, { fontSize: 40, fill: '#666' }))

      cover.width = 72
      cover.height = 63

      cover.x = 0
      cover.y = 0

      number.x = cover.width + 12
      number.y = cover.height - number.height

      return R.tap(items.add.bind(items), Object.assign(app.group(cover, number), { x: (prev?.x ?? 0) + (prev?.width ?? 0) + (index ? 16 : 0) }))
    }, null)

    icon.x = 60
    icon.y = panel.halfHeight - icon.halfHeight

    time.x = 586 - time.width
    time.y = 0

    items.x = 0
    items.y = title.height + 6

    content.x = 200
    content.y = panel.halfHeight - content.halfHeight

    const root = app.group(
      panel,
      icon,
      content,
    )

    // this.game.setRealSize(root, this.scale)

    root.interactive = true

    root.on('tap', () => service(api).receive({ id: data.id }).then(() => this.loadList()).then(() => this.game.modals.message.render('领取成功')))

    return root
  }

  loadList() {
    return service(api).list({ page: 1, size: 30 }).then(result => {
      Object.assign(this.states, {
        list: result.data.list,
      })
    })
  }

  /**
   * 构造函数
   * @param app 应用底层引擎
   * @param game 应用根数据流
   */
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1475
  }

  /**
   * 渲染函数
   */
  render() {
    const { app, game, scale } = this

    this.states = mobx.observable({
      list: [],
      dragging: false,
      touchend: null,
      touchstart: null,
      lastlocation: null,
      uplocation: null,
      downlocation: null,
    })

    const main = app.group()
    const content = app.group()

    const contentbox = app.rectangle(860, 886, '#fff')
    const contentmask = app.rectangle(860, 886, '#fff')

    const mask = app.sprite('shade.png')
    const panel = app.sprite('mailbox_panel.png')

    const receive = app.button(['mailbox_btn.png', 'mailbox_btn.png'])
    const close = app.button(['close_btn_up.png', 'close_btn_down.png'])

    main.add(panel)
    main.add(close)
    main.add(contentbox)
    main.add(contentmask)
    main.add(content)
    main.add(receive)

    contentbox.alpha = 0
    contentmask.alpha = 1

    contentbox.mask = null
    content.mask = contentmask

    mask.interactive = true
    close.interactive = true
    receive.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    close.width = game.scale * close.width * 1.25
    close.height = game.scale * close.height * 1.25

    contentmask.width = scale * contentmask.width + 2
    contentmask.height = scale * contentmask.height - 2

    game.setRealSize(panel, scale)
    game.setRealSize(receive, scale)
    game.setRealSize(content, scale)
    game.setRealSize(contentbox, scale)

    receive.x = game.getReal(8, scale) + panel.halfWidth - receive.halfWidth
    receive.y = game.getReal(1306, scale)

    content.x = game.getReal(12, scale) + panel.halfWidth - content.halfWidth
    content.y = game.getReal(370, scale)

    contentbox.x = game.getReal(12, scale) + panel.halfWidth - contentbox.halfWidth
    contentbox.y = game.getReal(380, scale)

    contentmask.x = game.getReal(12, scale) + panel.halfWidth - contentmask.halfWidth
    contentmask.y = game.getReal(380, scale)

    close.x = main.halfWidth - close.halfWidth
    close.y = panel.height + 6

    main.x = game.halfWidth - panel.halfWidth
    main.y = game.halfHeight - panel.halfHeight - close.halfHeight - 3

    this.states.modifylocation = content.y
    this.states.uplocation = content.y
    this.states.downlocation = -(Math.max(content.height, contentbox.height) - Math.min(content.height, contentbox.height) - contentbox.y + 6)

    game.hammer.on('panstart', event => {
      if(app.hitTestPoint(event.center, contentbox, true) == false) {
        return 0
      }

      this.states.dragging = true
      this.states.touchstart = event
    })

    game.hammer.on('panmove', event => {
      if(this.states.dragging == false) {
        return 0
      }

      content.y = game.getReal(contentbox.toLocal(event.center).y - contentbox.toLocal(this.states.touchstart.center).y, scale) + this.states.modifylocation
    })

    game.hammer.on('panend', event => {
      if(this.states.dragging == false) {
        return 0
      }

      const { app } = this
      const { states } = this

      const reachedup = R.gt(content.y, states.uplocation)
      const reacheddown = R.lt(content.y, states.downlocation)

      if(reachedup) {
        app.slide(content, content.x, states.uplocation, 8, 'acceleration')
      }

      else if(reacheddown) {
        app.slide(content, content.x, states.downlocation, 8, 'acceleration')
      }

      states.dragging = false
      states.touchend = event
      states.modifylocation = reachedup ? states.uplocation : reacheddown ? states.downlocation : content.y
    })

    game.setAnchor(close)
    game.setAnchor(receive)

    game.enableButton(receive, async () => {
      await service(api).receiveAll({})
      await game.refreshUserData()
      await this.hide()
      await this.destroy()
    })

    game.enableButton(close, async () => {
      await this.hide()
      await this.destroy()
    }, 'close')

    this.sprites = {
      mask,
      main,
      panel,
      close,
    }

    this.root = app.group(
      mask,
      main,
    )

    this.root.on('removed', () => {
      this.clear()
      this.game.hammer.off('panstart')
      this.game.hammer.off('panmove')
      this.game.hammer.off('panend')
    })

    this.clear = mobx.reaction(() => this.states.list, list => {
      content.remove(...content.children)
      content.add(app.grid(1, list.length, 810, 180, true, 0, 0, index => this.geneItem(list[index])))

      if(content.scale.x == 1) {
        game.setRealSize(content, scale)
      }

      content.x = game.getReal(12, scale) + panel.halfWidth - content.halfWidth
      content.y = game.getReal(370, scale)

      this.states.modifylocation = content.y
      this.states.uplocation = content.y
      this.states.downlocation = -(Math.max(content.height, contentbox.height) - Math.min(content.height, contentbox.height) - contentbox.y + 12)

      if(content.height < contentbox.height) {
        this.states.downlocation = content.y
      }
    })

    this.loadList()

    return this.show()
  }
}
