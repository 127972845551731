import api from '@sar/http'
import service from '@services/out'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1800
  }

  render() {
    const { app, game, scale } = this

    const main = app.group()
    const handles = app.group()

    const mask = app.sprite('shade.png')
    const goout = app.sprite('help_btn1.png')
    const fish = app.sprite('help_btn2.png')
    const shell = app.sprite('help_btn3.png')
    const contest = app.sprite('help_btn4.png')
    const emali = app.sprite('help_btn5.png')
    const store = app.sprite('help_btn6.png')
    const star = app.sprite('help_btn7.png')
    const rollback = app.sprite('help_btn8.png')

    main.add(goout)
    main.add(fish)
    main.add(shell)
    main.add(contest)
    main.add(emali)
    main.add(store)
    main.add(star)
    main.add(rollback)
    main.add(handles)

    mask.interactive = true
    goout.interactive = true
    fish.interactive = true
    shell.interactive = true
    contest.interactive = true
    emali.interactive = true
    store.interactive = true
    star.interactive = true
    rollback.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    game.setRealSize(goout, scale * 1.0)
    game.setRealSize(fish, scale * 1.0)
    game.setRealSize(shell, scale * 1.0)
    game.setRealSize(contest, scale * 1.0)
    game.setRealSize(emali, scale * 1.0)
    game.setRealSize(store, scale * 1.0)
    game.setRealSize(star, scale * 1.0)
    game.setRealSize(rollback, scale * 1.0)

    goout.putBottom(fish, 0, 16)
    fish.putBottom(shell, 0, 16)
    shell.putBottom(contest, 0, 16)
    contest.putBottom(emali, 0, 16)
    emali.putBottom(store, 0, 16)
    store.putBottom(star, 0, 16)
    star.putBottom(rollback, 0, 16)

    main.x = game.halfWidth - main.halfWidth
    main.y = game.halfHeight - main.halfHeight

    game.setAnchor(goout)
    game.enableButton(goout, () => game.modals.guidebook.render(1))

    game.setAnchor(fish)
    game.enableButton(fish, () => game.modals.guidebook.render(2))

    game.setAnchor(shell)
    game.enableButton(shell, () => game.modals.guidebook.render(3))

    game.setAnchor(contest)
    game.enableButton(contest, () => game.modals.guidebook.render(4))

    game.setAnchor(emali)
    game.enableButton(emali, () => game.modals.guidebook.render(5))

    game.setAnchor(store)
    game.enableButton(store, () => game.modals.guidebook.render(6))

    game.setAnchor(star)
    game.enableButton(star, () => game.modals.guidebook.render(7))

    game.setAnchor(rollback)
    game.enableButton(rollback, () => this.hide().then(() => this.destroy()), 'close')

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    return this.show()
  }
}
