import api from '@sar/http'
import service from '@services/applet'

import Shell from '@components/shell'
import Financial from '@components/financial'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

const presets = [
  {
    index: 0,
    shell: 1000,
    financial: 10,
  },
  {
    index: 1,
    shell: 5000,
    financial: 50,
  },{
    index: 2,
    shell: 10000,
    financial: 100,
  },

  {
    index: 3,
    shell: 50000,
    financial: 500,
  },
]

export default class Dialog extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1474
  }

  geneItem(preset, i) {
    const { app } = this

    const root = app.group()
    const shell = app.group()
    const financial = app.group()

    const panel = app.sprite(`exchange_item_panel.png`)
    const marker = app.sprite(`exchange_item_mark.png`)

    const icon = app.sprite(`exchange_icon_financial.png`)
    const cover = app.sprite(`exchange_item_icon${R.inc(preset.index)}.png`)

    const shellnum = app.text(`兑换${preset.shell}贝壳`, '26px sans', '#fff')
    const financialnum = app.text(`× ${preset.financial}`, '26px sans', '#333')

    panel.alpha = 0
    cover.alpha = 1

    panel.interactive = true
    cover.interactive = false

    shell.add(marker)
    shell.add(shellnum)

    financial.add(icon)
    financial.add(financialnum)

    root.add(panel)
    root.add(cover)
    root.add(shell)
    root.add(financial)

    icon.width = 60
    icon.height = 60

    cover.x = panel.halfWidth - cover.halfWidth
    cover.y = 12

    shellnum.x = marker.halfWidth - shellnum.halfWidth
    shellnum.y = marker.halfHeight - shellnum.halfHeight

    financialnum.x = icon.x + icon.width + 6
    financialnum.y = icon.halfHeight - financialnum.halfHeight

    shell.x = panel.halfWidth - shell.halfWidth
    shell.y = cover.y + cover.height + 6

    financial.x = panel.halfWidth - financial.halfWidth
    financial.y = shell.y + shell.height

    return R.tap(() => root.on('tap', () => Object.assign(this.states, { selected: i })),Object.assign(root, { interactive: true, select: () => panel.alpha = 1, unselect: () => panel.alpha = 0 }))
  }

  render() {
    const { app, game, scale } = this

    const shell = new Shell(app, game)
    const financial = new Financial(app, game)

    const main = app.group()
    const handles = app.group()
    const content = app.grid(2, 2, 380, 440, true, 0, 0, i => this.geneItem(R.nth(i, presets), i))

    const mask = app.sprite('shade.png')
    const panel = app.sprite('exchange_panel.png')

    const confirm = app.button(['exchange_btn_ok.png', 'exchange_btn_ok.png'])
    const cancel = app.button(['exchange_btn_back.png', 'exchange_btn_back.png'])

    handles.add(cancel)
    handles.add(confirm)

    main.add(panel)
    main.add(content)
    main.add(handles)

    main.add(shell.root)
    main.add(financial.root)

    mask.interactive = true
    cancel.interactive = true
    confirm.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    game.setRealSize(panel, scale)
    game.setRealSize(cancel, scale)
    game.setRealSize(content, scale)
    game.setRealSize(confirm, scale)

    shell.x = panel.width - shell.width - game.getReal(16, scale)
    shell.y = panel.y - shell.height + game.getReal(12, scale)

    financial.x = shell.x - financial.width - game.getReal(16, scale)
    financial.y = panel.y - financial.height + game.getReal(12, scale)

    content.x = game.getReal(68, scale)
    content.y = game.getReal(268, scale)

    confirm.x = 0
    confirm.y = 0

    cancel.x = confirm.x + confirm.width + 16
    cancel.y = 0

    handles.x = panel.halfWidth - handles.halfWidth
    handles.y = panel.height - handles.height - game.getReal(120, scale)

    main.x = game.halfWidth - panel.halfWidth
    main.y = game.halfHeight - panel.halfHeight

    game.setAnchor(cancel)
    game.enableButton(cancel, () => this.hide().then(() => this.destroy()), 'close')

    game.setAnchor(confirm)
    game.enableButton(confirm, () => service(api).coins({ count: presets[this.states.selected].financial }).then(e => Object.assign(this.states, { data: e.data })).then(() => game.refreshUserData()).then(() => game.modals.message.render('兑换成功')))

    this.states = mobx.observable({
      data: 0,
      selected: 0,
    })

    this.sprites = {
      mask,
      main,
      panel,
      content,
    }

    this.root = app.group(
      mask,
      main,
    )

    this.clear = mobx.autorun(() => {
      const { data, selected } = this.states
      const { financialCount } = game.identity.user

      financial.number = data
      shell.number = financialCount

      content.children.forEach((item, i) => R.when(i => selected == i, item.select)(i))
      content.children.forEach((item, i) => R.when(i => selected != i, item.unselect)(i))
    })

    return this.show().then(() => service(api).coins({})).then(e => Object.assign(this.states, { data: e.data })).finally(() => this.root.on('removed', () => this.clear()))
  }
}
