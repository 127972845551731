export default store => async function formatter(request) {
  // 格式化 url 参数字符串，去除多余的引号
  const formatParams = R.compose(
    R.fromPairs,
    R.map(R.split('=')),
    R.split('&'),
    R.replace(/\"+/g, ''),
    R.join('&'),
    R.map(R.join('=')),
    R.filter(R.compose(R.identity, R.nth(1))),
    R.toPairs,
    R.defaultTo({}),
  )

  Object.assign(request, {
    params: formatParams(request.params)
  })

  // 格式化 url 参数字符串，去除重复参数
  if(R.test(/\?+/, request.rest)){
    const formatRest = R.compose(
      R.head,
      R.split('?'),
    )

    const formatParams = R.compose(
      R.mergeRight(request.params),
      R.fromPairs,
      R.map(R.split('=')),
      R.split('&'),
      R.last,
      R.split('?'),
    )

    Object.assign(request, {
      rest: formatRest(request.rest),
      params: formatParams(request.rest),
    })
  }

  return request
}
