import api from '@sar/http'
import service from '@services/favorite'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

function geneDots (app, game, scale, num, page) {
  return app.grid(num, 1, game.getReal(48, scale), game.getReal(36, scale), true, 0, 0, (index: number) => {
    const unselect = app.circle(36, '#009fe8')
    const selected = app.circle(20, '#ffffff')

    game.setRealSize(unselect, scale)
    game.setRealSize(selected, scale)

    if(page - 1 == index) {
      unselect.alpha = 1
      selected.alpha = 1
    }

    else  {
      unselect.alpha = 1
      selected.alpha = 0
    }

    return Object.assign(app.group(unselect, selected), {

      release: () => {
        unselect.alpha = 1
        selected.alpha = 0
      },

      checked: () => {
        unselect.alpha = 1
        selected.alpha = 1
      },

    })
  })
}

function geneBooks(app, game, scale, count, dataset, owned, showDetail) {
  const genePage = (page: number) => app.grid(3, 4, game.getReal(224, scale), game.getReal(224, scale), true, 0, 0, (index: number) => {
    if(page * 12 + index + 1 > dataset.length) {
      return app.group()
    }

    const data = dataset[page * 12 + index]
    const isowned = owned.find(item => item.id == data?.id)

    if(isowned) {
      const panel = app.sprite('collection_part_collected_panel.png')
      const label = app.sprite('collection_part_collected_panel_label.png')
      const sample = app.sprite(`${game.profiles.oss}/${isowned.shortIcon}`)

      label.alpha = 0
      sample.alpha = 0

      if(isowned.attribute == 2) {
        label.alpha = 1
      }

      panel.width = panel.width * scale
      panel.height = panel.height * scale

      label.width = label.width * scale
      label.height = label.height * scale

      sample.width = 170 * scale
      sample.height = 170 * scale

      sample.x = panel.halfWidth - sample.halfWidth
      sample.y = panel.halfHeight - sample.halfHeight

      sample.interactive = true
      sample.on('tap', () => showDetail(data))

      app.charm.wait(300).then(() => app.fadeIn(sample, 8))

      return app.group(
        panel,
        sample,
        label,
      )
    }

    else {
      const panel = app.sprite('collection_part_collected_panel.png')
      const sample = app.sprite('collection_part_collected_sample.png')

      panel.width = panel.width * scale
      panel.height = panel.height * scale

      sample.width = 196 * scale
      sample.height = 196 * scale

      return app.group(
        panel,
        sample,
      )
    }
  })

  return app.grid(count, 1, game.getReal(224, scale) * 3 + 16, game.getReal(224, scale) * 4, false, 0, 0, (index: number) => genePage(index))
}

class Tabs extends Component {
  constructor(app, game, scale, states) {
    super()

    const postcard = app.sprite([ 'collection_page1a.png', 'collection_page1b.png' ])
    const knowledgecard = app.sprite([ 'collection_page2a.png', 'collection_page2b.png' ])

    if(states.selected == 'postcard') {
      postcard.show(1)
      knowledgecard.show(0)
    }

    else {
      postcard.show(0)
      knowledgecard.show(1)
    }

    postcard.interactive = true
    knowledgecard.interactive = true

    postcard.width = postcard.width * scale
    postcard.height = postcard.height * scale

    knowledgecard.width = knowledgecard.width * scale
    knowledgecard.height = knowledgecard.height * scale

    postcard.x = 0
    postcard.y = 0

    knowledgecard.x = postcard.width + 16 * scale
    knowledgecard.y = 0

    this.sprites = {
      postcard,
      knowledgecard,
    }

    this.root = app.group(
      postcard,
      knowledgecard,
    )

    this.clear = mobx.reaction(() => states.selected, async selected => {
      if(selected == 'postcard') {
        postcard.show(1)
        knowledgecard.show(0)
      }

      else {
        postcard.show(0)
        knowledgecard.show(1)
      }

      const [
        all,
        result
      ] = await Promise.all([
        service(api).all({
          page: 1,
          size: 300,
          type: selected == 'postcard' ? 1 : 2,
        }),
        service(api).list({
          page: 1,
          size: 300,
          type: selected == 'postcard' ? 1 : 2,
        }),
      ])

      Object.assign(states, {
        list: all.data.list,
        owned: result.data.list,
        total: all.data.total
      })
    })

    game.setAnchor(postcard)
    game.setAnchor(knowledgecard)

    game.enableButton(postcard, () => states.selected = 'postcard')
    game.enableButton(knowledgecard, () => states.selected = 'knowledgecard')
  }

  onDidMount() {

  }

  onDestroyed() {
    this.clear()
  }
}

class Content extends Component {
  get x() {
    return this.sprites.panel.x
  }

  get y() {
    return this.sprites.panel.y
  }

  get gx() {
    return this.sprites.panel.gx
  }

  get gy() {
    return this.sprites.panel.gy
  }

  get width() {
    return this.sprites.panel.width
  }

  get height() {
    return this.sprites.panel.height
  }

  get halfWidth() {
    return this.sprites.panel.halfWidth
  }

  get halfHeight() {
    return this.sprites.panel.halfHeight
  }

  constructor(app, game, scale, states) {
    super()

    this.app = app
    this.game = game
    this.scale = scale

    this.states = mobx.observable({
      page: 1,
      size: 12,
      total: 80,
      dataset: [],
      loading: false,
      dragging: false,

      pressPoint: {
        x: 0,
        y: 0,
      },

      releasePoint: {
        x: 0,
        y: 0,
      },

      get viewWidth() {
        return game.getReal(224, scale) * 3
      },

      get viewHeight() {
        return game.getReal(224, scale) * 4
      },

      get pageWidth() {
        return this.viewWidth + 16
      },

      get offsetWidth() {
        return panel.halfWidth - this.viewWidth * 0.5 - (this.page - 1) * this.pageWidth
      },

      get pageCount() {
        return Math.ceil(this.total / this.size)
      },

      get readCount() {
        if(states.selected == 'postcard') {
          return R.uniqBy(data => data.id, (states.owned || []).filter(item => item.type == 1)).length
        }

        return R.uniqBy(data => data.id, (states.owned || []).filter(item => item.type == 2)).length
      },

      get pagecontentCoordinate() {
        return {
          x: panel.halfWidth - this.viewWidth * 0.5,
          y: 12,
        }
      },

      get pagenumberCoordinate() {
        return {
          x: panel.width - pagenumber.width - game.getReal(40, scale),
          y: panel.height - pagenumber.height - game.getReal(40, scale),
        }
      },

      get paginationCoordinate() {
        return {
          x: panel.halfWidth - pagination.halfWidth,
          y: this.pagenumberCoordinate.y + pagenumber.halfHeight,
        }
      },
    })

    const pagenumber = app.text('00/00', 'bold 12px serif', '#333')
    const panel = app.sprite([ 'collection_panel_content.png', 'collection_details_panel.png' ])

    const pagination = app.group()
    const pagecontent = app.group()

    const tablecontent = app.group(pagenumber, pagination, pagecontent)
    const detailcontent = app.group()

    game.setRealSize(panel, scale)
    // game.setRealSize(pagenumber, scale)

    panel.interactive = true
    pagenumber.interactive = true

    pagination.interactive = true
    pagecontent.interactive = true

    panel.alpha = 1
    pagenumber.alpha = 1

    pagination.alpha = 1
    pagecontent.alpha = 0

    pagenumber.x = this.states.pagenumberCoordinate.x
    pagenumber.y = this.states.pagenumberCoordinate.y

    pagination.x = this.states.paginationCoordinate.x
    pagination.y = this.states.paginationCoordinate.y

    pagecontent.x = this.states.pagecontentCoordinate.x
    pagecontent.y = this.states.pagecontentCoordinate.y

    game.hammer.on('panstart', event => {
      if(app.hitTestPoint(event.center, panel, true) == false) {
        return 0
      }

      this.states.dragging = true
      this.states.pressPoint = panel.toLocal(event.center)
    })

    game.hammer.on('panmove', event => {
      if(this.states.dragging == false) {
        return 0
      }

      pagecontent.x = game.getReal(panel.toLocal(event.center).x - this.states.pressPoint.x, scale) + this.states.offsetWidth
    })

    game.hammer.on('panend', event => {
      if(this.states.dragging == false) {
        return 0
      }

      const { app } = this
      const { states } = this

      const offset = pagecontent.x -states.offsetWidth
      const direct = pagecontent.x > states.offsetWidth

      const guardleft = R.or(states.page - 1 < 1)
      const guardright = R.or(states.page + 1 > states.pageCount)

      /* 向左切换页面 */
      if(direct) {

        const page = R.ifElse(
          () => guardleft(Math.abs(offset) < 60),
          () => states.page = states.page - 0,
          () => states.page = states.page - 1,
        )

        const animation = R.ifElse(
          () => guardleft(Math.abs(offset) < 60),
          () => app.slide(pagecontent, (states.offsetWidth + states.pageWidth * 0) * 1, pagecontent.y, 8, 'acceleration'),
          () => app.slide(pagecontent, (states.offsetWidth + states.pageWidth * 1) * 1, pagecontent.y, 8, 'acceleration'),
        )

        animation().onComplete = () => {
          states.page = page()
        }

      }

      /* 向右切换页面 */
      else {

        const page = R.ifElse(
          () => guardright(Math.abs(offset) < 60),
          () => states.page = states.page + 0,
          () => states.page = states.page + 1,
        )

        const animation = R.ifElse(
          () => guardright(Math.abs(offset) < 60),
          () => app.slide(pagecontent, (states.pageWidth * 0 - states.offsetWidth) * -1, pagecontent.y, 8, 'acceleration'),
          () => app.slide(pagecontent, (states.pageWidth * 1 - states.offsetWidth) * -1, pagecontent.y, 8, 'acceleration'),
        )

        animation().onComplete = () => {
          states.page = page()
        }

      }

      states.dragging = false
      states.releasePoint = panel.toLocal(event.center)
    })

    this.sprites = {
      panel,
      pagenumber,
      pagination,
      pagecontent,
      tablecontent,
      detailcontent,
    }

    this.root = app.group(
      panel,
      tablecontent,
      detailcontent,
    )

    this.clear = R.call(() => {
      const list = mobx.reaction(() => states.list, async () => {
        Object.assign(this.states, {
          page: 1,
          total: states.total,
          dataset: states.list,
        })

        this.showTable()

        await new Promise(resolve => (app.fadeOut(pagecontent, 8)).onComplete = () => resolve(null))

        pagecontent.remove(...pagecontent.children)
        pagecontent.add(geneBooks(app, game, scale, this.states.pageCount, this.states.dataset, states.owned, data => this.showDetail(data)))

        pagenumber.x = this.states.pagenumberCoordinate.x
        pagenumber.y = this.states.pagenumberCoordinate.y

        pagination.x = this.states.paginationCoordinate.x
        pagination.y = this.states.paginationCoordinate.y

        pagecontent.x = this.states.pagecontentCoordinate.x
        pagecontent.y = this.states.pagecontentCoordinate.y

        await new Promise(resolve => (app.fadeIn(pagecontent, 8)).onComplete = () => resolve(null))
      })

      const page = mobx.autorun(() => {
        this.states.total = states.total
        this.states.dataset = states.list

        pagination.remove(...pagination.children)
        pagination.add(geneDots(app, game, scale, this.states.pageCount, this.states.page))

        this.sprites.pagenumber.interactive = false
        this.sprites.pagenumber.content = `${this.states.readCount}/${this.states.total}`

        this.sprites.pagenumber.x = this.states.pagenumberCoordinate.x
        this.sprites.pagenumber.y = this.states.pagenumberCoordinate.y

        pagination.x = this.states.paginationCoordinate.x
        pagination.y = this.states.paginationCoordinate.y
      })

      return () => {
        page()
        list()
      }
    })

    pagecontent.remove(...pagecontent.children)
    pagecontent.add(geneBooks(app, game, scale, this.states.pageCount, this.states.dataset, states.owned, data => this.showDetail(data)))

    this.showTable()
  }

  onDidMount() {
    this.sprites.pagecontent.mask = this.app.rectangle(
      this.width - 16,
      this.height - 16,

      '#fff',
      '#fff',

      0,

      this.gx + 8,
      this.gy + 8,
    )

    this.app.fadeIn(this.sprites.pagecontent, 8)
  }

  onDestroyed() {
    this.clear()
    this.app.remove(this.sprites.pagecontent.mask)

    this.game.hammer.off('panmove')
    this.game.hammer.off('panstart')
    this.game.hammer.off('panend')
  }

  showTable() {
    this.sprites.panel.show(0)

    this.sprites.tablecontent.visible = true
    this.sprites.detailcontent.visible = false
  }

  showDetail(data) {
    const { app, game, scale } = this

    const format = R.compose(
      R.join('\n'),
      R.map(R.join('')),
      R.splitEvery(16),
      R.split(''),
      R.replace('\n', ''),
      R.defaultTo(''),
    )

    const ditem = app.sprite(`${game.profiles.oss}/${data.icon}`)
    const dback = app.sprite('collection_details_back.png')
    const dcell = app.sprite('collection_details_cell_panel.png')
    const dlabel = app.sprite('collection_details_cell_panel_label.png')

    const dtitle = app.create(() => new PIXI.Text(data.name, { fontSize: 50, fontFamily: 'sans', fontWeight: 'bold', fill: '#333', align: 'center' }))
    const dsummary = app.create(() => new PIXI.Text(format(data.content), { fontSize: 40, fontFamily: 'sans', fill: '#333', align: 'center' }))

    ditem.alpha = 0
    dlabel.alpha = 0

    ditem.width = 686
    ditem.height = 512

    if(data.attribute == 2) {
      dlabel.alpha = 1
    }

    game.setRealSize(dback, scale)
    game.setRealSize(dcell, scale)
    game.setRealSize(ditem, scale)
    game.setRealSize(dlabel, scale)

    game.setRealSize(dtitle, scale)
    game.setRealSize(dsummary, scale)

    ditem.interactive = true
    dback.interactive = true

    ditem.on('tap', () => game.modals.card.render(data))

    dcell.x = this.sprites.panel.halfWidth - dcell.halfWidth
    dcell.y = game.getReal(30, scale)

    dlabel.x = this.sprites.panel.halfWidth - dlabel.halfWidth
    dlabel.y = game.getReal(30, scale)

    ditem.x = dcell.x + dcell.halfWidth - ditem.halfWidth
    ditem.y = dcell.y + dcell.halfHeight - ditem.halfHeight

    dtitle.x = this.sprites.panel.halfWidth - dtitle.halfWidth
    dtitle.y = dcell.y + dcell.height + game.getReal(30, scale)

    dsummary.x = this.sprites.panel.halfWidth - dsummary.halfWidth
    dsummary.y = dtitle.y + dtitle.height + game.getReal(56, scale)

    dback.x = this.sprites.panel.halfWidth - dback.halfWidth
    dback.y = this.sprites.panel.height - dback.height - game.getReal(72, scale)

    game.setAnchor(dback)

    game.enableButton(dback, () => {
      this.showTable()
    })

    this.sprites.detailcontent.remove(...this.sprites.detailcontent.children)

    this.sprites.detailcontent.add(
      dcell,
      dback,
      ditem,
      dtitle,
      dlabel,
      dsummary,
    )

    this.sprites.panel.show(1)

    this.sprites.tablecontent.visible = false
    this.sprites.detailcontent.visible = true

    app.fadeIn(ditem, 16)
  }
}

export default class Dialog extends Modal {
  get halfWidth() {
    return this.components.content.halfWidth
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = Number.parseFloat(((game.screenHeight - (game.screenHeight * 0.2)) / 1690).toFixed(3))
  }

  async render(type = 'postcard') {
    const { app, game, scale } = this

    const [
      all,
      result
    ] = await Promise.all([
      service(api).all({
        page: 1,
        size: 300,
        type: type == 'postcard' ? 1 : 2,
      }),
      service(api).list({
        page: 1,
        size: 300,
        type: type == 'postcard' ? 1 : 2,
      }),
    ])

    this.states = mobx.observable({
      selected: type,
      page: 1,
      list: all.data.list,
      total: all.data.total,
      owned: result.data.list,
    })

    const main = app.group()

    const tabs = new Tabs(app, game, scale, this.states)
    const content = new Content(app, game, scale, this.states)

    const mask = app.sprite('shade.png')
    const close = app.sprite('collection_close.png')
    const earth = app.sprite('collection_panel_earth.png')

    main.add(earth)
    main.add(close)

    main.add(tabs.root)
    main.add(content.root)

    mask.interactive = true
    close.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    close.width = close.width * scale
    close.height = close.height * scale

    earth.width = earth.width * scale
    earth.height = earth.height * scale

    earth.x = (content.width - earth.width) * 0.5
    earth.y = 0

    tabs.root.x = content.halfWidth - tabs.root.halfWidth
    tabs.root.y = 360 * scale

    content.root.x = 0
    content.root.y = tabs.root.height + tabs.root.y - 3

    close.x = content.halfWidth - close.halfWidth
    close.y = content.height + content.root.y + 24

    main.x = game.halfWidth - content.halfWidth
    main.y = game.halfHeight - main.halfHeight

    game.setAnchor(close)

    game.enableButton(close, async () => {
      await this.hide()
      await this.destroy()
    }, 'close')

    this.sprites = {
      mask,
      main,
    }

    this.components = {
      tabs,
      content,
    }

    this.root = app.group(
      mask,
      main,
    )

    this.root.on('removed', () => {
      tabs.onDestroyed()
      content.onDestroyed()
    })

    return this.show().then(async () => {
      tabs.onDidMount()
      content.onDidMount()
    })
  }
}
