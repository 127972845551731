import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Countdown extends Component {
  timing: any

  prefix: string = ''
  suffix: string = ''

  get number() {
    return this.sprites.number.text
  }

  set number(value) {
    this.sprites.number.text = `${this.prefix}${numeral(value).format('00')}${this.suffix}`
    this.sprites.number.x = this.sprites.panel.halfWidth - this.sprites.number.halfWidth
  }

  start(value) {
    return new Promise(resolve => {
      const count = () => {
        if(value == -1) {
          this.timing = false
          return resolve(0)
        }

        else {
          this.number = value
          this.timing = setTimeout(count, 1000)
        }

        value = value - 1
      }

      clearTimeout(this.timing)
      count()
    })
  }

  constructor(app, game) {
    super()

    const style = {
      fontSize: 40,
      fontFamily: 'Arial',
      fill: '#fff',
      stroke: '#333',
      strokeThickness: 8,
    }

    const panel = app.sprite('stage_contest_time_panel.png')
    const number = app.create(() => new PIXI.Text('', style))

    panel.x = 0
    panel.y = number.halfHeight - 3

    number.x = panel.halfWidth - number.halfWidth
    number.y = 0

    this.sprites = {
      panel,
      number,
    }

    this.root = app.group(
      panel,
      number,
    )
  }
}
