import api from '@sar/http'
import service from '@services/exam'

const { reaction, observable } = mobx

enum MODE {
  TEAM,
  MATCH,
  MATCHING,
}

enum STATUS {
  DONE,
  UNDONE,
}

function updateUrlStates(params, path = '/contest') {
  const host = location.host
  const protocol = location.protocol

  const format = R.compose(
    R.join('&'),
    R.map(R.join('=')),
    R.toPairs,
  )

  if(APP_ENV.prefix) {
    history.pushState(null, null, `${protocol}//${host}${APP_ENV.prefix}${path}?${format(params)}`)
  }

  else {
    history.pushState(null, null, `${protocol}//${host}${path}?${format(params)}`)
  }
}

export default class Page {
  private app
  private game
  private root
  private ticker

  private mode = observable.box()
  private status = observable.box()

  private player = observable.array([])
  private sprites = observable.array([])

  private props = observable.array(new Array(2))
  private actress = observable.array(new Array(2))

  constructor(app, game) {
    this.app = app
    this.game = game
  }

  async render(scale, circular, options) {
    const { app, game } = this

    const url = new URL(location.href)

    const fix = R.multiply(scale)
    const fit = R.curry(e => Object.assign(e, { width: e.width * scale, height: e.height * scale }))

    const lens = {
      my: R.nth(0),
      opponent: R.nth(1),
    }

    const frames = [
      app.circle(148, '#363533'),
      app.circle(148, '#363533'),
    ]

    const clips = [
      app.circle(132, '#fff'),
      app.circle(132, '#fff'),
    ]

    const titles = [
      app.text('', 'bold 12px sans', '#333333'),
      app.text('', 'bold 12px sans', '#333333'),
    ]

    const unprepared = [
      app.create(() => new PIXI.Text('未准备', { fontSize: 12, fontWeight: '300', fill: '#fff', stroke: '#333', strokeThickness: 3 })),
      app.create(() => new PIXI.Text('未准备', { fontSize: 12, fontWeight: '300', fill: '#fff', stroke: '#333', strokeThickness: 3 }))
    ]

    const prepared = [
      app.create(() => new PIXI.Text('已就绪', { fontSize: 12, fontWeight: '300', fill: '#fff', stroke: '#5988cd', strokeThickness: 3 })),
      app.create(() => new PIXI.Text('已就绪', { fontSize: 12, fontWeight: '300', fill: '#fff', stroke: '#5988cd', strokeThickness: 3 }))
    ]

    const tips = [
      app.sprite('stage_contest_level_panel.png'),
      app.sprite('stage_contest_level_panel.png'),
    ]

    const items = [
      app.sprite('stage_contest_item_sel.png'),
      app.sprite('stage_contest_item_sel.png'),
    ]

    const selects = [
      app.sprite('stage_contest_additem.png'),
      app.sprite('stage_contest_additem.png'),
    ]

    const title = app.sprite('stage_contest_item_title.png')
    const panel = app.sprite('stage_contest_item_panel.png')
    const scoreboard = app.sprite('stage_contest_vs_panel.png')

    const invite = app.sprite(['stage_contest_btn3.png', 'stage_contest_btn3.png'])
    const rollback = app.sprite(['stage_contest_btn9.png', 'stage_contest_btn14.png'])
    const ready = app.sprite(['stage_contest_btn12.png', 'stage_contest_btn13.png', 'stage_contest_btn16.png', 'stage_contest_btn17.png'])

    invite.interactive = true
    ready.interactive = true
    rollback.interactive = true

    R.ap([ e => e.alpha = 0 ], unprepared)
    R.ap([ e => e.alpha = 0 ], prepared)

    R.ap([ e => e.visible = false ], unprepared)
    R.ap([ e => e.visible = false ], prepared)

    R.ap([ R.curry(fit)(R.__, scale) ], tips)
    R.ap([ R.curry(fit)(R.__, scale) ], clips)
    R.ap([ R.curry(fit)(R.__, scale) ], frames)
    R.ap([ R.curry(fit)(R.__, scale) ], items)
    R.ap([ R.curry(fit)(R.__, scale) ], selects)

    fit(invite, scale)
    fit(scoreboard, scale)
    fit(panel, scale)
    fit(title, scale)
    fit(ready, scale)
    fit(rollback, scale)

    scoreboard.x = panel.halfWidth - scoreboard.halfWidth
    scoreboard.y = lens.my(tips).height

    panel.x = 0
    panel.y = scoreboard.y + scoreboard.height + fix(60)

    title.x = panel.halfWidth - title.halfWidth
    title.y = panel.y + fix(80)

    invite.x = panel.halfWidth - invite.halfWidth
    invite.y = panel.y + panel.height + fix(60)

    ready.x = panel.halfWidth - ready.halfWidth
    ready.y = invite.y + invite.height + fix(60)

    rollback.x = panel.halfWidth - rollback.halfWidth
    rollback.y = ready.y + ready.height + fix(60)

    lens.my(frames).x = scoreboard.x + lens.my(frames).halfWidth + fix(90)
    lens.my(frames).y = scoreboard.y + scoreboard.halfHeight

    lens.opponent(frames).x = scoreboard.x + scoreboard.width - lens.opponent(frames).halfWidth - fix(90)
    lens.opponent(frames).y = scoreboard.y + scoreboard.halfHeight

    lens.my(clips).x = lens.my(frames).x
    lens.my(clips).y = lens.my(frames).y

    lens.opponent(clips).x = lens.opponent(frames).x
    lens.opponent(clips).y = lens.opponent(frames).y

    lens.my(tips).x = lens.my(frames).x - lens.my(tips).halfWidth
    lens.my(tips).y = lens.my(frames).y - lens.my(frames).halfHeight - lens.my(tips).height - fix(16)

    lens.opponent(tips).x = lens.opponent(frames).x - lens.opponent(tips).halfWidth
    lens.opponent(tips).y = lens.opponent(frames).y - lens.opponent(frames).halfHeight - lens.opponent(tips).height - fix(16)

    lens.my(titles).x = lens.my(tips).x + lens.my(tips).halfWidth - lens.my(titles).halfWidth
    lens.my(titles).y = lens.my(tips).y + lens.my(tips).halfHeight - lens.my(titles).halfHeight - fix(8)

    lens.opponent(titles).x = lens.opponent(tips).x + lens.opponent(tips).halfWidth - lens.opponent(titles).halfWidth
    lens.opponent(titles).y = lens.opponent(tips).y + lens.opponent(tips).halfHeight - lens.opponent(titles).halfHeight - fix(8)

    lens.my(unprepared).x = lens.my(frames).x - lens.my(unprepared).halfWidth
    lens.my(unprepared).y = lens.my(frames).y + lens.my(unprepared).halfHeight

    lens.opponent(unprepared).x = lens.opponent(frames).x - lens.opponent(unprepared).halfWidth
    lens.opponent(unprepared).y = lens.opponent(frames).y + lens.opponent(unprepared).halfHeight

    lens.my(prepared).x = lens.my(frames).x - lens.my(prepared).halfWidth
    lens.my(prepared).y = lens.my(frames).y + lens.my(prepared).halfHeight

    lens.opponent(prepared).x = lens.opponent(frames).x - lens.opponent(prepared).halfWidth
    lens.opponent(prepared).y = lens.opponent(frames).y + lens.opponent(prepared).halfHeight

    R.nth(0, items).x = panel.halfWidth - R.nth(0, items).halfWidth
    R.nth(0, items).y = title.y + title.height + fix(160)

    R.nth(1, items).x = panel.halfWidth - R.nth(0, items).halfWidth
    R.nth(1, items).y = R.nth(0, items).y + R.nth(0, items).height + fix(32)

    R.nth(0, selects).x = R.nth(0, items).x + R.nth(0, items).halfWidth - R.nth(0, selects).halfWidth
    R.nth(0, selects).y = R.nth(0, items).y + R.nth(0, items).halfHeight - R.nth(0, selects).halfHeight + fix(12)

    R.nth(1, selects).x = R.nth(1, items).x + R.nth(1, items).halfWidth - R.nth(1, selects).halfWidth
    R.nth(1, selects).y = R.nth(1, items).y + R.nth(1, items).halfHeight - R.nth(1, selects).halfHeight + fix(12)

    this.root = app.group(
      scoreboard,
      panel,
      title,
      ready,
      rollback,
      invite,
    )

    this.root.add(
      ...frames,
      ...clips,
      ...tips,
      ...titles,
      ...items,
      ...selects,
      ...unprepared,
      ...prepared,
    )

    this.root.x = this.game.halfWidth - this.root.halfWidth
    this.root.y = this.game.halfHeight - this.root.halfHeight

    this.root.alpha = url.searchParams.get('battle') == '1' ? 1 : 0
    this.root.interactive = url.searchParams.get('battle') == '1' ? false : true

    Array.from(R.flatten([ ready ])).forEach((e, index) => game.setAnchor(e))
    Array.from(R.flatten([ ready ])).forEach((e, index) => game.enableButton(e, async () => {
      if(this.mode.get() == MODE.TEAM) {
        const friend = this.actress[1]

        // 好友还未加入游戏
        if(friend?.id == null) {
          return game.modals.toast.render('请等待好友加入')
        }

        ready.show(2)
        // ready.tint = 0x666666
        ready.interactive = false

        const exam = await game.api.post(`${APP_ENV.api}/api/exam/start`, { mode: 1, fid: friend.id, shopId: this.props.filter(R.identity).map(R.prop('shopId')).join(',') }).then(R.prop('data'))

        game.socket.send({
          type: 'question',
          action: 'answer',
          data: {
            examId: exam.examId,
            fid: friend.id,
            roomId: new URL(location.href).searchParams.get('room'),
          },
        })
      }

      else if(this.mode.get() == MODE.MATCHING) {
        if(this.ticker) {
          clearTimeout(this.ticker)
          this.ticker = null
        }

        game.socket.send({
          type: 'question',
          action: 'del',
        })

        await game.hide(R.nth(1, titles))

        ready.show(0)
        this.mode.set(MODE.MATCH)
        this.actress.replace([this.actress[0], null])

        await game.show(R.nth(1, titles))
      }

      // 开始匹配
      else {
        ready.show(3)
        this.mode.set(MODE.MATCHING)

        const element = app.sprite('stage_contest_icon_match.png')

        element.width = 150
        element.height = 150

        fit(element)

        element.x = R.nth(1, clips).x - element.halfWidth
        element.y = R.nth(1, clips).y - element.halfHeight

        this.root.add(element)
        this.player.splice(index, 1, element)

        const start = moment().unix()

        const countdown = () => {
          R.nth(1, titles).text = moment.unix(moment().unix() - start).format('mm:ss')

          if(this.mode.get() == MODE.MATCHING) {
            this.ticker = setTimeout(countdown, 1000)
          }
        }

        await game.hide(R.nth(1, titles))

        R.nth(1, titles).text = '00:00'
        R.nth(1, titles).x = R.nth(1, tips).x + R.nth(1, tips).halfWidth - R.nth(1, titles).halfWidth

        countdown()

        await game.show(R.nth(1, titles))

        game.socket.send({
          data: {
            uid: game.identity.user.id,
          },
          type: 'question',
        })
      }
    }))

    Array.from(R.flatten([ rollback ])).forEach((e, index) => game.setAnchor(e))
    Array.from(R.flatten([ rollback ])).forEach((e, index) => game.enableButton(e, () => {
      if(this.mode.get() == MODE.TEAM) {
        game.socket.send({
          type: 'room',
          action: 'del',
          data: {
            roomId: new URL(location.href).searchParams.get('room'),
          },
        })

        // 更新 url 但不重载页面
        updateUrlStates({
          battle: 1,
          mode: MODE.MATCH,
          token: url.searchParams.get('token'),
        })

        // ready.tint = 0xffffff
        ready.interactive = true

        return this.mode.set(MODE.MATCH)
      }

      else {
        // 取消匹配
        game.socket.send({
          type: 'question',
          action: 'del',
          data: {
            fid: new URL(location.href).searchParams.get('fid'),
          }
        })

        return new Promise(resolve => (app.fadeOut(this.root, 16)).onComplete = resolve).then(() => {
          app.remove(this.root)
          this.root = null

          updateUrlStates({ token: url.searchParams.get('token') }, '/contest')

          game.socket.unsubscribe('question')
        }).then(circular)
      }
    }))

    Array.from(R.flatten([ selects ])).forEach((e, index) => game.setAnchor(e))
    Array.from(R.flatten([ selects ])).forEach((e, index) => {
      game.enableButton(e, () => {
        if(this.status.get() == STATUS.DONE) {
          game.modals.toast.render('准备就绪后无法选择道具')
        }

        else {
          game.modals.items.render(true, 2, true, this.props.slice()).then(data => this.props.replace(R.update(index, data, this.props.slice()))).catch(e => console.log('取消选择'))
        }
      })
    })

    // 邀请按钮点击事件
    lens.opponent(frames).on('tap', async () => {
      try {
        if(this.mode.get() == MODE.MATCHING) {
          return 0;
        }

        lens.opponent(frames).interactive = false

        const result = await api.post(`${APP_ENV.api}/api/room/getNum`)

        const params = {
          battle: 1,
          token: 2,
          mode: MODE.TEAM,
          room: result.data.roomId,
          fid: game.identity.user.id,
        }

        const value = `${location.protocol}//${location.host}/contest?${R.toPairs(params).map(R.join('=')).join('&')}`
        const element = document.getElementById('input') as any

        element.setAttribute('value', value)
        element.select()
        element.setSelectionRange(0, value.length)
        document.execCommand('copy')
        element.blur()

        game.socket.send({
          type: 'question',
          data: {
            uid: game.identity.user.id,
            roomId: result.data.roomId,
          },
        })

        wx.miniProgram.postMessage({
          data:{
            url:'/contest',
            battle: 1,
            mode: MODE.TEAM,
            room: result.data.roomId,
            fid: game.identity.user.id,
          }
        })

        game.modals.message.render('请点击右上角的“···”菜单分享给微信好友，开启对战！')

        this.mode.set(MODE.TEAM)

        delete params.fid

        updateUrlStates({
          ...params,
          token: url.searchParams.get('token'),
        })
      }

      catch (error) {
        console.log(error)
      }

      finally {
        lens.opponent(frames).interactive = true
      }
    })

    game.setAnchor(invite)
    game.enableButton(invite, async () => {
      try {
        if(this.mode.get() == MODE.MATCHING) {
          return game.modals.toast.render('取消匹配后才能邀请好友')
        }

        invite.interactive = false

        const result = await api.post(`${APP_ENV.api}/api/room/getNum`)

        const params = {
          battle: 1,
          token: 2,
          mode: MODE.TEAM,
          room: result.data.roomId,
          fid: game.identity.user.id,
        }

        const value = `${location.protocol}//${location.host}/contest?${R.toPairs(params).map(R.join('=')).join('&')}`
        const element = document.getElementById('input') as any

        element.setAttribute('value', value)
        element.select()
        element.setSelectionRange(0, value.length)
        document.execCommand('copy')
        element.blur()

        game.socket.send({
          type: 'question',
          data: {
            uid: game.identity.user.id,
            roomId: result.data.roomId,
          },
        })

        wx.miniProgram.postMessage({
          data:{
            url:'/contest',
            battle: 1,
            mode: MODE.TEAM,
            room: result.data.roomId,
            fid: game.identity.user.id,
          }
        })

        game.modals.message.render('请点击右上角的“···”菜单分享给微信好友，开启对战！')

        this.mode.set(MODE.TEAM)

        delete params.fid

        updateUrlStates({
          ...params,
          token: url.searchParams.get('token'),
        })
      }

      catch (error) {
        console.log(error)
      }

      finally {
        invite.interactive = true
      }
    })

    const props = reaction(() => this.props.slice(), props => {
      props.forEach((e, index) => {
        if(this.sprites[index] != null) Promise.all([ app.remove(this.sprites[index]), this.sprites[index] = null ]);

        if(e == null) return R.nth(index, selects).alpha = 1;

        R.nth(index, selects).alpha = 0

        const element = app.sprite(`${game.profiles.oss}/${e.shopUrl}`)

        element.width = 240
        element.height = 240

        fit(element)

        element.x = R.nth(index, items).x + R.nth(index, items).halfWidth - element.halfWidth
        element.y = R.nth(index, items).y + R.nth(index, items).halfHeight - element.halfHeight + fix(12)

        this.root.add(element)
        this.sprites.splice(index, 1, element)
      })
    })

    const actress = reaction(() => this.actress.slice(), actress => {
      actress.forEach((e, index) => {
        if(this.player[index] != null) Promise.all([ app.remove(this.player[index]), this.player[index] = null ]);

        if(e == null) {
          R.nth(index, frames).interactive = true
          R.nth(index, titles).text = '邀请好友'

          const element = app.sprite('stage_contest_btn11.png')

          element.width = 140
          element.height = 140

          fit(element)

          element.x = R.nth(index, clips).x - element.halfWidth
          element.y = R.nth(index, clips).y - element.halfHeight

          this.root.add(element)
          this.player.splice(index, 1, element)
        }

        else {
          R.nth(index, frames).interactive = false
          R.nth(index, titles).text = e.name

          const element = app.sprite(e.url)

          element.width = 132
          element.height = 132

          fit(element)

          element.x = R.nth(index, clips).x - element.halfWidth
          element.y = R.nth(index, clips).y - element.halfHeight

          element.mask = R.nth(index, clips)

          this.root.add(element)
          this.player.splice(index, 1, element)
        }

        if(this.actress.slice().filter(R.identity).length > 1) {
          R.ap([ e => e.layer = 3 ], unprepared)
          R.ap([ e => e.layer = 3 ], prepared)

          R.ap([ e => e.visible = true ], unprepared)
          R.ap([ e => e.visible = true ], prepared)
        }

        else {
          R.ap([ e => e.layer = 3 ], unprepared)
          R.ap([ e => e.layer = 3 ], prepared)

          R.ap([ e => e.visible = false ], unprepared)
          R.ap([ e => e.visible = false ], prepared)
        }

        R.nth(index, titles).x = R.nth(index, tips).x + R.nth(index, tips).halfWidth - R.nth(index, titles).halfWidth
      })
    })

    const mode = reaction(() => this.mode.get(), mode => {
      if(mode == MODE.MATCH) {
        ready.show(0)
        rollback.show(0)
      }

      else if(mode == MODE.TEAM){
        ready.show(1)
        rollback.show(1)
      }
    })

    const status = reaction(() => this.status.get(), status => {

    })

    this.mode.set(game.url.searchParams.get('mode') ? parseInt(game.url.searchParams.get('mode')) : MODE.MATCH)
    this.status.set(STATUS.UNDONE)

    this.actress.replace([
      {
        id: game.identity.user.id,
        url: game.identity.user.avatarUrl,
        name: game.identity.user.nickname,
      },
      null
    ])

    this.props.replace(options?.items ?? new Array(2))

    game.socket.listen('question', R.identity, R.identity, R.T, (e, res) => {
      // 更新准备状态
      if(res.status == 0 && res.action == 'answer') {
        if(e.uAnswerStatus === true) {
          lens.my(prepared).alpha = 1
          lens.my(unprepared).alpha = 0
        }

        else {
          lens.my(prepared).alpha = 0
          lens.my(unprepared).alpha = 1
        }

        if(e.fAnswerStatus === true) {
          lens.opponent(prepared).alpha = 1
          lens.opponent(unprepared).alpha = 0
        }

        else {
          lens.opponent(prepared).alpha = 0
          lens.opponent(unprepared).alpha = 1
        }
      }

      // 准备完毕, 进入答题
      if(res.status == 0 && res.action == 'answer' && e.uAnswerStatus == true && e.fAnswerStatus == true) {
        this.root.interactiveChildren = false

        game.socket.unsubscribe('question')

        new Promise(resolve => (app.fadeOut(this.root, 16)).onComplete = resolve).then(() => game.stages.answer.render({
          id: e.examId,
          fid: this.actress[1]?.id,
          room: new URL(location.href).searchParams.get('room'),
          props: this.props.filter(R.identity).slice(),
          roles: this.actress.filter(R.identity).slice(),
          circular,
          scale,
        })).then(() => {
          app.remove(this.root)
          this.root = null
        })
      }

      // 好友加入游戏
      if(res.status == 0 && res.action == 'battle' && e.fid && e.favater && e.fname) {
        if(this.ticker) {
          clearTimeout(this.ticker)
          this.ticker = null
        }

        this.mode.set(MODE.TEAM)

        game.url.push('/contest', {
          fid: e.fid,
          room: e.roomId,
          mode: 0,
          battle: 1,
          token: game.url.searchParams.get('token'),
        })

        this.actress.replace([
          {
            id: game.identity.user.id,
            url: game.identity.user.avatarUrl,
            name: game.identity.user.nickname,
          },
          {
            id: e.fid,
            url: e.favater,
            name: e.fname,
          }
        ])
      }

      // 房间失效或已解散
      else if(res.status == 200001) {
        updateUrlStates({
          battle: 1,
          mode: MODE.MATCH,
          token: new URL(location.href).searchParams.get('token'),
        })

        // 回到匹配模式
        this.mode.set(MODE.MATCH)
        this.status.set(STATUS.UNDONE)
        this.actress.replace([ this.actress[0], null ])

        // R.ap([ e => e.visible = false ], unprepared)
        // R.ap([ e => e.visible = false ], prepared)

        game.modals.toast.render('对战已解散')
      }

      // 玩家不能与自己对战
      else if(res.status == 200005) {
        updateUrlStates({
          battle: 1,
          mode: MODE.MATCH,
          token: new URL(location.href).searchParams.get('token'),
        })

        // 回到匹配模式
        this.mode.set(MODE.MATCH)
        this.actress.replace([ this.actress[0], null ])

        game.modals.toast.render('玩家不能与自己对战')
      }

      // 玩家离线中
      else if(res.status == 200002) {
        this.actress.replace([ this.actress[0], null ])
      }
    })

    if(url.searchParams.get('fid')) {
      game.socket.send({
        type: 'question',
        data: {
          uid: game.identity.user.id,
          fid: parseInt(url.searchParams.get('fid')),
          roomId: url.searchParams.get('room'),
        },
      })
    }

    return new Promise(resolve => (app.fadeIn(this.root, 16)).onComplete = resolve).then(() => this.root.on('removed', () => {
      const clear = R.ap([ e => e() ])
      const reset = R.ap([ e => e.replace(e.map(R.always(null))) ])

      clear([
        mode,
        status,
        props,
        actress,
      ])

      reset([
        this.player,
        this.sprites,
        this.props,
        this.actress,
      ])

      this.mode.set()
      this.status.set()
    }))
  }
}
