import api from '@sar/http'
import service from '@services/store'

import Fish from '@components/fish'
import Shell from '@components/shell'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

const format = R.compose(
  R.join('\n'),
  R.map(R.join('')),
  R.splitEvery(10),
  R.split(''),
  R.defaultTo(''),
)

class Tabs extends Component {
  active(type) {
    this.sprites.edibles.alpha = type == 1 ? 1 : 0
    this.sprites.property.alpha = type == 2 ? 1 : 0
    this.sprites.decorate.alpha = type == 3 ? 1 : 0
  }

  constructor(app, game, scale, states) {
    super()

    this.app = app
    this.game = game
    this.scale = scale

    const panel = app.sprite('shop_page.png')
    const edibles = app.sprite('shop_page1.png')
    const property = app.sprite('shop_page2.png')
    const decorate = app.sprite('shop_page3.png')

    edibles.alpha = 1
    property.alpha = 0
    decorate.alpha = 0

    edibles.interactive = true
    property.interactive = true
    decorate.interactive = true

    edibles.x = 0
    edibles.y = panel.halfHeight - edibles.halfHeight

    edibles.putRight(property)
    property.putRight(decorate)

    edibles.on('tap', () => states.actived = 1)
    property.on('tap', () => states.actived = 2)
    decorate.on('tap', () => states.actived = 3)

    this.sprites = {
      panel,
      edibles,
      property,
      decorate,
    }

    this.root = app.group(
      panel,
      edibles,
      property,
      decorate,
    )
  }
}

class Content extends Component {
  states = mobx.observable({
    dragging: false,
    touchend: null,
    touchstart: null,
    lastlocation: null,
    uplocation: null,
    downlocation: null,
  })

  constructor(app, game, scale, states) {
    super()

    this.app = app
    this.game = game
    this.scale = scale

    const mask = app.rectangle(860, 1100, '#fff')
    const border = app.rectangle(860, 1100, '#fff')
    const content = app.grid(0, 0, 800, 280, true, 10, 0, () => app.group())

    mask.alpha = 1
    border.alpha = 0
    content.alpha = 0

    border.mask = null
    content.mask = mask

    game.hammer.on('panstart', event => {
      const body = {
        x: border.x,
        y: border.y,
        gx: border.gx,
        gy: border.gy,
        xAnchorOffset: border.xAnchorOffset,
        yAnchorOffset: border.yAnchorOffset,
        width: game.getReal(border.width, scale),
        height: game.getReal(border.height, scale),
        _bumpPropertiesAdded: true,
      }

      if(app.hitTestPoint(event.center, body, true) == false) {
        return 0
      }

      this.states.dragging = true
      this.states.touchstart = event
    })

    game.hammer.on('panmove', event => {
      if(this.states.dragging == false) {
        return 0
      }

      content.y = border.toLocal(event.center).y - border.toLocal(this.states.touchstart.center).y + this.states.modifylocation
    })

    game.hammer.on('panend', event => {
      if(this.states.dragging == false) {
        return 0
      }

      const { app } = this
      const { states } = this

      const reachedup = R.gt(content.y, states.uplocation)
      const reacheddown = R.lt(content.y, states.downlocation)

      if(reachedup) {
        app.slide(content, content.x, states.uplocation, 8, 'acceleration')
      }

      else if(reacheddown) {
        app.slide(content, content.x, states.downlocation, 8, 'acceleration')
      }

      states.dragging = false
      states.touchend = event
      states.modifylocation = reachedup ? states.uplocation : reacheddown ? states.downlocation : content.y
    })

    this.sprites = {
      mask,
      border,
      content,
    }

    this.root = app.group(
      mask,
      border,
      content,
    )
  }

  geneItem(data, index) {
    const { app } = this
    const { game } = this

    const root = app.group()
    const href = `${game.profiles.oss}/${data.img}`

    return R.tap(root => {
      const handle = app.group()
      const profile = app.group()

      const icon = app.sprite(href)
      const unit = app.sprite('shop_fish_icon.png')
      const button = app.sprite((data.type == 1 || data.type == 2) ? 'stage_contest_item_btn.png' : 'stage_contest_item_btnns.png')

      const price = app.create(() => new PIXI.Text('', { fontSize: 26, fontFamily: 'sans', fill: '#333', leading: 12 }))
      const summary = app.create(() => new PIXI.Text('', { fontSize: 30, fontFamily: 'sans', fill: '#333', leading: 12 }))

      const panel = app.roundRectangle(800, 240, 16, '#e5f2fe', '#e5f2fe', 6)
      const picture = app.roundRectangle(176, 176, 16, '#ffffff', '#81affd', 6)
      const comment = app.roundRectangle(340, 160, 16, '#ffffff', '#ffffff', 6)

      icon.alpha = 0

      profile.add(comment)
      profile.add(summary)

      handle.add(unit)
      handle.add(price)
      handle.add(button)

      button.interactive = true
      picture.interactive = true

      price.text = `×${data.price}`
      summary.text = `${format(data.detail)}`

      icon.width = 182
      icon.height = 182

      icon.x = 30
      icon.y = panel.halfHeight - picture.halfHeight

      picture.x = 30
      picture.y = panel.halfHeight - picture.halfHeight

      profile.x = 30 + picture.x + picture.width
      profile.y = panel.halfHeight - profile.halfHeight

      summary.x = 20
      summary.y = 25

      unit.x = 6
      unit.y = 0

      price.x = 3 + unit.x + unit.width
      price.y = unit.halfHeight - price.halfHeight

      button.x = 0
      button.y = 3 + unit.x + unit.width

      handle.x = 30 + profile.x + profile.width
      handle.y = panel.halfHeight - handle.halfHeight

      root.x = 26
      root.y = index * panel.height + index * 36

      root.add(panel)
      root.add(picture)
      root.add(handle)
      root.add(profile)
      root.add(icon)

      if(data.type == 1 || data.type == 2) {
        game.setAnchor(button)
        game.enableButton(button, () => service(api).buy({ id: data.id }).then(() => game.sound.buy.play()).then(() => game.refreshUserData()).then(() => game.modals.toast.render(`已购买, ${data.name}!`)))
      }

      app.charm.wait(300).then(() => app.fadeIn(icon, 8))
    }, root)
  }

  onDestroyed() {
    this.game.hammer.off('panstart')
    this.game.hammer.off('panmove')
    this.game.hammer.off('panend')
  }

  async update(data: any[]) {
    const { app, states, sprites } = this

    await new Promise(resolve => (app.fadeOut(sprites.content, 8)).onComplete = () => resolve(null))

    sprites.content.x = 0
    sprites.content.y = 0

    sprites.content.remove(...sprites.content.children)
    sprites.content.add(...data.map((item, index) => this.geneItem(item, index)))

    states.modifylocation = sprites.content.y
    states.uplocation = sprites.content.y
    states.downlocation = -(Math.max(sprites.content.height, sprites.border.height) - Math.min(sprites.content.height, sprites.border.height) - sprites.border.y)

    if(sprites.content.height < sprites.border.height) {
      states.downlocation = 0
    }

    await new Promise(resolve => (app.fadeIn(sprites.content, 8)).onComplete = () => resolve(null))
  }
}

export default class Dialog extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1448
  }

  async load(type: number) {
    const result = await service(api).list({
      type,
      page: 1,
      size: 1000,
    })

    this.states.list = result.data.list
  }

  render() {
    const { app, game, scale } = this

    this.states = mobx.observable({
      list: [],
      actived: 1,
    })

    const main = app.group()
    const close = app.sprite('close_btn_up.png')

    const mask = app.sprite('shade.png')
    const panel = app.sprite('shop_panel.png')

    const fish = new Fish(app, game)
    const shell = new Shell(app, game)

    const tabs = new Tabs(app, game, scale, this.states)
    const content = new Content(app, game, scale, this.states)

    game.setRealSize(tabs, scale * 1.0)
    game.setRealSize(panel, scale * 1.0)
    game.setRealSize(close, scale * 1.2)
    game.setRealSize(content, scale * 1.0)

    main.add(panel)
    main.add(close)

    main.add(fish.root)
    main.add(shell.root)

    main.add(tabs.root)
    main.add(content.root)

    mask.interactive = true
    close.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    fish.x = panel.width - fish.width - game.getReal(16, scale)
    fish.y = panel.y - fish.height + game.getReal(120, scale)

    shell.x = fish.x - shell.width - game.getReal(16, scale)
    shell.y = panel.y - shell.height + game.getReal(120, scale)

    close.x = panel.halfWidth - close.halfWidth
    close.y = game.getReal(30, scale) + panel.x + panel.height

    tabs.x = game.getReal(500, scale)
    tabs.y = game.getReal(180, scale)

    content.x = game.getReal(16, scale)
    content.y = game.getReal(286, scale)

    main.x = game.halfWidth - main.halfWidth
    main.y = game.halfHeight - main.halfHeight

    fish.number = game.identity.user.fishCount
    shell.number = game.identity.user.financialCount

    game.setAnchor(close)
    game.enableButton(close, () => this.hide().then(() => this.destroy()), 'close')

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    this.clear = R.call(() => {
      const clearLoad = mobx.reaction(() => this.states.actived, value => this.load(value))
      const clearList = mobx.reaction(() => this.states.list, value => content.update(value))
      const clearActived = mobx.reaction(() => this.states.actived, value => tabs.active(value))

      const clearFish = mobx.reaction(() => game.identity.user.fishCount, number => Object.assign(fish, { number }))
      const clearShell = mobx.reaction(() => game.identity.user.financialCount, number => Object.assign(shell, { number }))

      return () => {
        clearLoad()
        clearList()
        clearFish()
        clearShell()
        clearActived()
      }
    })

    return Promise.race([ this.show(), this.load(this.states.actived) ]).then(() => {
      tabs.onDidMount()
      content.onDidMount()

      this.root.on('removed', () => this.clear())
      this.root.on('removed', () => tabs.onDestroyed())
      this.root.on('removed', () => content.onDestroyed())
    })
  }
}
