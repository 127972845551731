import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Settee extends Component {
  show(status: string) {
    if(status == 'normal') {
      this.sprites.sad.visible = false
      this.sprites.happy.visible = false
      this.sprites.normal.visible = true
      this.sprites.reading.visible = false

      this.sprites.sad.stopAnimation([0, 180])
      this.sprites.happy.stopAnimation([0, 239])
      this.sprites.normal.playAnimation([240, 240])
      this.sprites.reading.stopAnimation([0, 180])
    }

    else if (status == 'sad') {
      this.sprites.sad.visible = true
      this.sprites.happy.visible = false
      this.sprites.normal.visible = false
      this.sprites.reading.visible = false

      this.sprites.sad.playAnimation([0, 180])
      this.sprites.happy.stopAnimation([0, 239])
      this.sprites.normal.stopAnimation([240, 240])
      this.sprites.reading.stopAnimation([0, 180])
    }

    else if (status == 'happy') {
      this.sprites.sad.visible = false
      this.sprites.happy.visible = true
      this.sprites.normal.visible = false
      this.sprites.reading.visible = false

      this.sprites.sad.stopAnimation([0, 180])
      this.sprites.happy.playAnimation([0, 239])
      this.sprites.normal.stopAnimation([240, 240])
      this.sprites.reading.stopAnimation([0, 180])
    }

    else if (status == 'reading') {
      this.sprites.sad.visible = false
      this.sprites.happy.visible = false
      this.sprites.normal.visible = false
      this.sprites.reading.visible = true

      this.sprites.sad.stopAnimation([0, 180])
      this.sprites.happy.stopAnimation([0, 239])
      this.sprites.normal.stopAnimation([240, 240])
      this.sprites.reading.playAnimation([0, 180])
    }
  }

  constructor(app, game) {
    super()

    const sad = app.sprite(R.times((i: number) => `animate_sad_${numeral(i + 1).format('000')}.png`, 180))
    const happy = app.sprite(R.times((i: number) => `animate_rest_${numeral(i + 0).format('000')}.png`, 241))
    const normal = app.sprite(R.times((i: number) => `animate_rest_${numeral(i + 0).format('000')}.png`, 241))
    const reading = app.sprite(R.times((i: number) => `animate_reading_${numeral(i + 1).format('000')}.png`, 180))

    sad.fps = 26
    happy.fps = 26
    normal.fps = 26
    reading.fps = 26

    sad.visible = false
    happy.visible = false
    normal.visible = false
    reading.visible = false

    sad.loop = true
    happy.loop = true
    normal.loop = true
    reading.loop = true

    game.setRealSize(sad, game.scale)
    game.setRealSize(happy, game.scale)
    game.setRealSize(normal, game.scale)
    game.setRealSize(reading, game.scale)

    this.app = app
    this.game = game

    this.sprites = { sad, happy, normal, reading }
    this.root = app.group( sad, happy, normal, reading )

    this.states = mobx.observable({ status: 'normal' })
    this.clear = mobx.reaction(() => mobx.toJS(this.states), states => this.show(states.status))
  }

  onDestroyed() {
    this.clear()
  }
}
