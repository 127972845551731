import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.5) / 1268
  }

  render() {
    const { app, game, scale } = this

    const main = app.group()
    const mask = app.sprite('shade.png')

    const panel = app.sprite('sys_panel.png')
    const close = app.sprite('close_btn_up.png')

    const music = R.call(() => {
      const btn = app.sprite([ 'sys_btn.png' ])
      const panel = app.sprite([ 'sys_on.png', 'sys_off.png' ])

      game.setRealSize(btn, scale)
      game.setRealSize(panel, scale)

      const selected = () => {
        panel.show(0)
        btn.x = panel.width - btn.width
      }

      const unselected = () => {
        panel.show(1)
        btn.x = 0
      }

      return Object.assign(app.group(panel, btn), { selected, unselected })
    })

    const sound = R.call(() => {
      const btn = app.sprite([ 'sys_btn.png' ])
      const panel = app.sprite([ 'sys_on.png', 'sys_off.png' ])

      game.setRealSize(btn, scale)
      game.setRealSize(panel, scale)

      const selected = () => {
        panel.show(0)
        btn.x = panel.width - btn.width
      }

      const unselected = () => {
        panel.show(1)
        btn.x = 0
      }

      return Object.assign(app.group(panel, btn), { selected, unselected })
    })

    main.add(panel)
    main.add(close)
    main.add(music)
    main.add(sound)

    mask.interactive = true
    close.interactive = true
    music.interactive = true
    sound.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    panel.width = scale * panel.width
    panel.height = scale * panel.height

    close.width = game.scale * close.width * 1.2
    close.height = game.scale * close.height * 1.2

    panel.x = 0
    panel.y = 0

    music.x = game.getReal(480, scale)
    music.y = game.getReal(574, scale)

    sound.x = game.getReal(480, scale)
    sound.y = game.getReal(804, scale)

    close.x = panel.halfWidth - close.halfWidth - game.getReal(16, scale)
    close.y = panel.height + panel.y + game.getReal(30, scale)

    main.x = game.halfWidth - main.halfWidth + game.getReal(24, scale)
    main.y = game.halfHeight - main.halfHeight - game.getReal(56, scale)

    game.setAnchor(close)
    game.enableButton(close, () => this.hide().then(() => this.destroy()), 'close')

    game.setPivot(sound)
    game.enableButton(sound, () => (game.states.sound ? game.disableSound() : game.enableSound()).then(() => Object.assign(game.states, { sound: R.not(game.states.sound) })))

    game.setPivot(music)
    game.enableButton(music, () => (game.states.music ? game.disableMusic() : game.enableMusic()).then(() => Object.assign(game.states, { music: R.not(game.states.music) })))

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    this.clear = mobx.autorun(() => {
      if(game.states.music) {
        music.selected()
      }

      else {
        music.unselected()
      }

      if(game.states.sound) {
        sound.selected()
      }

      else {
        sound.unselected()
      }
    })

    return this.show().then(() => this.root.on('removed', () => this.clear()))
  }
}
