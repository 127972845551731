import api from '@sar/http'
import service from '@services/task'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

async function cover(app, game) {
  const renderTexture = PIXI.RenderTexture.create({ width: game.screenWidth, height: game.screenHeight })

  app.renderer.render(app.stage, { renderTexture })

  const cover = app.create(() => PIXI.Sprite.from(app.renderer.extract.image(renderTexture)))
  const mask = app.rectangle(game.screenWidth, game.screenHeight, '#fff')

  renderTexture.destroy()

  cover.alpha = 0
  mask.alpha = 0
  mask.interactive = true

  await new Promise(resolve => (app.fadeIn(mask, 16)).onComplete = () => resolve(0))
  await new Promise(resolve => (app.fadeOut(mask, 16)).onComplete = () => resolve(0))

  game.stages.share.render(cover).then(() => app.remove(mask))
}

export default class Dialog extends Modal {
  get width() {
    return this.sprites.panel.width
  }

  get height() {
    return this.sprites.panel.height + this.sprites.close.height + 6
  }

  get halfWidth() {
    return this.sprites.panel.halfWidth
  }

  get halfHeight() {
    return this.sprites.panel.halfHeight + this.sprites.close.halfHeight + 3
  }

  geneItem(data) {
    const { app, game } = this

    const icon = app.sprite('task_list_icon_sample.png')
    const title = app.text(data.title, '40px sans', '#333')
    const button = app.sprite([ 'task_list_btn.png', 'task_list_done.png' ])
    const panel = app.sprite([ 'task_list_panel1.png', 'task_list_panel2.png' ])

    panel.interactive = false
    button.interactive = true

    icon.x = 40
    icon.y = panel.halfHeight - icon.halfHeight

    button.x = panel.width - button.width - 40
    button.y = panel.halfHeight - button.halfHeight

    title.x = 180
    title.y = panel.halfHeight - title.halfHeight

    if(data.isOpen) {
      game.setAnchor(button)
      game.enableButton(button, R.identity)
    }

    else if(data.type == 5) {
      game.setAnchor(button)
      game.enableButton(button, () => this.hide().then(() => this.destroy()).then(() => cover(app, game)))
    }

    // 出行一次
    else if(data.type == 2) {
      game.setAnchor(button)
      game.enableButton(button, () => game.identity.outing ? game.modals.toast.render('海懒已经出行了哦!') : game.modals.goout.render())
    }

    // 跳转到 PK
    else if(data.type == 3){
      game.setAnchor(button)
      game.enableButton(button, async () => {
        if(game.stages.home.sprites != null){
          await this.hide()
          await game.stages.home.hide()
          await game.stages.contest.render()
          await game.music.normal.stop()
          await game.music.contest.play()
          await game.stages.home.destroy()
          await this.destroy()
        }

        else if(game.stages.outdoor.sprites != null){
          await this.hide()
          await game.stages.outdoor.hide()
          await game.stages.contest.render()
          await game.music.normal.stop()
          await game.music.contest.play()
          await game.stages.outdoor.destroy()
          await this.destroy()
        }

        else if(game.stages.contest.sprites != null){
          await this.hide()
          await this.destroy()
        }
      })
    }

    else if(data.appTask == 1) {
      game.setAnchor(button)
      game.enableButton(button, () => wx.miniProgram.switchTab({ url: data.thirdUrl }))
    }

    if(data.isOpen) {
      panel.show(1)
      button.show(1)
    }

    else {
      panel.show(0)
      button.show(0)
    }

    return app.group(
      panel,
      icon,
      button,
      title,
    )
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.25) / 1848
  }

  render() {
    const { app, game, scale } = this

    const states = mobx.observable({
      list: [],
      finished: false,
      dragging: false,
      touchend: null,
      touchstart: null,
      lastlocation: null,
      uplocation: null,
      downlocation: null,
    })

    this.states = states

    const main = app.group()
    const content = app.group()
    const message = app.group()

    const contentbox = app.rectangle(880, 1200, '#fff')
    const contentmask = app.rectangle(880, 1200, '#fff')

    const mask = app.sprite('shade.png')
    const panel = app.sprite('task_panel.png')
    const cover = app.sprite(['task_title_icon1.png', 'task_title_icon2.png'])
    const summary = app.sprite(['task_title_text1.png', 'task_title_text2.png'])
    const close = app.button(['close_btn_up.png', 'close_btn_down.png'])

    message.add(cover)
    message.add(summary)

    main.add(panel)
    main.add(close)
    main.add(message)
    main.add(content)
    main.add(contentbox)
    main.add(contentmask)

    contentbox.alpha = 0
    contentmask.alpha = 1

    contentbox.mask = null
    content.mask = contentmask

    mask.interactive = true
    close.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    close.width = game.scale * close.width * 1.25
    close.height = game.scale * close.height * 1.25

    game.setRealSize(panel, scale)
    game.setRealSize(message, scale)
    game.setRealSize(content, scale)
    game.setRealSize(contentbox, scale)
    game.setRealSize(contentmask, scale)

    cover.x = summary.x + summary.width
    cover.y = summary.y + summary.halfHeight - cover.halfHeight

    message.x = panel.halfWidth - message.halfWidth
    message.y = game.getReal(416, scale)

    contentbox.x = game.getReal(50, scale)
    contentbox.y = game.getReal(580, scale)

    contentmask.x = game.getReal(50, scale)
    contentmask.y = game.getReal(580, scale)

    content.x = game.getReal(60, scale)
    content.y = game.getReal(580, scale)

    close.x = main.halfWidth - close.halfWidth
    close.y = panel.height + 6

    main.x = game.halfWidth - panel.halfWidth
    main.y = game.halfHeight - panel.halfHeight - close.halfHeight - 3

    this.states.modifylocation = content.y
    this.states.uplocation = content.y
    this.states.downlocation = -(Math.max(content.height, contentbox.height) - Math.min(content.height, contentbox.height) - contentbox.y + 8)

    game.hammer.on('panstart', event => {
      if(app.hitTestPoint(event.center, contentbox, true) == false) {
        return 0
      }

      this.states.dragging = true
      this.states.touchstart = event
    })

    game.hammer.on('panmove', event => {
      if(this.states.dragging == false) {
        return 0
      }

      content.y = game.getReal(contentbox.toLocal(event.center).y - contentbox.toLocal(this.states.touchstart.center).y, scale) + this.states.modifylocation
    })

    game.hammer.on('panend', event => {
      if(this.states.dragging == false) {
        return 0
      }

      const { app } = this
      const { states } = this

      const reachedup = R.gt(content.y, states.uplocation)
      const reacheddown = R.lt(content.y, states.downlocation)

      if(reachedup) {
        app.slide(content, content.x, states.uplocation, 8, 'acceleration')
      }

      else if(reacheddown) {
        app.slide(content, content.x, states.downlocation, 8, 'acceleration')
      }

      states.dragging = false
      states.touchend = event
      states.modifylocation = reachedup ? states.uplocation : reacheddown ? states.downlocation : content.y
    })

    game.setAnchor(close)
    game.enableButton(close, () => this.hide().then(() => this.destroy()), 'close')

    game.setAnchor(cover)
    game.enableButton(cover, () => wx.miniProgram.navigateTo({ url: '/pagesD/paradise/paradise' }))

    this.sprites = {
      mask,
      main,
      panel,
      close,
    }

    this.root = app.group(
      mask,
      main,
    )

    this.clear = R.call(() => {
      const clearFinishedReaction = mobx.reaction(() => this.states.finished, finished => {
        if(finished) {
          cover.show(1)
          summary.show(1)

          cover.interactive = true
        }

        else {
          cover.show(0)
          summary.show(0)

          cover.interactive = false
        }
      })

      const clearListReaction = mobx.reaction(() => this.states.list, list => {
        content.remove(...content.children)
        content.add(app.grid(1, list.length, 860, 200, true, 0, 0, index => this.geneItem(index == list.length ? null : list[index])))

        game.setRealSize(content, scale)

        content.x = game.getReal(60, scale)
        content.y = game.getReal(580, scale)

        this.states.modifylocation = content.y
        this.states.uplocation = content.y
        this.states.downlocation = -(Math.max(content.height, contentbox.height) - Math.min(content.height, contentbox.height) - contentbox.y + 6)

        if(content.height < contentbox.height) {
          this.states.downlocation = content.y
        }
      })

      return () => {
        clearListReaction()
        clearFinishedReaction()
      }
    })

    this.root.on('removed', () => {
      this.clear()
      this.game.hammer.off('panstart')
      this.game.hammer.off('panmove')
      this.game.hammer.off('panend')
    })

    service(api).list({}).then(result => {
      Object.assign(this.states, {
        list: result.data.list,
      })
    })

    service(api).finish({}).then(result => {
      Object.assign(this.states, {
        finished: result.data,
      })
    })

    return this.show()
  }
}
