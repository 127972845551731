import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.2) / 1642
  }

  render() {
    const { app, game, scale } = this

    const main = app.group()
    const close = app.sprite('close_btn_up.png')

    const mask = app.sprite('shade.png')
    const panel = app.sprite('stage_contest_solo_rule.png')

    game.setRealSize(panel, scale)
    game.setRealSize(close, scale)

    main.add(panel)
    main.add(close)

    mask.interactive = true
    close.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    main.x = game.halfWidth - main.halfWidth
    main.y = game.halfHeight - main.halfHeight

    close.x = panel.width - close.width - game.getReal(80, scale)
    close.y = game.getReal(102, scale) - close.halfHeight

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    game.setAnchor(close)
    game.enableButton(close, () => this.hide().then(() => this.destroy()), 'close')

    return this.show()
  }
}
