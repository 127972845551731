import Stage from '@prototype/stage'

export default class Page extends Stage {
  progress: number = 0

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
  }

  over() {
    return new Promise(resolve => this.app.state = () => this.state(resolve))
  }

  state(complete) {
    const { app, game, sprites } = this

    const progress = Math.round(app.loader.progress)

    sprites.progress.pointer.content = numeral(progress).format('00')

    sprites.progress.pointer.x = sprites.progress.halfWidth - sprites.progress.pointer.halfWidth - sprites.progress.symbol.halfWidth - 6
    sprites.progress.pointer.y = sprites.title.y + sprites.title.height + 12

    sprites.progress.symbol.x = sprites.progress.pointer.x + sprites.progress.pointer.width
    sprites.progress.symbol.y = sprites.progress.pointer.y

    if(progress >= 100 && app.loader.progress >= 100 && game.identity?.user){
      app.state = null

      this.hide().then(() => complete())
    }
  }

  render() {
    const { app, game } = this

    const panel = app.rectangle(100, 100, '#1ac267')
    const icon = app.sprite(`${game.profiles.prefix}/images/init.png`)

    const dots = app.create(() => new PIXI.Text('', { fontSize: 12, fill: '#fff' }))
    const title = app.create(() => new PIXI.Text('请稍等片刻', { fontSize: 40, fill: '#fff' }))

    const symbol = app.text('', '28px serif', '#fff')
    const pointer = app.text('', '28px serif', '#fff')
    const version = app.text('', '32px serif', '#fff')

    const progress = app.group(icon, title, dots, pointer, symbol)
    const messages = app.group(version)

    icon.width = 290
    icon.height = 310

    game.setRealSize(icon)
    game.setRealSize(title)
    game.setRealSize(messages)

    panel.width = game.screenWidth
    panel.height = game.screenHeight

    progress.symbol = symbol
    progress.pointer = pointer
    messages.version = version

    dots.resolution = game.ratio
    title.resolution = game.ratio
    symbol.resolution = game.ratio
    pointer.resolution = game.ratio
    version.resolution = game.ratio

    progress.symbol.content = '%'
    progress.pointer.content = '00'
    messages.version.content = `version by ${game.profiles.version} engine by ${PIXI.VERSION}`

    messages.x = game.halfWidth - messages.halfWidth
    messages.y = game.screenHeight - messages.height - 16

    title.x = 0
    title.y = icon.y + icon.height + 12

    pointer.x = progress.halfWidth - pointer.halfWidth - symbol.halfWidth - 6
    pointer.y = title.y + title.height + 12

    symbol.x = pointer.x + pointer.width
    symbol.y = pointer.y

    dots.x = title.x + title.width
    dots.y = title.y + title.halfHeight - dots.halfHeight

    progress.x = game.halfWidth - progress.halfWidth + 12
    progress.y = game.halfHeight - progress.halfHeight - 60

    this.sprites = {
      panel,
      title,
      messages,
      progress,
    }

    this.root = app.group(
      panel,
      messages,
      progress,
    )

    let index = 1

    const interval = setInterval(() => {

      dots.text = R.repeat('･', index).join('')

      index = index + 1

      if(index >= 4) {
        index = 0
      }
    }, 300)

    this.root.on('remove', () => {
      clearInterval(interval)
    })

    return this.over()
  }
}
