import api from '@sar/http'
import service from '@services/out'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  data: any

  get width() {
    return this.sprites.panel.width
  }

  get height() {
    return this.sprites.panel.height + this.sprites.close.height + 6
  }

  get halfWidth() {
    return this.sprites.panel.halfWidth
  }

  get halfHeight() {
    return this.sprites.panel.halfHeight + this.sprites.close.halfHeight + 3
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1600
  }

  render(num = 100) {
    if(this.root != null) return 0;

    const { app, game, scale } = this

    const main = app.group()
    const content = app.group()

    const mask = app.sprite('shade.png')
    const panel = app.sprite('reward_panel.png')
    const fish = app.sprite('reward_icon_fish.png')
    const cover = app.sprite('reward_icon_panel.png')
    const close = app.button([ 'close_btn_up.png', 'close_btn_down.png' ])

    const title = app.create(() => new PIXI.Text('本次收获', { fontSize: 64, fontWeight: 'bold', fill: '#fff' }))
    const number = app.create(() => new PIXI.Text(`小鱼干 × ${num}`, { fontSize: 48, fill: '#333', align: 'center' }))
    const summary = app.create(() => new PIXI.Text('今天又是大丰收\n好耶!', { fontSize: 50, fill: '#666', fontWeight: 'bold', align: 'center' }))

    content.add(cover)
    content.add(fish)
    content.add(title)
    content.add(number)
    content.add(summary)

    main.add(panel)
    main.add(close)
    main.add(content)

    mask.interactive = true
    close.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    game.setRealSize(fish, scale * 1.0)
    game.setRealSize(panel, scale * 1.0)
    game.setRealSize(title, scale * 1.0)
    game.setRealSize(cover, scale * 1.0)
    game.setRealSize(close, scale * 1.3)
    game.setRealSize(number, scale * 1.0)
    game.setRealSize(summary, scale * 1.0)

    title.x = panel.halfWidth - title.halfWidth
    title.y = game.getReal(490, scale)

    cover.x = panel.halfWidth - cover.halfWidth
    cover.y = game.getReal(860, scale)

    summary.x = panel.halfWidth - summary.halfWidth
    summary.y = game.getReal(660, scale)

    fish.x = panel.halfWidth - fish.halfWidth
    fish.y = game.getReal(860, scale)

    number.x = panel.halfWidth - number.halfWidth
    number.y = game.getReal(1220, scale)

    close.x = panel.halfWidth - close.halfWidth - 6
    close.y = panel.y + panel.height + 12

    main.x = game.halfWidth - panel.halfWidth
    main.y = game.halfHeight - panel.halfHeight - close.halfHeight - 6

    game.setAnchor(close)
    game.enableButton(close, () => this.hide().then(() => this.destroy()), 'close')

    this.sprites = {
      mask,
      main,
      panel,
      close,
    }

    this.root = app.group(
      mask,
      main,
    )

    return this.show()
  }
}
