import api from '@sar/http'
import service from '@services/user'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = Number.parseFloat(((game.screenHeight * 0.75) / 1319).toFixed(3))
  }

  render(name = '') {
    const { app, game, scale } = this

    const text = app.create(() => new PIXI.Text(name, { fontSize: 32, fill: '#333', fontFamily: 'JingnanBoBoH' }))
    const trigger = app.rectangle(515, 100, 'black')
    const cursor = app.line('black', 1, 0, text.y, 0, 32)

    const mask = app.sprite('shade.png')
    const icon = app.sprite('name_icon.png')
    const panel = app.sprite('name_panel.png')
    const button = app.button(['name_btn_up.png', 'name_btn_down.png'])

    const input = app.group(text, cursor, trigger)
    const main = app.group(panel, icon, input, button)

    cursor.alpha = 0
    trigger.alpha = 0

    mask.interactive = true
    button.interactive = true
    trigger.interactive = true

    trigger.width = scale * trigger.width
    trigger.height = scale * trigger.height

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    panel.width = scale * panel.width
    panel.height = scale * panel.height

    icon.width = scale * icon.width
    icon.height = scale * icon.height

    button.width = scale * button.width
    button.height = scale * button.height

    text.x = input.halfWidth - text.halfWidth
    text.y = 0

    cursor.x = input.halfWidth - text.halfWidth + text.width
    cursor.y = 0

    input.x = main.halfWidth - input.halfWidth
    input.y = scale * 325 - input.height

    icon.x = main.halfWidth - icon.halfWidth
    icon.y = scale * 500

    button.x = main.halfWidth - button.halfWidth
    button.y = scale * 500 + icon.height + 32

    main.x = game.halfWidth - main.halfWidth
    main.y = game.halfHeight - main.halfHeight

    button.on('tap', async () => {
      await service(api).setName({ nickname: this.states.value })

      await game.refreshUserData()
      await game.modals.name.hide()
      await game.modals.name.destroy()
    })

    mask.on('tap', () => this.states.focus = false)
    trigger.on('tap', () => this.states.focus = true)

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    this.states = mobx.observable({
      value: name,
      focus: false,
    })

    this.clear = mobx.reaction(() => mobx.toJS(this.states), states => {
      const dom = document.getElementById('input')

      if(states.focus) {
        dom.blur()
        dom.focus()

        game.removeClock('cursor')
        game.enableClock('cursor', 300, () => cursor.alpha = cursor.alpha == 0 ? 1 : 0)

        dom.addEventListener('input', (event: any) => this.states.value = event.target.value)
        dom.addEventListener('propertychange', (event: any) => this.states.value = event.target.value)
      }

      else {
        dom.focus()
        dom.blur()

        game.removeClock('cursor')
        game.enableClock('cursor', 300, () => cursor.alpha = 0)

        dom.removeEventListener('input', (event: any) => this.states.value = event.target.value)
        dom.removeEventListener('propertychange', (event: any) => this.states.value = event.target.value)
      }

      text.text = states.value

      text.x = input.halfWidth - text.halfWidth
      cursor.x = input.halfWidth - text.halfWidth + text.width
    })

    // @ts-ignore
    document.getElementById('input').value = name

    return this.show().then(() => {
      this.root.on('removed', () => this.clear())
      this.root.on('removed', () => game.removeClock('cursor'))
      // @ts-ignore
      this.root.on('removed', () => document.getElementById('input').value = '')
    })
  }
}
