import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Email extends Component {
  show(num: number) {
    this.sprites.box.show(num)
    this.sprites.bird.show(num)
    this.sprites.mail.show(num)
  }

  constructor(app, game) {
    super()

    const box = app.sprite([ 'stage_outdoor_day_mail_part1.png', 'stage_outdoor_night_mail_part1.png' ])
    const bird = app.sprite([ 'stage_outdoor_day_mail_part2.png', 'stage_outdoor_night_mail_part2.png' ])
    const mail = app.sprite([ 'stage_outdoor_day_mail_part3.png', 'stage_outdoor_night_mail_part3.png' ])

    box.loop = false
    bird.loop = false
    mail.loop = false

    box.width = game.getReal(box.width)
    box.height = game.getReal(box.height)

    bird.width = game.getReal(bird.width)
    bird.height = game.getReal(bird.height)

    mail.width = game.getReal(mail.width)
    mail.height = game.getReal(mail.height)

    box.x = 0
    box.y = 0

    bird.x = -game.getReal(20)
    bird.y = -bird.height + game.getReal(10)

    mail.x = 0
    mail.y = game.getReal(42)

    this.sprites = {
      box,
      bird,
      mail,
    }

    this.root = app.group(
      box,
      bird,
      mail,
    )
  }
}
