export default api => ({
  start: function (options) {
    return api.post(`${APP_ENV.api}/api/exam/start`, options)
  },

  answer: function (options) {
    return api.post(`${APP_ENV.api}/api/exam/answer`, options)
  },

  finish: function (options) {
    return api.post(`${APP_ENV.api}/api/exam/finish`, options)
  },

  prop: function (options) {
    return api.post(`${APP_ENV.api}/api/exam/prop`, options)
  },
})
