import api from '@sar/http'
import service from '@services/out'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

export default class Dialog extends Modal {
  data: any

  get width() {
    return this.sprites.panel.width
  }

  get height() {
    return this.sprites.panel.height + this.sprites.confirm.height + this.sprites.cancel.height + 24
  }

  get halfWidth() {
    return this.sprites.panel.halfWidth
  }

  get halfHeight() {
    return this.sprites.panel.halfHeight + this.sprites.confirm.halfHeight + this.sprites.cancel.halfHeight + 12
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.3) / 1800
  }

  render() {
    const { app, game, scale } = this

    const main = app.group()
    const content = app.group()

    const mask = app.sprite('shade.png')
    const cover = app.sprite('goout_icon_back.png')
    const panel = app.sprite('goout_back_panel.png')

    const confirm = app.button(['goout_btn_back.png', 'goout_btn_back.png'])
    const cancel = app.button(['goout_btn_cancel.png', 'goout_btn_cancel.png'])

    const title = app.create(() => new PIXI.Text('提前返回', { fontSize: 64, fontWeight: 'bold', fill: '#fff' }))
    const summary = app.create(() => new PIXI.Text('渤仔正在外面旅行哦\n提前回来就得不到礼物和纪念品啦', { fontSize: 46, fill: '#666', align: 'center', leading: 12 }))

    content.add(cover)
    content.add(title)
    content.add(summary)

    main.add(panel)
    main.add(cancel)
    main.add(confirm)
    main.add(content)

    mask.interactive = true
    cancel.interactive = true
    confirm.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    game.setRealSize(panel, scale)
    game.setRealSize(title, scale)
    game.setRealSize(cover, scale)
    game.setRealSize(cancel, scale)
    game.setRealSize(confirm, scale)
    game.setRealSize(summary, scale)

    title.x = panel.halfWidth - title.halfWidth
    title.y = game.getReal(308, scale)

    cover.x = panel.halfWidth - cover.halfWidth
    cover.y = game.getReal(460, scale)

    summary.x = panel.halfWidth - summary.halfWidth
    summary.y = game.getReal(1000, scale)

    confirm.x = panel.halfWidth - cancel.halfWidth - 6
    confirm.y = panel.y + panel.height + 12

    cancel.x = panel.halfWidth - cancel.halfWidth - 6
    cancel.y = confirm.y + confirm.height + 12

    main.x = game.halfWidth - panel.halfWidth
    main.y = game.halfHeight - panel.halfHeight - cancel.halfHeight - confirm.halfHeight - 12

    game.setAnchor(cancel)
    game.enableButton(cancel, () => this.hide().then(() => this.destroy()), 'close')

    game.setAnchor(confirm)
    game.enableButton(confirm, () => service(api).back({}).then(() => () => game.refreshOutData()).then(() => game.refreshUserData()).then(() => this.hide()).then(() => this.destroy()))

    this.sprites = {
      mask,
      main,
      panel,
      cancel,
      confirm,
    }

    this.root = app.group(
      mask,
      main,
    )

    return this.show()
  }
}
