export default api => ({
  firstOrg(options) {
    return api.get(`${APP_ENV.applet}/dingTalk/firstOrg`, options)
  },

  getJsConfig (options) {
    return api.get(`${APP_ENV.applet}/api/third/getJsConfig`, options)
  },

  shareLink (options) {
    return api.get(`${APP_ENV.applet}/gameApi/shareLink`, options)
  },

  syncCoins (options) {
    return api.post(`${APP_ENV.applet}/gameApi/syncCoins`, options)
  },

  coins (options) {
    return api.post(`${APP_ENV.api}/api/third/coins`, options)
  },
})
