export default store => async function notify(response) {
  if(response.code != 200) {
    return Promise.reject(`network error code with ${response?.code}.`)
  }

  else if(response.data?.ret == 0 || response.data?.code == 200) {
    return Promise.resolve(response.data)
  }

  else {
    return Promise.reject(`api system error code with ${R.defaultTo(response.data?.ret, response.data?.code)}`).finally(() => store.modals.message.render(response.data.msg))
  }
}
