import api from '@sar/http'
import service from '@services/user'
import outService from '@services/out'
import fishingService from '@services/fishing'

export default new class Game {
  app: any
  api: any
  url: any
  utils: any
  hammer: any
  socket: any

  profiles = {
    api: APP_ENV.api,
    oss: APP_ENV.oss,
    socket: APP_ENV.socket,
    adapter: APP_ENV.adapter,
    timeout: APP_ENV.timeout,
    version: APP_ENV.version,
    prefix: APP_ENV.prefix ?? '',
  }

  readonly identity: any = mobx.observable({
    user: null,
    fishing: null,
  })

  sound: any
  music: any

  readonly tasks: any = {}
  readonly sounds: any = {}
  readonly states: any = {}
  readonly stages: any = {}
  readonly modals: any = {}

  readonly width: number = 1080
  readonly heigth: number = 2400

  get ratio() {
    return window.devicePixelRatio
  }

  get scale() {
    return this.screenWidth / this.width
  }

  get screenWidth() {
    return window.outerWidth
  }

  get screenHeight() {
    return window.outerHeight
  }

  get actualWidth() {
    return Math.round(this.width * this.scale)
  }

  get actualHeigth() {
    return Math.round(this.heigth * this.scale)
  }

  get halfWidth() {
    return Math.round(this.screenWidth * 0.5)
  }

  get halfHeight() {
    return Math.round(this.screenHeight * 0.5)
  }

  get offset() {
    const min = Math.min(this.actualHeigth, this.screenHeight)
    const max = Math.max(this.actualHeigth, this.screenHeight)

    return this.actualHeigth < this.screenHeight
      ? ((max - min) / 2) * 1
      : ((max - min) / 2) * -1
  }

  constructor() {
    const setting = JSON.parse(localStorage.getItem('setting') ?? '{}')

    this.states = mobx.observable({
      music: setting.music ?? true,
      sound: setting.sound ?? true,
      expand: false,
    })

    mobx.autorun(() => localStorage.setItem('setting', JSON.stringify(this.states)))
  }

  store() {

  }

  restore() {

  }

  useModal(name: string, value: any) {
    this.modals[name] = value
  }

  useSound(name: string, value: any) {
    this.sounds[name] = value
  }

  useStage(name: string, value: any) {
    this.stages[name] = value
  }

  getReal(value: number, scale: number) {
    if(scale) {
      return value * scale
    }

    else {
      return value * this.scale
    }
  }

  setRealSize(sprite: any, scale: number) {
    if(scale) {
      sprite.width = sprite.width * scale
      sprite.height = sprite.height * scale
    }

    else {
      sprite.width = sprite.width * this.scale
      sprite.height = sprite.height * this.scale
    }
  }

  setPivot(sprite: any) {
    sprite.pivot.x = sprite.halfWidth
    sprite.pivot.y = sprite.halfHeight

    sprite.x = sprite.x + sprite.halfWidth
    sprite.y = sprite.y + sprite.halfHeight
  }

  setAnchor(sprite: any) {
    sprite.anchor.x = 0.5
    sprite.anchor.y = 0.5

    sprite.x = sprite.x + sprite.halfWidth
    sprite.y = sprite.y + sprite.halfHeight
  }

  getIdentity(key: string) {
    if(this.identity[key]) {
      return this.identity[key]
    }

    return JSON.parse(localStorage.getItem(key))
  }

  setIdentity(key: string, value: any) {
    Object.assign(this.identity, { [key]: value })
    localStorage.setItem(key, JSON.stringify(value))
  }

  enableClock(id: string, time: number, task: Function) {
    if(this.tasks[id]) {
      throw new Error(`A scheduled task with id '${id}' already exists`)
    }

    task()

    this.tasks[id] = setInterval(task, time)
  }

  enableButton(sprite: any, callback: Function, type = 'normal') {
    sprite.interactive = true

    const state = {
      playing: false,
    }

    const start = {
      x: sprite.scale.x,
      y: sprite.scale.y,
    }

    const end = {
      x: sprite.scale.x * 1.125,
      y: sprite.scale.x * 1.125,
    }

    sprite.on('tap', event => {
      if(state.playing) {
        return 0
      }

      else {
        if(type == 'normal') {
          this.sound.tap.play()
        }

        else if(type == 'close') {
          this.sound.close.play()
        }

        const enlarge = this.app.scaler(sprite, end.x, end.y, 6)
        const decrease = this.app.scaler(sprite, start.x, start.y, 6)

        decrease.pause()

        enlarge.onComplete = () => {
          decrease.play()
        }

        decrease.onComplete = () => {
          callback(event)
          state.playing = false
        }

        state.playing = true
      }
    })
  }

  removeClock(id: string) {
    if(this.tasks[id]) {
      clearInterval(this.tasks[id])

      delete this.tasks[id]
    }
  }

  async refreshUserData() {
    const result = await service(api).detail({
      id: this.identity.user.id
    })

    Object.assign(this.identity.user, result.data)
  }

  async refreshOutData() {
    const result = await outService(api).list({ type: 0, page: 1, size: 1 })

    const out = R.compose(
      R.nth(0),
      R.prop('list'),
      R.prop('data'),
    )

    const outing = R.compose(
      R.not,
      R.isNil,
      R.nth(0),
      R.prop('list'),
      R.prop('data'),
    )

    this.setIdentity('out', out(result))
    this.setIdentity('outing', outing(result))
  }

  async refreshFishingData() {
    const [
      resultA,
      resultB,
    ] = await Promise.all([
      fishingService(api).stock({ type: 'A' }),
      fishingService(api).stock({ type: 'B' }),
    ])

    Object.assign(this.identity, {
      fishing: {
        a: resultA.data,
        b: resultB.data,
      }
    })
  }

  async enableMusic() {
    this.music.normal.mute(false)
    this.music.contest.mute(false)
  }

  async disableMusic() {
    this.music.normal.mute(true)
    this.music.contest.mute(true)
  }

  async enableSound() {
    this.sound.shy.mute(false)
    this.sound.tap.mute(false)
    this.sound.buy.mute(false)
    this.sound.close.mute(false)
    this.sound.reward.mute(false)
    this.sound.defeat.mute(false)
    this.sound.victory.mute(false)
    this.sound.cutover.mute(false)
    this.sound.upgrade.mute(false)
  }

  async disableSound() {
    this.sound.shy.mute(true)
    this.sound.tap.mute(true)
    this.sound.buy.mute(true)
    this.sound.close.mute(true)
    this.sound.reward.mute(true)
    this.sound.defeat.mute(true)
    this.sound.victory.mute(true)
    this.sound.cutover.mute(true)
    this.sound.upgrade.mute(true)
  }

  render(View) {
    return new View(this.app, this)
  }

  show(e) {
    return new Promise(resolve => (this.app.fadeIn(e, 16)).onComplete = resolve)
  }

  hide(e) {
    return new Promise(resolve => (this.app.fadeOut(e, 16)).onComplete = resolve)
  }
}
