export default api => ({
  login: function (options) {
    return api.post(`${APP_ENV.api}/api/login`, options)
  },

  detail: function (options) {
    return api.post(`${APP_ENV.api}/api/user/detail`, options)
  },

  level: function(options) {
    return api.post(`${APP_ENV.api}/api/user/level`, options)
  },

  myrank: function (options) {
    return api.post(`${APP_ENV.api}/api/user/user_rank`, options)
  },

  rank: function (options) {
    return api.post(`${APP_ENV.api}/api/user/rank_list`, options)
  },

  setName: function (options) {
    return api.post(`${APP_ENV.api}/api/user/setName`, options)
  },

  setNew: function (options) {
    return api.post(`${APP_ENV.api}/api/user/setNew`, options)
  },

  depository: function (options) {
    return api.post(`${APP_ENV.api}/api/user/depository`, options)
  },
})
