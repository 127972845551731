export default class Component {
  app: any
  game: any
  root: any
  clear: any
  scale: any
  states: any
  sprites: any

  set x(value) {
    this.root.x = value
  }

  set y(value) {
    this.root.y = value
  }

  set alpha(value) {
    this.root.alpha = value
  }

  set layer(value) {
    this.root.layer = value
  }

  set width(value) {
    this.root.width = value
  }

  set height(value) {
    this.root.height = value
  }

  set visible(value) {
    this.root.visible = value
  }

  set interactive(value) {
    this.root.interactive = value
  }

  set interactiveChildren(value) {
    this.root.interactiveChildren = value
  }

  get x() {
    return this.root.x
  }

  get y() {
    return this.root.y
  }

  get gx() {
    return this.root.gx
  }

  get gy() {
    return this.root.gy
  }

  get alpha() {
    return this.root.alpha
  }

  get layer() {
    return this.root.layer
  }

  get visible() {
    return this.root.visible
  }

  get interactive() {
    return this.root.interactive
  }

  get interactiveChildren() {
    return this.root.interactiveChildren
  }

  get width() {
    return this.root.width
  }

  get height() {
    return this.root.height
  }

  get halfWidth() {
    return this.root.halfWidth
  }

  get halfHeight() {
    return this.root.halfHeight
  }

  on(type: string, handle: (any) => void) {
    this.root.on(type, handle)
  }

  onDidMount() {

  }

  onDestroyed() {

  }
}
