const { reaction, observable } = mobx

const lens = {
  my: R.nth(0),
  opponent: R.nth(1),
}

enum Status {
  normal,
  error,
  success,
}

async function float(app, element) {
  await Promise.all([
    new Promise(resolve => (app.slide(element, element.x, element.y - 90, 90)).onComplete = resolve),
    new Promise(resolve => (app.fadeIn(element, 30)).onComplete = resolve).then(() => new Promise(resolve => (app.fadeOut(element, 60)).onComplete = resolve)),
  ])
}

async function flicker(app, element) {
  await new Promise(resolve => (app.fadeOut(element, 8, 0.5)).onComplete = resolve)
  await new Promise(resolve => (app.fadeIn(element, 8, 1)).onComplete = resolve)

  await new Promise(resolve => (app.fadeOut(element, 8, 0.5)).onComplete = resolve)
  await new Promise(resolve => (app.fadeIn(element, 8, 1)).onComplete = resolve)

  await new Promise(resolve => (app.fadeOut(element, 8, 0.5)).onComplete = resolve)
  await new Promise(resolve => (app.fadeIn(element, 8, 1)).onComplete = resolve)
}

export default class View {
  private app
  private game
  private root

  private used = null
  private examId = null
  private books = new Map()
  private answered = new Set()

  private itemElements = []
  private playerElements = []
  private optionsElements = []

  private init = observable.box(true)
  private playing = observable.box(false)

  private topic = observable.box(null)
  private countdown = observable.box(null)

  private score = observable.array(R.repeat(null, 2))
  private player = observable.array(R.repeat(null, 2))

  private items = observable.array(R.repeat(null, 0))
  private topics = observable.array(R.repeat(null, 0))
  private options = observable.array(R.repeat(null, 0))

  constructor(app, game) {
    this.app = app
    this.game = game
  }

  /**
   * @param id 试卷ID
   * @param fid 好友ID
   * @param room 房间号
   * @param props 道具列表
   * @param roles 玩家列表
   */
  async render({ id, fid, room, props, roles, scale, circular }) {
    const { app, game } = this

    const fix = R.multiply(game.scale)
    const fit = R.curry(e => Object.assign(e, { width: e.width * game.scale, height: e.height * game.scale }))

    const scoreboard = app.sprite('stage_contest_vs_panel.png')
    const timerboard = app.sprite('stage_contest_time_panel.png')
    const blackboard = app.sprite('stage_contest_content_panel.png')

    const result = app.sprite([ 'stage_contest_right.png', 'stage_contest_wrong.png' ])
    const selected = app.sprite([ 'stage_contest_icon_my.png', 'stage_contest_icon_friend.png' ])

    const used = R.times(() => app.sprite([ 'stage_contest_item_panel_used.png' ]), 2)
    const items = R.times(() => app.sprite([ 'stage_contest_item_panel_none.png' ]), 2)
    const tools = R.times(() => app.sprite([ 'stage_contest_item_panel_locked.png' ]), 2)

    const scores = R.times(() => app.sprite([ 'stage_contest_plus1.png', 'stage_contest_plus3.png' ]), 2)
    const options = R.times(() => app.sprite([ 'stage_contest_sel1.png', 'stage_contest_sel2.png', 'stage_contest_sel3.png' ]), 3)

    const time = app.create(() => new PIXI.Text('', { fontSize: 14, fontWeight: '300', fill: '#fff', stroke: '#333', strokeThickness: 3, leading: 3 }))
    const title = app.create(() => new PIXI.Text('', { fontSize: 16, fontWeight: '800', fill: '#333', stroke: '#fff', strokeThickness: 3, leading: 3 }))
    const content = app.create(() => new PIXI.Text('', { fontSize: 14, fontWeight: '300', fill: '#fff', stroke: '#fff', strokeThickness: 0, leading: 3 }))

    const frames = [
      app.circle(132, '#363533'),
      app.circle(132, '#363533'),
    ]

    const clips = [
      app.circle(116, '#fff'),
      app.circle(116, '#fff'),
    ]

    const numbers = [
      app.text('0', 'bold 18px sans', '#333333'),
      app.text('0', 'bold 18px sans', '#333333'),
    ]

    R.ap([ R.curry(fit)(R.__, game.scale) ], clips)
    R.ap([ R.curry(fit)(R.__, game.scale) ], frames)
    R.ap([ R.curry(fit)(R.__, game.scale) ], used)
    R.ap([ R.curry(fit)(R.__, game.scale) ], items)
    R.ap([ R.curry(fit)(R.__, game.scale) ], tools)
    R.ap([ R.curry(fit)(R.__, game.scale) ], options)
    R.ap([ R.curry(fit)(R.__, game.scale) ], scores)

    R.ap([ e => e.visible = false ], R.flatten([ used ]))
    R.ap([ e => e.visible = false ], R.flatten([ scores ]))
    R.ap([ e => e.visible = false ], R.flatten([ result ]))
    R.ap([ e => e.visible = false ], R.flatten([ selected ]))

    R.ap([ e => e.interactive = true ], R.flatten([ items ]))
    R.ap([ e => e.interactive = true ], R.flatten([ options ]))

    fit(scoreboard)
    fit(timerboard)
    fit(blackboard)

    fit(result)
    fit(selected)

    scoreboard.x = blackboard.halfWidth - scoreboard.halfWidth + fix(8)
    scoreboard.y = 0

    blackboard.x = 0
    blackboard.y = scoreboard.y + scoreboard.height + fix(120)

    timerboard.x = fix(200)
    timerboard.y = blackboard.y + blackboard.height - timerboard.height

    lens.my(frames).x = scoreboard.x + lens.my(frames).halfWidth + fix(30)
    lens.my(frames).y = scoreboard.y + scoreboard.halfHeight

    lens.opponent(frames).x = scoreboard.x + scoreboard.width - lens.opponent(frames).halfWidth - fix(30)
    lens.opponent(frames).y = scoreboard.y + scoreboard.halfHeight

    lens.my(clips).x = lens.my(frames).x
    lens.my(clips).y = lens.my(frames).y

    lens.opponent(clips).x = lens.opponent(frames).x
    lens.opponent(clips).y = lens.opponent(frames).y

    lens.my(numbers).x = lens.my(frames).x + lens.my(frames).halfWidth + fix(36)
    lens.my(numbers).y = scoreboard.y + scoreboard.halfHeight - lens.my(numbers).halfHeight

    lens.opponent(numbers).x = lens.opponent(frames).x - lens.opponent(frames).halfWidth - lens.opponent(numbers).width - fix(36)
    lens.opponent(numbers).y = scoreboard.y + scoreboard.halfHeight - lens.opponent(numbers).halfHeight

    R.nth(0, options).x = blackboard.x + blackboard.halfWidth - R.nth(0, options).halfWidth
    R.nth(0, options).y = blackboard.y + blackboard.height + fix(60)

    R.nth(1, options).x = R.nth(0, options).x
    R.nth(1, options).y = R.nth(0, options).y + R.nth(0, options).height + fix(30)

    R.nth(2, options).x = R.nth(1, options).x
    R.nth(2, options).y = R.nth(1, options).y + R.nth(1, options).height + fix(30)

    R.nth(0, tools).x = blackboard.x + blackboard.halfWidth - R.nth(0, tools).halfWidth * 2 - fix(30)
    R.nth(0, tools).y = R.nth(2, options).y + R.nth(2, options).height + fix(90)

    R.nth(1, tools).x = R.nth(0, tools).x + R.nth(0, tools).width + fix(60)
    R.nth(1, tools).y = R.nth(2, options).y + R.nth(2, options).height + fix(90)

    // R.nth(2, tools).x = R.nth(1, tools).x + R.nth(1, tools).width + fix(60)
    // R.nth(2, tools).y = R.nth(2, options).y + R.nth(2, options).height + fix(90)

    R.nth(0, items).x = R.nth(0, tools).x + R.nth(0, tools).halfWidth - R.nth(0, items).halfWidth
    R.nth(0, items).y = R.nth(0, tools).y + R.nth(0, tools).halfHeight - R.nth(0, items).halfHeight

    R.nth(1, items).x = R.nth(1, tools).x + R.nth(1, tools).halfWidth - R.nth(1, items).halfWidth
    R.nth(1, items).y = R.nth(1, tools).y + R.nth(1, tools).halfHeight - R.nth(1, items).halfHeight

    // R.nth(2, items).x = R.nth(2, tools).x + R.nth(2, tools).halfWidth - R.nth(2, items).halfWidth
    // R.nth(2, items).y = R.nth(2, tools).y + R.nth(2, tools).halfHeight - R.nth(2, items).halfHeight

    R.nth(0, used).x = R.nth(0, tools).x + R.nth(0, tools).halfWidth - R.nth(0, used).halfWidth
    R.nth(0, used).y = R.nth(0, tools).y + R.nth(0, tools).halfHeight - R.nth(0, used).halfHeight

    R.nth(1, used).x = R.nth(1, tools).x + R.nth(1, tools).halfWidth - R.nth(1, used).halfWidth
    R.nth(1, used).y = R.nth(1, tools).y + R.nth(1, tools).halfHeight - R.nth(1, used).halfHeight

    // R.nth(2, used).x = R.nth(2, tools).x + R.nth(2, tools).halfWidth - R.nth(2, used).halfWidth
    // R.nth(2, used).y = R.nth(2, tools).y + R.nth(2, tools).halfHeight - R.nth(2, used).halfHeight

    this.root = app.group(
      scoreboard,
      blackboard,
      timerboard,

      title,
      time,
      content,

      result,
      selected,
    )

    this.root.add(
      ...frames,
      ...clips,
      ...numbers,
      ...options,
      ...tools,
      ...items,
      ...used,
      ...scores,
    )

    this.root.alpha = 0
    this.root.interactive = true

    this.root.x = this.game.halfWidth - this.root.halfWidth
    this.root.y = this.game.halfHeight - this.root.halfHeight

    items.forEach((e, index) => {
      e.on('tap', async () => {
        if(props[index] != null) {
          R.nth(index, used).interactive = true
          R.nth(index, used).visible = true
          R.nth(index, used).layer = 3

          this.used = props[index].shopId

          await game.api.post(`${APP_ENV.api}/api/exam/prop`, {
            roomId: room,
            examId: this.examId,
            shopId: props[index].shopId,
            qid: this.topics[this.topic.get()].id,
          })
        }
      })
    })

    options.forEach((e, index) => {
      e.on('tap', async () => {
        const data = this.topics[this.topic.get()]

        if(data && data?.id && this.answered.has(data.id) == false) {
          this.playing.set(true)

          selected.x = R.nth(index, options).x + fix(30)
          selected.y = R.nth(index, options).y + R.nth(index, options).halfHeight - selected.halfHeight

          selected.alpha = 1
          selected.layer = 3
          selected.visible = true

          this.answered.add(data.id)

          try {
            const response = await game.api.post(`${APP_ENV.api}/api/exam/answer`, {
              examId: this.examId,
              qid: data.id,
              roomId: room,
              userResult: this.books.get(index),
            })

            this.used = null

            if(response.data.status) {
              result.show(0)
              R.nth(index, options).show(Status.success)
            }

            else {
              result.show(1)
              R.nth(index, options).show(Status.error)
            }

            result.x = R.nth(index, options).x + R.nth(index, options).width - result.width - fix(40)
            result.y = R.nth(index, options).y + R.nth(index, options).halfHeight - result.halfHeight

            result.alpha = 1
            result.layer = 3

            this.optionsElements[index].visible = false
            result.visible = true

            await flicker(app, result)
            await app.charm.wait(1500)
          } finally {
            this.playing.set(false)
          }
        }
      })
    })

    const topicReactionClear = reaction(() => this.topic.get(), async topic => {
      if(R.is(Number, topic) == false) return;

      options.forEach(e => {
        e.interactive = false
      })

      if(this.init.get() == false) {
        await Promise.all([
          new Promise(resolve => (app.fadeOut(title, 16)).onComplete = resolve),
          new Promise(resolve => (app.fadeOut(content, 16)).onComplete = resolve),
          new Promise(resolve => (app.fadeOut(result, 16)).onComplete = resolve),
          new Promise(resolve => (app.fadeOut(selected, 16)).onComplete = resolve),
          Promise.all(options.map(e => new Promise(resolve => (app.fadeOut(e, 16)).onComplete = resolve))),
          Promise.all(this.optionsElements.filter(R.identity).map(e => new Promise(resolve => (app.fadeOut(e, 16)).onComplete = resolve))),
        ])
      }

      result.visible = false
      selected.visible = false

      const topics = this.topics.slice()
      const entries = JSON.parse(topics[topic].content)

      if(R.toPairs(entries).length == 2) {
        R.nth(2, options).visible = false
      }

      title.text = `第${R.inc(topic)}题（共${R.length(topics)}题）`

      title.x = scoreboard.x + scoreboard.halfWidth - title.halfWidth
      title.y = scoreboard.y + scoreboard.height + fix(60)

      content.text = R.compose(
        R.join('\n'),
        R.map(R.join('')),
        R.splitEvery(16),
        R.split(''),
        R.defaultTo(''),
      )(topics[topic].title)

      content.x = blackboard.x + blackboard.halfWidth - content.halfWidth + fix(8)
      content.y = blackboard.y + fix(90)

      options.forEach((e, index) => {
        e.show(Status.normal)
      })

      R.toPairs(entries).forEach((e, index) => {
        if(this.optionsElements[index] != null) app.remove(...this.optionsElements.splice(index, 1, null));

        if(R.isEmpty(e)) return;

        const element = app.text(R.compose(
          R.join('\n'),
          R.map(R.join('')),
          R.splitEvery(20),
          R.split(''),
          R.defaultTo(''),
        )(e[1]), '12px sans', '#333')

        this.books.set(index, e[0])

        element.x = R.nth(index, options).x + R.nth(index, options).halfWidth - element.halfWidth
        element.y = R.nth(index, options).y + R.nth(index, options).halfHeight - element.halfHeight

        this.root.add(element)
        this.optionsElements.splice(index, 1, element)
      })

      if(this.init.get() == false) {
        await Promise.all([
          new Promise(resolve => (app.fadeIn(title, 16)).onComplete = resolve),
          new Promise(resolve => (app.fadeIn(content, 16)).onComplete = resolve),
          Promise.all(options.map(e => new Promise(resolve => (app.fadeIn(e, 16)).onComplete = resolve)))
        ])
      }

      options.forEach(e => {
        e.interactive = true
      })

      this.init.set(false)
    })

    const countdownReactionClear = reaction(() => this.countdown.get(), countdown => {
      time.text = `剩余时间: ${numeral(countdown).format('00')}秒`

      time.x = timerboard.x + timerboard.halfWidth - time.halfWidth
      time.y = timerboard.y - time.halfHeight + fix(6)
    })

    const playerReactionClear = reaction(() => this.player.slice(), player => {
      player.forEach((e, index) => {
        if(this.playerElements[index] != null) app.remove(...this.playerElements.splice(index, 1, null));

        if(e == null) return;

        const element = app.sprite(e.url)

        element.width = 116
        element.height = 116

        fit(element)

        element.x = R.nth(index, clips).x - element.halfWidth
        element.y = R.nth(index, clips).y - element.halfHeight

        element.mask = R.nth(index, clips)

        this.root.add(element)
        this.playerElements.splice(index, 1, element)
      })
    })

    const itemsReactionClear = reaction(() => this.items.slice(), props => {
      props.forEach((e, index) => {
        if(this.itemElements[index] != null) app.remove(...this.itemElements.splice(index, 1, null));

        if(e == null) return;

        const element = app.sprite(`${game.profiles.oss}/${e.shopUrl}`)

        element.width = 160
        element.height = 160

        fit(element)

        element.x = R.nth(index, items).x + R.nth(index, items).halfWidth - element.halfWidth
        element.y = R.nth(index, items).y + R.nth(index, items).halfHeight - element.halfHeight

        this.root.add(element)
        this.itemElements.splice(index, 1, element)

        // 入场即已使用
        if(e.shopName == '幸运星星') {
          R.nth(index, used).interactive = true
          R.nth(index, used).visible = true
          R.nth(index, used).layer = 3
        }
      })
    })

    const scoreReactionClear = reaction(() => this.score.slice(), score => {
      score.forEach(async (e, index) => {
        if(e == null) return 0;

        // 加分动画
        if(parseInt(R.nth(index, numbers).text) != e && this.init.get() == false) {
          R.nth(index, scores).x = R.nth(index, numbers).x + R.nth(index, numbers).halfWidth - R.nth(index, scores).halfWidth
          R.nth(index, scores).y = R.nth(index, numbers).y - R.nth(index, scores).height - fix(6)

          R.nth(index, scores).show(1)
          R.nth(index, scores).visible = true

          float(app, R.nth(index, scores))
        }

        R.nth(index, numbers).text = e

        if(index == 0) {
          lens.my(numbers).x = lens.my(frames).x + lens.my(frames).halfWidth + fix(36)
          lens.my(numbers).y = scoreboard.y + scoreboard.halfHeight - lens.my(numbers).halfHeight
        }

        else {
          lens.opponent(numbers).x = lens.opponent(frames).x - lens.opponent(frames).halfWidth - lens.opponent(numbers).width - fix(36)
          lens.opponent(numbers).y = scoreboard.y + scoreboard.halfHeight - lens.opponent(numbers).halfHeight
        }
      })
    })

    game.api.post(`${APP_ENV.api}/api/exam/start`, {
      fid,
      mode: 1,
      status: 'restore',
      shopId: props.filter(R.identity).map(R.prop('shopId')).join(','),
    }).then(R.prop('data')).then(event => {
      this.topics.replace(event.list)
      this.items.replace(props)
      this.player.replace(roles)

      this.examId = event.examId

      game.socket.listen('question', R.identity, R.identity, R.T, async (e, res) => {
        if(res.action = 'answer') {
          this.countdown.set(e.sec)

          if(e.uNum >= 4 && e.sec == 0) {
            game.removeClock('battle-ping')
            game.socket.unsubscribe('question')

            const result = await game.api.post(`${APP_ENV.api}/api/exam/finish`, {
              fid,
              room,
              examId: event.examId,
            })

            // 渲染胜利或失败弹窗
            if('fail' == result.data.status) {
              game.sound.defeat.play()
              game.modals.settlement.render({
                type: 'battle',
                exp: result.data.exp,
                shell: result.data.conch,
                status: 'fail',
                onCancel: async () => {
                  game.socket.send({
                    type: 'question',
                    action: 'del',
                    data: {
                      fid,
                    }
                  })

                  game.url.push('/contest', {
                    token: game.url.searchParams.get('token'),
                  })

                  new Promise(resolve => (app.fadeOut(this.root, 16)).onComplete = resolve).then(() => {
                    app.remove(this.root)
                    this.root = null
                    circular()
                  })
                },
                onConfirm: () => {
                  new Promise(resolve => (app.fadeOut(this.root, 16)).onComplete = resolve).then(() => {
                    app.remove(this.root)
                    this.root = null

                    game.stages.battle.render(scale, circular)
                  })
                },
              }, 'battle')
            }

            else if('tie' == result.data.status) {
              game.sound.defeat.play()
              game.modals.settlement.render({
                type: 'battle',
                exp: result.data.exp,
                shell: result.data.conch,
                status: 'tie',
                onCancel: async () => {
                  game.socket.send({
                    type: 'question',
                    action: 'del',
                    data: {
                      fid,
                    }
                  })

                  game.url.push('/contest', {
                    token: game.url.searchParams.get('token'),
                  })

                  new Promise(resolve => (app.fadeOut(this.root, 16)).onComplete = resolve).then(() => {
                    app.remove(this.root)
                    this.root = null
                    circular()
                  })
                },
                onConfirm: () => {
                  new Promise(resolve => (app.fadeOut(this.root, 16)).onComplete = resolve).then(() => {
                    app.remove(this.root)
                    this.root = null

                    game.stages.battle.render(scale, circular)
                  })
                },
              }, 'battle')
            }

            else {
              game.sound.victory.play()
              game.modals.settlement.render({
                type: 'battle',
                exp: result.data.exp,
                shell: result.data.conch,
                status: 'victory',
                onCancel: async () => {
                  game.socket.send({
                    type: 'question',
                    action: 'del',
                    data: {
                      fid,
                    }
                  })

                  game.url.push('/contest', {
                    token: game.url.searchParams.get('token'),
                  })

                  new Promise(resolve => (app.fadeOut(this.root, 16)).onComplete = resolve).then(() => {
                    app.remove(this.root)
                    this.root = null
                    circular()
                  })
                },
                onConfirm: () => {
                  new Promise(resolve => (app.fadeOut(this.root, 16)).onComplete = resolve).then(() => {
                    app.remove(this.root)
                    this.root = null

                    game.stages.battle.render(scale, circular)
                  })
                },
              }, 'battle')
            }
          }

          else {
            this.score.replace([ e.uscore ?? 0, e.fscore ?? 0 ])
            this.topic.set(e.uNum)
          }
        }
      })

      game.enableClock('battle-ping', 1000, () => {
        game.socket.send({
          type: 'battle',
          data: {
            fid,
            room,
          }
        })
      })
    })

    return new Promise(resolve => (app.fadeIn(this.root, 16)).onComplete = resolve).then(() => this.root.on('removed', () => {
      game.socket.unsubscribe('question')

      const clear = R.ap([ e => e() ])
      const clean = R.ap([ e => e.set(null) ])
      const reset = R.ap([ e => e.replace(e.map(R.always(null))) ])

      this.used = null
      this.books = new Map()
      this.answered = new Set()

      this.itemElements = []
      this.playerElements = []
      this.optionsElements = []

      clear([
        scoreReactionClear,
        itemsReactionClear,
        topicReactionClear,
        playerReactionClear,
        countdownReactionClear,
      ])

      clean([
        this.topic,
        this.countdown,
      ])

      reset([
        this.items,
        this.score,
        this.player,
        this.topics,
        this.options,
      ])
    }))
  }
}
