export default class Stage {
  app: any
  game: any

  root: any
  clear: any
  states: any
  sprites: any
  components: any

  async show(): Promise<any> {
    const { app, game, root } = this

    const x = game.getReal(9)
    const y = game.getReal(9)

    const mask = app.sprite('trans.png')
    const shade = app.sprite('shade.png')

    root.alpha = 0
    mask.alpha = 1
    shade.alpha = 0

    mask.interactive = true
    shade.interactive = true

    shade.width = game.getReal(shade.width) * 2
    shade.height = game.getReal(shade.height) * 2

    mask.width = game.getReal(mask.width) * 2
    mask.height = game.getReal(mask.height) * 2

    mask.x = 0
    mask.y = game.offset

    shade.x = 0
    shade.y = game.offset

    mask.anchor.x = 0.5
    mask.anchor.y = 0.5

    mask.x = mask.x + mask.halfWidth
    mask.y = mask.y + mask.halfHeight

    mask.scale.x = game.getReal(3)
    mask.scale.y = game.getReal(3)

    await Promise.all([
      new Promise(resolve => (app.fadeIn(root, 40)).onComplete = () => resolve(null)),
      new Promise(resolve => (app.scaler(mask, x, y, 40)).onComplete = () => resolve(null))
    ])

    app.remove(mask)
    app.remove(shade)
  }

  async hide(): Promise<any> {
    const { app, game, root } = this

    const x = game.getReal(3)
    const y = game.getReal(3)

    const mask = app.sprite('trans.png')
    const shade = app.sprite('shade.png')

    mask.alpha = 1
    shade.alpha = 0

    mask.interactive = true
    shade.interactive = true

    shade.width = game.getReal(shade.width) * 2
    shade.height = game.getReal(shade.height) * 2

    mask.width = game.getReal(mask.width) * 2
    mask.height = game.getReal(mask.height) * 2

    mask.x = 0
    mask.y = game.offset

    shade.x = 0
    shade.y = game.offset

    mask.anchor.x = 0.5
    mask.anchor.y = 0.5

    mask.x = mask.x + mask.halfWidth
    mask.y = mask.y + mask.halfHeight

    mask.scale.x = game.getReal(9)
    mask.scale.y = game.getReal(9)

    await Promise.all([
      new Promise(resolve => (app.fadeOut(root, 40)).onComplete = () => resolve(null)),
      new Promise(resolve => (app.scaler(mask, x, y, 40)).onComplete = () => resolve(null)),
    ])

    app.remove(mask)
    app.remove(shade)
  }

  destroy(): Promise<any> {
    const { app, root } = this

    return Promise.resolve(app.remove(root)).then(() => {
      this.root = null
      this.sprites = null
      this.components = null
    })
  }
}
