export default api => ({
  add: function (options?) {
    return api.post(`${APP_ENV.api}/api/out/add`, options)
  },

  back: function (options?) {
    return api.post(`${APP_ENV.api}/api/out/back`, options)
  },

  list: function (options?) {
    return api.post(`${APP_ENV.api}/api/out/list`, options)
  },

  status: function (options?) {
    return api.post(`${APP_ENV.api}/api/out/status`, options)
  },

  finish: function (options?) {
    return api.post(`${APP_ENV.api}/api/out/finish`, options)
  },
})
