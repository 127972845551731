import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Caption extends Component {
  set title(value) {
    this.sprites.title.text = value
    this.sprites.title.x = this.sprites.panel.halfWidth - this.sprites.title.halfWidth
  }

  set content(value) {
    const format = R.compose(
      R.join('\n'),
      R.map(R.join('')),
      R.splitEvery(13),
      R.split(''),
      R.defaultTo(''),
    )

    this.sprites.text.text = format(value)
    this.sprites.text.x = this.sprites.panel.x + 240
  }

  constructor(app, game) {
    super()

    const panel = app.sprite('stage_contest_content_panel.png')

    const text = app.create(() => new PIXI.Text('', { fontSize: 48,  fill: '#fff', leading: 3, stroke: '#333', strokeThickness: 0 }))
    const title = app.create(() => new PIXI.Text('', { fontSize: 48,  fill: '#333', fontWeight: 'bold', stroke: '#fff', strokeThickness: 8 }))

    title.x = panel.halfWidth - title.halfWidth
    title.y = 0

    panel.x = 0
    panel.y = title.y + title.height + 30

    text.x = panel.x + 60
    text.y = panel.y + 80

    this.sprites = {
      panel,
      title,
      text,
    }

    this.root = app.group(
      panel,
      title,
      text,
    )
  }
}
