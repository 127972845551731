import api from '@sar/http'
import service from '@services/user'

import Modal from '@prototype/modal'
import Component from '@prototype/component'

const format = R.compose(
  R.join('\n'),
  R.map(R.join('')),
  R.splitEvery(18),
  R.split(''),
  R.defaultTo(''),
)

class Tabs extends Component {
  active(type: number) {
    if(type == 1) {
      this.sprites.edibles.alpha = 1
      this.sprites.property.alpha = 0
      this.sprites.decorate.alpha = 0
    }

    else if(type == 2) {
      this.sprites.edibles.alpha = 0
      this.sprites.property.alpha = 1
      this.sprites.decorate.alpha = 0
    }

    else if(type == 3) {
      this.sprites.edibles.alpha = 0
      this.sprites.property.alpha = 0
      this.sprites.decorate.alpha = 1
    }
  }

  constructor(app, game, scale, states) {
    super()

    this.app = app
    this.game = game
    this.scale = scale

    const panel = app.sprite('shop_page.png')
    const edibles = app.sprite('shop_page1.png')
    const property = app.sprite('shop_page2.png')
    const decorate = app.sprite('shop_page3.png')

    edibles.alpha = 1
    property.alpha = 0
    decorate.alpha = 0

    edibles.interactive = true
    property.interactive = true
    decorate.interactive = true

    edibles.x = 0
    edibles.y = panel.halfHeight - edibles.halfHeight

    edibles.putRight(property)
    property.putRight(decorate)

    edibles.on('tap', () => states.actived = 1)
    property.on('tap', () => states.actived = 2)
    decorate.on('tap', () => states.actived = 3)

    this.sprites = {
      panel,
      edibles,
      property,
      decorate,
    }

    this.root = app.group(
      panel,
      edibles,
      property,
      decorate,
    )
  }
}

class Content extends Component {
  resetPrev: any
  parentStates: any

  states = mobx.observable({
    ratio: (800 / (160 * 12)) * (861 / 800) * 1.08,
    dragging: false,
    touchend: null,
    touchstart: null,
    lastlocation: null,
    uplocation: null,
    downlocation: null,
  })

  constructor(app, game, scale, states) {
    super()

    this.app = app
    this.game = game
    this.scale = scale
    this.parentStates = states

    const content = app.group(app.grid(4, 12, 160, 160, true, 0, 0, () => this.geneItem(null)))
    const mask = app.rectangle(780, 800, '#fff')
    const border = app.rectangle(780, 800, '#fff')

    const pointer = app.sprite('bag_bar2.png')
    const scrollbar = app.sprite('bag_bar1.png')

    scrollbar.height = 800

    mask.alpha = 1
    border.alpha = 0
    content.alpha = 0

    border.mask = null
    content.mask = mask

    scrollbar.x = border.x + border.width - 48
    scrollbar.y = 0

    pointer.x = border.x + border.width - 48
    pointer.y = 0

    game.hammer.on('panstart', event => {
      const body = {
        x: border.x,
        y: border.y,
        gx: border.gx,
        gy: border.gy,
        xAnchorOffset: border.xAnchorOffset,
        yAnchorOffset: border.yAnchorOffset,
        width: game.getReal(border.width, scale),
        height: game.getReal(border.height, scale),
        _bumpPropertiesAdded: true,
      }

      if(app.hitTestPoint(event.center, body, true) == false) {
        return 0
      }

      this.states.dragging = true
      this.states.touchstart = event
    })

    game.hammer.on('panmove', event => {
      if(this.states.dragging == false) {
        return 0
      }

      content.y = border.toLocal(event.center).y - border.toLocal(this.states.touchstart.center).y + this.states.modifylocation

      const pointerY = content.y * this.states.ratio * -1

      if(pointerY < 0) {
        pointer.y = 0
      }

      else if(pointerY > 800 - pointer.height) {
        pointer.y = 800 - pointer.height
      }

      else {
        pointer.y = pointerY
      }
    })

    game.hammer.on('panend', event => {
      if(this.states.dragging == false) {
        return 0
      }

      const { app } = this
      const { states } = this

      const reachedup = R.gt(content.y, states.uplocation)
      const reacheddown = R.lt(content.y, states.downlocation)

      if(reachedup) {
        app.slide(content, content.x, states.uplocation, 8, 'acceleration')
      }

      else if(reacheddown) {
        app.slide(content, content.x, states.downlocation, 8, 'acceleration')
      }

      states.dragging = false
      states.touchend = event
      states.modifylocation = reachedup ? states.uplocation : reacheddown ? states.downlocation : content.y
    })

    this.sprites = {
      mask,
      border,
      content,
      scrollbar,
      pointer,
    }

    this.root = app.group(
      mask,
      border,
      content,
      scrollbar,
      pointer,
    )
  }

  geneItem(data) {
    const { app, game, parentStates } = this

    if(data == null) {
      return app.group(app.sprite('bag_box.png'))
    }

    const root = app.group()

    return R.tap(root => {
      const panel = app.sprite('bag_box.png')
      const selected = app.sprite('bag_box_set.png')
      const icon = app.sprite(`${game.profiles.oss}/${data.shopUrl}`)
      const number = app.create(() => new PIXI.Text(data.shopNum, { fontSize: 24, fontFamily: 'sans', fontWeight: 'bold', fill: '#fff', stroke: '#0091d3', strokeThickness: 6 }))

      selected.interactive = true

      icon.alpha = 0
      selected.alpha = 0

      icon.width = 140
      icon.height = 140

      icon.x = panel.halfWidth - icon.halfWidth
      icon.y = panel.halfHeight - icon.halfHeight

      selected.x = panel.halfWidth - selected.halfWidth
      selected.y = panel.halfHeight - selected.halfHeight

      number.x = panel.width - number.width - 16
      number.y = 16

      root.add(panel)
      root.add(selected)
      root.add(icon)
      root.add(number)

      app.charm.wait(300).then(() => app.fadeIn(icon, 8))

      if(parentStates?.selected?.id == data.id) {
        selected.alpha = 1

        this.resetPrev = () => {
          selected.alpha = 0
        }
      }

      selected.on('tap', () => {
        if(parentStates?.selected?.id == data.id) {
          selected.alpha = 0
          parentStates.selected = null
        }

        else {
          if(this.resetPrev) {
            this.resetPrev()
          }

          selected.alpha = 1
          parentStates.selected = data

          this.resetPrev = () => {
            selected.alpha = 0
          }
        }
      })
    }, root)
  }

  onDestroyed() {
    this.game.hammer.off('panstart')
    this.game.hammer.off('panmove')
    this.game.hammer.off('panend')
  }

  async update(data: any[], exclude = []) {
    const ids = R.pluck('id', exclude.filter(R.identity))

    const items = data.filter(e => R.any(id => e.id == id, ids) == false)

    const { app, states, sprites } = this

    await new Promise(resolve => (app.fadeOut(sprites.content, 8)).onComplete = () => resolve(null))

    sprites.content.remove(...sprites.content.children)
    sprites.content.add(app.grid(4, 12, 160, 160, true, 0, 0, index => this.geneItem(index >= items.length ? null : items[index])))

    sprites.content.x = sprites.border.halfWidth - sprites.content.halfWidth
    sprites.content.y = 0
    sprites.pointer.y = 0

    states.modifylocation = sprites.content.y
    states.uplocation = sprites.content.y
    states.downlocation = -(Math.max(sprites.content.height, sprites.border.height) - Math.min(sprites.content.height, sprites.border.height) - sprites.border.y + 3)

    if(sprites.content.height < sprites.border.height) {
      states.downlocation = 0
    }

    await new Promise(resolve => (app.fadeIn(sprites.content, 8)).onComplete = () => resolve(null))
  }
}

class Summary extends Component {
  constructor(app, game, scale, states) {
    super()

    this.app = app
    this.game = game
    this.scale = scale

    const panel = app.sprite('bag_des.png')

    const title = app.create(() => new PIXI.Text('', { fontSize: 40, fontFamily: 'sans', fontWeight: 'bold', fill: '#333', leading: 12 }))
    const content = app.create(() => new PIXI.Text('', { fontSize: 32, fontFamily: 'sans', fontWeight: 'normal', fill: '#333', leading: 12 }))

    this.sprites = {
      panel,
      title,
      content,
    }

    this.root = app.group(
      panel,
      title,
      content,
    )

    this.clear = mobx.reaction(() => states.selected, data => {
      if(data) {
        title.text = data.shopName ?? ''
        content.text = format(data.shopDetail ?? '')

        title.x = panel.halfWidth - title.halfWidth
        title.y = 16

        content.x = panel.halfWidth - content.halfWidth
        content.y = title.y + title.height + 12
      }

      else {
        title.text = ''
        content.text = ''
      }
    })
  }
}

export default class Dialog extends Modal {
  takeReject: any
  takeResolve: any

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
    this.scale = (game.screenHeight - game.screenHeight * 0.2) / 1575
  }

  async load(type: number) {
    const result = await service(api).depository({
      type,
      page: 1,
      size: 10e6,
    })

    Object.assign(this.states, {
      list: result.data.list
    })
  }

  async take() {
    return new Promise((resolve, reject) => {
      this.takeReject = reject
      this.takeResolve = resolve
    })
  }

  render(hideTab = false, type = 1, isTake = false, exclude = [], selected) {
    const { app, game, scale } = this

    const states = mobx.observable({
      list: [],
      actived: type,
      selected: selected,
    })

    this.states = states

    const main = app.group()
    const handles = app.group()

    const mask = app.sprite('shade.png')
    const panel = app.sprite('bag_panel.png')

    const confirm = app.sprite('bag_btn_ok.png')
    const cancel = app.sprite('bag_btn_back.png')

    const tabs = new Tabs(app, game, scale, states)
    const content = new Content(app, game, scale, states)
    const summary = new Summary(app, game, scale, states)

    game.setRealSize(tabs, scale * 1.0)
    game.setRealSize(panel, scale * 1.0)
    game.setRealSize(confirm, scale * 1.0)
    game.setRealSize(cancel, scale * 1.0)
    game.setRealSize(content, scale * 1.0)
    game.setRealSize(summary, scale * 1.0)

    handles.add(cancel)
    handles.add(confirm)

    main.add(panel)
    main.add(handles)

    main.add(tabs.root)
    main.add(content.root)
    main.add(summary.root)

    mask.interactive = true
    cancel.interactive = true
    confirm.interactive = true

    mask.width = game.screenWidth
    mask.height = game.screenHeight

    cancel.x = confirm.x + confirm.width + game.getReal(30, scale)
    cancel.y = 0

    tabs.x = game.getReal(460, scale)
    tabs.y = game.getReal(188, scale)

    content.x = game.getReal(40, scale)
    content.y = game.getReal(300, scale)

    summary.x = panel.halfWidth - summary.halfWidth + game.getReal(20, scale)
    summary.y = game.getReal(1200, scale)

    handles.x = panel.halfWidth - handles.halfWidth + game.getReal(20, scale)
    handles.y = summary.y + summary.height + game.getReal(30, scale)

    main.x = game.halfWidth - main.halfWidth
    main.y = game.halfHeight - main.halfHeight

    game.setAnchor(confirm)
    game.enableButton(confirm, () => {
      if(this.takeResolve) {
        this.takeResolve(states.selected)
      }

      this.hide().then(() => this.destroy())
    })

    game.setAnchor(cancel)
    game.enableButton(cancel, () => {
      if(this.takeReject) {
        this.takeReject()
      }

      this.hide().then(() => this.destroy())
    }, 'close')

    this.sprites = {
      mask,
      main,
    }

    this.root = app.group(
      mask,
      main,
    )

    this.clear = R.call(() => {
      const list = mobx.reaction(() => states.list, () => content.update(states.list, exclude))
      const load = mobx.reaction(() => states.actived, () => this.load(states.actived))
      const actived = mobx.reaction(() => states.actived, () => {
        tabs.active(states.actived)
        states.selected = null
      })

      return () => {
        list()
        load()
        actived()
      }
    })

    if(hideTab) {
      tabs.alpha = 0
      tabs.interactiveChildren = false
    }

    this.load(states.actived)

    return this.show().then(() => {
      tabs.onDidMount()
      content.onDidMount()
      summary.onDidMount()

      this.root.on('removed', () => this.clear())
      this.root.on('removed', () => tabs.onDestroyed())
      this.root.on('removed', () => content.onDestroyed())
      this.root.on('removed', () => summary.onDestroyed())
    }).then(() => isTake ? this.take() : Promise.resolve())
  }
}
