import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Lutris extends Component {
  show(status: string) {
    if(status == 'normal') {
      this.sprites.shyhi.visible = false
      this.sprites.stand.visible = false
      this.sprites.think.visible = false
      this.sprites.greet.visible = false
      this.sprites.brush.visible = false

      this.sprites.shyhi.stopAnimation()
      this.sprites.stand.stopAnimation()
      this.sprites.think.stopAnimation()
      this.sprites.greet.stopAnimation()
      this.sprites.brush.stopAnimation()
    }

    else if (status == 'stand') {
      this.sprites.shyhi.visible = false
      this.sprites.stand.visible = true
      this.sprites.think.visible = false
      this.sprites.greet.visible = false
      this.sprites.brush.visible = false

      this.sprites.shyhi.stopAnimation()
      this.sprites.stand.playAnimation()
      this.sprites.think.stopAnimation()
      this.sprites.greet.stopAnimation()
      this.sprites.brush.stopAnimation()
    }

    else if (status == 'shyhi') {
      this.game.sound.shy.play()
      this.sprites.shyhi.visible = true
      this.sprites.stand.visible = false
      this.sprites.think.visible = false
      this.sprites.greet.visible = false
      this.sprites.brush.visible = false

      this.sprites.shyhi.playAnimation()
      this.sprites.stand.stopAnimation()
      this.sprites.think.stopAnimation()
      this.sprites.greet.stopAnimation()
      this.sprites.brush.stopAnimation()
    }

    else if (status == 'think') {
      this.sprites.shyhi.visible = false
      this.sprites.stand.visible = false
      this.sprites.think.visible = true
      this.sprites.greet.visible = false
      this.sprites.brush.visible = false

      this.sprites.shyhi.stopAnimation()
      this.sprites.stand.stopAnimation()
      this.sprites.think.playAnimation()
      this.sprites.greet.stopAnimation()
      this.sprites.brush.stopAnimation()
    }

    else if (status == 'greet') {
      this.sprites.shyhi.visible = false
      this.sprites.stand.visible = false
      this.sprites.think.visible = false
      this.sprites.greet.visible = true
      this.sprites.brush.visible = false

      this.sprites.shyhi.stopAnimation()
      this.sprites.stand.stopAnimation()
      this.sprites.think.stopAnimation()
      this.sprites.greet.playAnimation()
      this.sprites.brush.stopAnimation()
    }

    else if (status == 'brush') {
      this.sprites.shyhi.visible = false
      this.sprites.stand.visible = false
      this.sprites.think.visible = false
      this.sprites.greet.visible = false
      this.sprites.brush.visible = true

      this.sprites.shyhi.stopAnimation()
      this.sprites.stand.stopAnimation()
      this.sprites.think.stopAnimation()
      this.sprites.greet.stopAnimation()
      this.sprites.brush.playAnimation()
    }

    return new Promise(resolve => this.sprites.brush.onComplete = resolve)
  }

  constructor(app, game) {
    super()

    const shyhi = app.sprite(R.times((i: number) => `animate_shy_${numeral(i + 1).format('000')}.png`, 75))
    const stand = app.sprite(R.times((i: number) => `animate_stand_${numeral(i + 1).format('000')}.png`, 125))
    const think = app.sprite(R.times((i: number) => `animate_think_${numeral(i + 1).format('000')}.png`, 146))
    const greet = app.sprite(R.times((i: number) => `animate_greet_${numeral(i + 1).format('000')}.png`, 91))
    const brush = app.sprite(R.times((i: number) => `animate_brush_teeth_${numeral(i + 1).format('000')}.png`, 120))

    shyhi.fps = 26
    stand.fps = 26
    think.fps = 26
    greet.fps = 26
    brush.fps = 26

    shyhi.visible = false
    stand.visible = false
    think.visible = false
    greet.visible = false
    brush.visible = false

    shyhi.loop = false
    think.loop = false
    greet.loop = false
    stand.loop = false
    brush.loop = false

    shyhi.interactive = true
    stand.interactive = true
    think.interactive = true
    greet.interactive = true
    brush.interactive = true

    stand.onComplete = () => this.states.status = app.randomInt(1, 10) > 5 ? 'greet' : 'think'
    shyhi.onComplete = () => this.states.status = app.randomInt(1, 10) > 5 ? 'stand' : 'stand'
    think.onComplete = () => this.states.status = app.randomInt(1, 10) > 5 ? 'stand' : 'stand'
    greet.onComplete = () => this.states.status = app.randomInt(1, 10) > 5 ? 'stand' : 'stand'

    shyhi.stopAnimation()
    stand.stopAnimation()
    think.stopAnimation()
    greet.stopAnimation()
    brush.stopAnimation()

    game.setRealSize(shyhi, game.scale * 0.7 * 0.95)
    game.setRealSize(stand, game.scale * 0.7 * 1.00)
    game.setRealSize(think, game.scale * 0.7 * 1.05)
    game.setRealSize(greet, game.scale * 0.7 * 1.00)
    game.setRealSize(brush, game.scale * 0.7 * 1.00)

    shyhi.x = game.getReal(15 * 1, game.scale * 0.7 * 0.95)
    shyhi.y = game.getReal(12 * 1, game.scale * 0.7 * 0.95)

    think.x = game.getReal(20 * -1, game.scale * 0.7 * 1.05)
    think.y = game.getReal(48 * -1, game.scale * 0.7 * 1.05)

    this.app = app
    this.game = game

    this.sprites = { shyhi, stand, think, greet, brush }
    this.root = app.group( shyhi, stand, think, greet, brush )

    this.states = mobx.observable({ status: 'normal' })
    this.clear = mobx.reaction(() => mobx.toJS(this.states), states => this.show(states.status))

    shyhi.on('tap', () => this.states.status = 'shyhi')
    stand.on('tap', () => this.states.status = 'shyhi')
    think.on('tap', () => this.states.status = 'shyhi')
    greet.on('tap', () => this.states.status = 'shyhi')
  }

  onDestroyed() {
    this.clear()
  }
}
