export default store => async function identity(request) {
  const uid = store.identity.id
  const token = store.identity.token
  const pretoken = store.profiles.token

  if(store.identity.token) {
    request.headers = Object.assign({}, request.headers, {
      token: store.identity.token,
    })
  }

  if(store.identity.identifier && R.test(new RegExp(APP_ENV.api, 'g'), request.url)){
    request.headers = Object.assign({}, request.headers, {
      identifier: store.identity.identifier
    })
  }

  const formatParams = R.compose(
    R.set(R.lensProp('t'), token || pretoken),
    R.set(R.lensProp('nonce'), Math.random().toString(36).slice(2)),
  )

  const formatRest = R.compose(
    R.replace(/:uid/g, uid),
    R.replace(/:token/g, token),
    R.replace(/:pretoken/g, pretoken),
  )

  return Object.assign(request, {
    rest: formatRest(request.rest),
    params: formatParams(request.params),
  })
}
