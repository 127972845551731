import Stage from './stage'

export default class Modal extends Stage {
  scale: number

  get main() {
    return this.sprites.main
  }

  get mask() {
    return this.sprites.mask
  }

  get width() {
    return this.sprites.main.width
  }

  get height() {
    return this.sprites.main.height
  }

  get halfWidth() {
    return this.sprites.main.halfWidth
  }

  get halfHeight() {
    return this.sprites.main.halfHeight
  }

  show(): Promise<any> {
    const { app } = this

    this.main.pivot.x = this.halfWidth
    this.main.pivot.y = this.halfHeight

    this.main.x = this.main.x + this.halfWidth
    this.main.y = this.main.y + this.halfHeight

    this.mask.alpha = 0
    this.main.alpha = 0

    this.main.scale.x = 0.6
    this.main.scale.y = 0.6

    return Promise.all([
      new Promise(resolve => (app.fadeIn(this.mask, 8)).onComplete = () => resolve(null)),
      new Promise(resolve => (app.fadeIn(this.main, 16)).onComplete = () => resolve(null)),
      new Promise(resolve => (app.scaler(this.main, 1, 1, 16)).onComplete = () => resolve(null)),
    ])
  }

  hide(): Promise<any> {
    const { app } = this

    return Promise.all([
      new Promise(resolve => (app.fadeOut(this.mask, 8)).onComplete = () => resolve(null)),
      new Promise(resolve => (app.fadeOut(this.main, 16)).onComplete = () => resolve(null)),
      new Promise(resolve => (app.scaler(this.main, 0.6, 0.6, 16)).onComplete = () => resolve(null)),
    ])
  }
}
