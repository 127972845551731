import Fish from '@components/fish'
import Shell from '@components/shell'
import Submenu from '@components/submenu'

import Stage from '@prototype/stage'
import Component from '@prototype/component'

async function cover(app, game) {
  const renderTexture = PIXI.RenderTexture.create({ width: game.screenWidth, height: game.screenHeight })

  app.renderer.render(app.stage, { renderTexture })

  const cover = app.create(() => PIXI.Sprite.from(app.renderer.extract.image(renderTexture)))
  const mask = app.rectangle(game.screenWidth, game.screenHeight, '#fff')

  renderTexture.destroy()

  cover.alpha = 0
  mask.alpha = 0
  mask.interactive = true

  await new Promise(resolve => (app.fadeIn(mask, 16)).onComplete = () => resolve(0))
  await new Promise(resolve => (app.fadeOut(mask, 16)).onComplete = () => resolve(0))

  game.stages.share.render(cover).then(() => app.remove(mask))
}

export default class Menu extends Component {
  get padding() {
    const sum = R.compose(
      R.subtract(R.__, this.sprites.fish.width),
      R.subtract(R.__, this.sprites.main.width),
      R.subtract(R.__, this.sprites.shell.width),
      R.subtract(R.__, this.sprites.status.width),
    )

    return sum(this.game.screenWidth) * 0.2
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game

    this.sprites = {}
    this.root = app.group()

    this.sprites.fish = new Fish(app, game)
    this.sprites.shell = new Shell(app, game)
    this.sprites.submenu = new Submenu(app, game)

    this.sprites.main = app.sprite('menu_btn_all.png')
    this.sprites.hint = app.sprite('menu_btn_hint.png')
    this.sprites.share = app.sprite('menu_btn_share.png')
    this.sprites.status = app.sprite('menu_btn_status.png')

    this.sprites.main.interactive = true
    this.sprites.hint.interactive = true
    this.sprites.share.interactive = false
    this.sprites.status.interactive = true

    this.sprites.share.visible = false

    game.setRealSize(this.sprites.main)
    game.setRealSize(this.sprites.hint)
    game.setRealSize(this.sprites.share)
    game.setRealSize(this.sprites.status)

    this.sprites.status.x = this.padding
    this.sprites.status.y = game.getReal(30)

    this.sprites.share.x = this.sprites.status.x + (this.sprites.status.width - this.sprites.share.width) / 2
    this.sprites.share.y = this.sprites.status.y + this.sprites.status.height

    this.sprites.main.x = game.screenWidth - this.padding - this.sprites.main.width
    this.sprites.main.y = game.getReal(30)

    this.sprites.shell.x = this.padding + this.sprites.status.width + this.sprites.status.x
    this.sprites.shell.y = game.getReal(30)

    this.sprites.fish.x = this.padding + this.sprites.shell.width + this.sprites.shell.x
    this.sprites.fish.y = game.getReal(30)

    this.sprites.hint.x = this.sprites.shell.x - game.getReal(30)
    this.sprites.hint.y = this.sprites.shell.y + this.sprites.shell.height + game.getReal(30)

    this.sprites.submenu.x = this.sprites.main.x + (this.sprites.main.halfWidth - this.sprites.submenu.halfWidth) * 0.5
    this.sprites.submenu.y = 0

    game.setAnchor(this.sprites.share)
    game.enableButton(this.sprites.share, () => cover(app, game))

    game.setAnchor(this.sprites.hint)
    game.enableButton(this.sprites.hint, () => game.modals.email.render())

    game.setAnchor(this.sprites.status)
    game.enableButton(this.sprites.status, () => game.modals.status.render())

    game.setAnchor(this.sprites.main)
    game.enableButton(this.sprites.main, () => Object.assign(game.states, { expand: true }))

    this.root.add(this.sprites.main)
    this.root.add(this.sprites.hint)
    this.root.add(this.sprites.share)
    this.root.add(this.sprites.status)

    this.root.add(this.sprites.fish.root)
    this.root.add(this.sprites.shell.root)
    this.root.add(this.sprites.submenu.root)

    this.clear = mobx.autorun(() => {
      this.sprites.fish.number = game.identity?.user?.fishCount ?? 0
      this.sprites.shell.number = game.identity?.user?.financialCount ?? 0

      if(game.states.expand){
        this.sprites.main.visible = false
        this.sprites.submenu.visible = true
      }

      else {
        this.sprites.main.visible = true
        this.sprites.submenu.visible = false
      }

      if(game.identity.award) {
        this.sprites.hint.visible = true
      }

      else {
        this.sprites.hint.visible = false
      }
    })
  }

  onDestroyed() {
    this.clear()
  }
}
