import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Item extends Component {
  constructor(app, game) {
    super()

    const none = app.sprite('stage_contest_item_panel_none.png')
    const used = app.sprite('stage_contest_item_panel_used.png')
    const panel = app.sprite('stage_contest_item_panel_locked.png')

    used.alpha = 0
    none.alpha = 0

    used.x = panel.halfWidth - used.halfWidth
    used.y = panel.halfHeight - used.halfHeight

    none.x = panel.halfWidth - none.halfWidth
    none.y = panel.halfHeight - none.halfHeight

    this.sprites = {
      panel,
      none,
      used,
    }

    this.root = app.group(
      panel,
      none,
      used,
    )
  }
}
