import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Hints extends Component {
  constructor(app, game) {
    super()

    const shop = app.sprite('stage_home_pop.png')
    const lapt = app.sprite('stage_home_pop.png')
    const book = app.sprite('stage_home_pop.png')
    const star = app.sprite('stage_home_pop.png')

    game.setRealSize(shop)
    game.setRealSize(lapt)
    game.setRealSize(book)
    game.setRealSize(star)

    game.setAnchor(shop)
    game.setAnchor(lapt)
    game.setAnchor(book)
    game.setAnchor(star)

    app.breathe(shop, game.getReal(0.85), game.getReal(0.85), 36, true)
    app.breathe(lapt, game.getReal(0.85), game.getReal(0.85), 36, true)
    app.breathe(book, game.getReal(0.85), game.getReal(0.85), 36, true)
    app.breathe(star, game.getReal(0.85), game.getReal(0.85), 36, true)

    shop.interactive = true
    lapt.interactive = true
    book.interactive = true
    star.interactive = true

    shop.x = game.getReal(260)
    shop.y = game.getReal(1445)

    lapt.x = game.getReal(888)
    lapt.y = game.getReal(1170)

    book.x = game.getReal(770)
    book.y = game.getReal(970)

    star.x = game.getReal(365)
    star.y = game.getReal(932)

    shop.on('tap', () => game.modals.store.render())
    star.on('tap', () => game.modals.stars.render('postcard'))
    book.on('tap', () => game.modals.stars.render('knowledgecard'))
    lapt.on('tap', () => game.stages.home.hide().then(() => game.stages.contest.render()).then(() => game.music.normal.stop()).then(() => game.music.contest.play()).then(() => game.stages.home.destroy()))

    this.root = app.group(shop, lapt, book, star)
  }
}
