import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Financial extends Component {
  get number() {
    return this.sprites.number.text
  }

  set number(value) {
    this.sprites.number.text = `×${value > 10000 ? (value / 10000).toFixed(2) + 'w' : value}`
  }

  constructor(app, game) {
    super()

    const panel = app.sprite('menu_part_shell.png')
    const number = app.text(`×0`, '14px serif', '#824237')

    game.setRealSize(panel)

    panel.x = game.getReal(0)
    panel.y = game.getReal(0)

    number.x = game.getReal(80)
    number.y = game.getReal(42)

    this.sprites = {
      panel,
      number,
    }

    this.root = app.group(
      panel,
      number
    )
  }
}
