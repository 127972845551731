import api from '@sar/http'
import service from '@services/out'
import fishingService from '@services/fishing'

import Menu from '@components/menu'
import Email from '@components/emial'
import Lutris from '@components/lutris'

import Stage from '@prototype/stage'
import Component from '@prototype/component'

export default class Page extends Stage {
  query: any
  countdown: any

  get isNight() {
    return moment().isBefore(`${moment().format('YYYY-MM-DD')} 06:00`) || moment().isAfter(`${moment().format('YYYY-MM-DD')} 19:00`)
  }

  constructor(app, game) {
    super()

    this.app = app
    this.game = game
  }

  update() {
    const { game, sprites, components } = this
    const { time, partner, partnerhint } = sprites

    sprites.fishingA.update()
    sprites.fishingB.update()

    if(this.countdown) {
      clearInterval(this.countdown)
    }

    if(game.identity?.outing) {
      partner.visible = false
      partnerhint.visible = false
      sprites.schoolbag.visible = false

      time.interactive = true
      partner.interactive = false
      partnerhint.interactive = false

      components.lutris.visible = false

      const data = game.identity?.out

      if(data == null) {
        return 0
      }

      if(moment(data.endTime).isBefore(moment())) {
        return 0
      }

      const duration = moment.duration(moment(data.endTime).diff(moment()))

      time.update({
        hour: numeral(duration.hours()).format('00'),
        minute: numeral(duration.minutes()).format('00'),
        second: numeral(duration.seconds()).format('00'),
      })

      time.visible = true

      this.countdown = setInterval(() => {
        if(moment(data.endTime).isBefore(moment())) {
          return 0
        }

        const duration = moment.duration(moment(data.endTime).diff(moment()))

        time.update({
          hour: numeral(duration.hours()).format('00'),
          minute: numeral(duration.minutes()).format('00'),
          second: numeral(duration.seconds()).format('00'),
        })
      }, 1000)
    }

    else {
      time.visible = false
      partner.visible = true
      partnerhint.visible = true
      sprites.schoolbag.visible = true

      time.interactive = false
      partner.interactive = true
      partnerhint.interactive = true

      components.lutris.visible = true

      components.lutris.show('stand')
    }

    // 显示夜晚场景
    if(this.isNight) {
      sprites.stage.show(1)
      sprites.partner.show(1)
      sprites.snowman.show(1)
      sprites.bulletin.show(1)
      sprites.schoolbag.show(1)
      components.email.show(1)

      components.lutris.visible = false

      if(game.identity?.outing) {
        sprites.bonfire.show(3)
        sprites.waterice.show(3)
      }

      else {
        sprites.bonfire.show(2)
        sprites.waterice.show(2)
      }

      // A收获点满了的情况
      if(game.identity?.fishing?.a?.isMax == 'yes') {
        sprites.fishing.show(3)
        sprites.fishingA.interactive = true
      }

      // A收获点有鱼的情况
      else if(game.identity?.fishing?.a?.fishCount > 0) {
        sprites.fishing.show(5)
        sprites.fishingA.interactive = true
      }

      // A收获点无鱼的情况
      else {
        sprites.fishing.show(4)
        sprites.fishingA.interactive = false
      }

      // B收获点满了的情况
      if(game.identity?.fishing?.b?.isMax == 'yes') {
        sprites.clothesline.show(3)
        sprites.fishingB.interactive = true
      }

      // B收获点有鱼的情况
      else if(game.identity?.fishing?.b?.fishCount > 0) {
        sprites.clothesline.show(5)
        sprites.fishingB.interactive = true
      }

      // B收获点无鱼的情况
      else {
        sprites.clothesline.show(4)
        sprites.fishingB.interactive = false
      }
    }

    else {
      sprites.stage.show(0)
      sprites.partner.show(0)
      sprites.snowman.show(0)
      sprites.bulletin.show(0)
      sprites.schoolbag.show(0)
      components.email.show(0)

      if(game.identity.outing) {
        sprites.bonfire.show(1)
        sprites.waterice.show(1)
      }

      else {
        sprites.bonfire.show(0)
        sprites.waterice.show(0)
      }

      // A收获点满了的情况
      if(game.identity?.fishing?.a?.isMax == 'yes') {
        sprites.fishing.show(0)
        sprites.fishingA.interactive = true
      }

      // A收获点有鱼的情况
      else if(game.identity?.fishing?.a?.fishCount > 0) {
        sprites.fishing.show(2)
        sprites.fishingA.interactive = true
      }

      // A收获点无鱼的情况
      else {
        sprites.fishing.show(1)
        sprites.fishingA.interactive = false
      }

      // B收获点满了的情况
      if(game.identity?.fishing?.b?.isMax == 'yes') {
        sprites.clothesline.show(0)
        sprites.fishingB.interactive = true
      }

      // B收获点有鱼的情况
      else if(game.identity?.fishing?.b?.fishCount > 0) {
        sprites.clothesline.show(2)
        sprites.fishingB.interactive = true
      }

      // B收获点无鱼的情况
      else {
        sprites.clothesline.show(1)
        sprites.fishingB.interactive = false
      }
    }
  }

  geneTime() {
    const { app } = this

    const root = app.group()
    const time = app.group()
    const panel = app.sprite('goout_time_panel.png')

    const hour = app.create(() => new PIXI.Text('小时', { fontSize: 24, fontWeight: 'bold', fill: '#fff', stroke: '#5988cd', strokeThickness: 6, letterSpacing: 2 }))
    const minute = app.create(() => new PIXI.Text('分', { fontSize: 24, fontWeight: 'bold', fill: '#fff', stroke: '#5988cd', strokeThickness: 6, letterSpacing: 2 }))
    const second = app.create(() => new PIXI.Text('秒', { fontSize: 24, fontWeight: 'bold', fill: '#fff', stroke: '#5988cd', strokeThickness: 6, letterSpacing: 2 }))

    const hournum = app.create(() => new PIXI.Text('00', { fontSize: 40, fontWeight: 'bold', fill: '#fff', stroke: '#5988cd', strokeThickness: 6, letterSpacing: 2 }))
    const minutenum = app.create(() => new PIXI.Text('00', { fontSize: 40, fontWeight: 'bold', fill: '#fff', stroke: '#5988cd', strokeThickness: 6, letterSpacing: 2 }))
    const secondnum = app.create(() => new PIXI.Text('00', { fontSize: 40, fontWeight: 'bold', fill: '#fff', stroke: '#5988cd', strokeThickness: 6, letterSpacing: 2 }))

    time.add(hour)
    time.add(minute)
    time.add(second)
    time.add(hournum)
    time.add(minutenum)
    time.add(secondnum)

    root.add(panel)
    root.add(time)

    hournum.x = 0
    hournum.y = 0

    hour.x = hournum.x + hournum.width
    hour.y = hournum.y + hournum.height - hour.height - 4

    minutenum.x = hour.x + hour.width + 12
    minutenum.y = 0

    minute.x = minutenum.x + minutenum.width
    minute.y = minutenum.y + minutenum.height - minute.height - 4

    secondnum.x = minute.x + minute.width + 12
    secondnum.y = 0

    second.x = secondnum.x + secondnum.width
    second.y = secondnum.y + secondnum.height - second.height - 4

    time.x = panel.halfWidth - time.halfWidth
    time.y = panel.height - time.height - 48

    return Object.assign(root, {
      update: ({ hour, minute, second }) => {
        hournum.text = hour
        minutenum.text = minute
        secondnum.text = second
      }
    })
  }

  render() {
    const { app, game } = this

    const time = this.geneTime()
    const doormask = app.rectangle(145, 214, 'black')

    const fishingA = R.call(() => {
      const text = game.identity?.fishing?.a?.isMax == 'yes' ? '已经\n满啦' : `已收获\n${game.identity?.fishing?.a?.fishCount ?? 0}`

      const marker = app.sprite('stage_outdoor_timemark.png')
      const content = app.create(() => new PIXI.Text(text, { fontSize: 24, fontWeight: 'bold', fill: '#fff', align: 'center', leading: 3 }))

      content.x = marker.halfWidth - content.halfWidth
      content.y = marker.halfHeight - content.halfHeight + 4

      return Object.assign(app.group(
        marker,
        content,
      ), {
        update: () => {
          content.text = game.identity?.fishing?.a?.isMax == 'yes' ? '已经\n满啦' : `已收获\n${game.identity?.fishing?.a?.fishCount ?? 0}`
          content.x = marker.halfWidth - content.halfWidth
          content.y = marker.halfHeight - content.halfHeight + 4
        }
      })
    })

    const fishingB = R.call(() => {
      const text = game.identity?.fishing?.b?.isMax == 'yes' ? '已经\n满啦' : `已收获\n${game.identity?.fishing?.b?.fishCount ?? 0}`
      const marker = app.sprite('stage_outdoor_timemark.png')
      const content = app.create(() => new PIXI.Text(text, { fontSize: 24, fontWeight: 'bold', fill: '#fff', align: 'center', leading: 3 }))

      content.x = marker.halfWidth - content.halfWidth
      content.y = marker.halfHeight - content.halfHeight + 4

      return Object.assign(app.group(
        marker,
        content,
      ), {
        update: () => {
          content.text = game.identity?.fishing?.b?.isMax == 'yes' ? '已经\n满啦' : `已收获\n${game.identity?.fishing?.b?.fishCount ?? 0}`
          content.x = marker.halfWidth - content.halfWidth
          content.y = marker.halfHeight - content.halfHeight + 4
        }
      })
    })

    const mailhint = app.sprite('stage_outdoor_mail_pop.png')
    const partnerhint = app.sprite('stage_outdoor_partner_pop.png')
    const bulletinhint = app.sprite('stage_outdoor_bulletin_pop.png')

    const stage = app.sprite(['stage_outdoor_day_bg.png', 'stage_outdoor_night_bg.png'])
    const partner = app.sprite(['stage_outdoor_day_partner.png', 'stage_outdoor_night_partner.png'])
    const snowman = app.sprite(['stage_outdoor_day_snowman.png', 'stage_outdoor_night_snowman.png'])
    const bulletin = app.sprite(['stage_outdoor_day_bulletin.png', 'stage_outdoor_night_bulletin.png'])
    const schoolbag = app.sprite(['stage_outdoor_day_schoolbag.png', 'stage_outdoor_night_schoolbag.png'])

    // const swim = app.sprite(R.times((i: number) => `animate_swim_${numeral(R.inc(i)).format('000')}.png`, 180))
    // const stand = app.sprite(R.times((i: number) => `animate_stand_${numeral(R.inc(i)).format('000')}.png`, 125))

    const bonfire = app.sprite([
      'stage_outdoor_day_bonfire1.png',
      'stage_outdoor_day_bonfire2.png',
      'stage_outdoor_night_bonfire1.png',
      'stage_outdoor_night_bonfire2.png',
    ])

    const waterice = app.sprite([
      'stage_outdoor_day_waterice1.png',
      'stage_outdoor_day_waterice2.png',
      'stage_outdoor_night_waterice1.png',
      'stage_outdoor_night_waterice2.png',
    ])

    const fishing = app.sprite([
      'stage_outdoor_day_fishing1.png',
      'stage_outdoor_day_fishing2.png',
      'stage_outdoor_day_fishing3.png',
      'stage_outdoor_night_fishing1.png',
      'stage_outdoor_night_fishing2.png',
      'stage_outdoor_night_fishing3.png',
    ])

    const clothesline = app.sprite([
      'stage_outdoor_day_clothesline1.png',
      'stage_outdoor_day_clothesline2.png',
      'stage_outdoor_day_clothesline3.png',
      'stage_outdoor_night_clothesline1.png',
      'stage_outdoor_night_clothesline2.png',
      'stage_outdoor_night_clothesline3.png',
    ])

    stage.loop = false
    snowman.loop = false
    partner.loop = false
    fishing.loop = false
    bulletin.loop = false
    schoolbag.loop = false
    bonfire.loop = false
    waterice.loop = false
    clothesline.loop = false

    time.interactive = true
    stage.interactive = true
    bulletin.interactive = true
    doormask.interactive = true
    mailhint.interactive = true
    partnerhint.interactive = true
    bulletinhint.interactive = true

    stage.width = game.getReal(stage.width) * 2
    stage.height = game.getReal(stage.height) * 2

    partner.width = game.getReal(partner.width)
    partner.height = game.getReal(partner.height)

    snowman.width = game.getReal(snowman.width)
    snowman.height = game.getReal(snowman.height)

    schoolbag.width = game.getReal(schoolbag.width)
    schoolbag.height = game.getReal(schoolbag.height)

    bulletin.width = game.getReal(bulletin.width)
    bulletin.height = game.getReal(bulletin.height)

    bonfire.width = game.getReal(bonfire.width)
    bonfire.height = game.getReal(bonfire.height)

    waterice.width = game.getReal(waterice.width)
    waterice.height = game.getReal(waterice.height)

    fishing.width = game.getReal(fishing.width)
    fishing.height = game.getReal(fishing.height)

    clothesline.width = game.getReal(clothesline.width)
    clothesline.height = game.getReal(clothesline.height)

    doormask.width = game.getReal(doormask.width)
    doormask.height = game.getReal(doormask.height)

    fishingA.width = game.getReal(fishingA.width)
    fishingA.height = game.getReal(fishingA.height)

    fishingB.width = game.getReal(fishingB.width)
    fishingB.height = game.getReal(fishingB.height)

    mailhint.width = game.getReal(mailhint.width)
    mailhint.height = game.getReal(mailhint.height)

    partnerhint.width = game.getReal(partnerhint.width)
    partnerhint.height = game.getReal(partnerhint.height)

    bulletinhint.width = game.getReal(bulletinhint.width)
    bulletinhint.height = game.getReal(bulletinhint.height)

    time.width = game.getReal(time.width)
    time.height = game.getReal(time.height)

    partner.x = game.getReal(-80)
    partner.y = game.getReal(1700)

    snowman.x = game.getReal(100)
    snowman.y = game.getReal(630)

    schoolbag.x = game.getReal(400)
    schoolbag.y = game.getReal(1150)

    bulletin.x = game.getReal(760)
    bulletin.y = game.getReal(605)

    bonfire.x = game.getReal(505)
    bonfire.y = game.getReal(816)

    waterice.x = game.getReal(75)
    waterice.y = game.getReal(974)

    clothesline.x = game.getReal(560)
    clothesline.y = game.getReal(950)

    fishing.x = game.getReal(80)
    fishing.y = game.getReal(1350)

    doormask.alpha = 0
    doormask.x = game.getReal(500)
    doormask.y = game.getReal(580)

    mailhint.x = game.getReal(274)
    mailhint.y = game.getReal(500)

    fishingA.x = game.getReal(300)
    fishingA.y = game.getReal(1380)

    fishingB.x = game.getReal(800)
    fishingB.y = game.getReal(1180)

    partnerhint.x = game.getReal(62)
    partnerhint.y = game.getReal(1660)

    bulletinhint.x = game.getReal(916)
    bulletinhint.y = game.getReal(530)

    time.x = game.getReal(60)
    time.y = game.getReal(1780)

    mailhint.anchor.x = 0.5
    mailhint.anchor.y = 0.5

    partnerhint.anchor.x = 0.5
    partnerhint.anchor.y = 0.5

    bulletinhint.anchor.x = 0.5
    bulletinhint.anchor.y = 0.5

    // 修正设置 anchor 带来的坐标偏移
    mailhint.x = mailhint.x + mailhint.halfWidth
    mailhint.y = mailhint.y + mailhint.halfHeight

    partnerhint.x = partnerhint.x + partnerhint.halfWidth
    partnerhint.y = partnerhint.y + partnerhint.halfHeight

    bulletinhint.x = bulletinhint.x + bulletinhint.halfWidth
    bulletinhint.y = bulletinhint.y + bulletinhint.halfHeight

    app.slide(fishingA, fishingA.x, fishingA.y + 8, 120, 'smoothstep', true, 300)
    app.slide(fishingB, fishingB.x, fishingB.y + 8, 120, 'smoothstep', true, 600)

    app.breathe(mailhint, game.getReal(0.85), game.getReal(0.85), 30, true)
    app.breathe(partnerhint, game.getReal(0.85), game.getReal(0.85), 30, true)
    app.breathe(bulletinhint, game.getReal(0.85), game.getReal(0.85), 30, true)

    partnerhint.on('tap', () => game.modals.goout.render())
    bulletinhint.on('tap', () => game.modals.tasks.render())

    fishingA.on('tap', () => fishingService(api).complete({ type: 'A' }).then(result => game.modals.reward.render(result.data.fishCount)).then(() => {
      game.sound.reward.play()
      game.refreshUserData()
      game.refreshFishingData()
    }))

    fishingB.on('tap', () => fishingService(api).complete({ type: 'B' }).then(result => game.modals.reward.render(result.data.fishCount)).then(() => {
      game.sound.reward.play()
      game.refreshUserData()
      game.refreshFishingData()
    }))

    time.on('tap', () => game.modals.gohome.render())
    mailhint.on('tap', () => game.modals.email.render())
    doormask.on('tap', () => Promise.resolve(game.sound.cutover.play()).then(() => this.hide()).then(() => game.stages.home.render()).then(() => game.music.normal.pause()).then(() => game.music.normal.play()).then(() => this.destroy()))

    this.sprites = {
      stage,
      partner,
      snowman,
      bulletin,
      bonfire,
      waterice,
      schoolbag,
      clothesline,
      fishing,
      doormask,
      mailhint,
      partnerhint,
      bulletinhint,
      time,
      fishingA,
      fishingB,
    }

    this.root = app.group(
      stage,
      snowman,
      bulletin,
      bonfire,
      waterice,
      schoolbag,
      clothesline,
      fishing,
      partner,
      doormask,
      mailhint,
      partnerhint,
      bulletinhint,
      time,
      fishingA,
      fishingB,
    )

    const menu = new Menu(app, game)
    const email = new Email(app, game)
    const lutris = new Lutris(app, game)

    this.root.x = 0
    this.root.y = game.offset < -60 ? -40 : game.offset

    menu.x = 0
    menu.y = Math.abs(game.offset < -60 ? -40 : game.offset)

    email.x = game.getReal(350)
    email.y = game.getReal(800) - email.sprites.box.height

    lutris.x = game.getReal(200)
    lutris.y = game.getReal(1000)

    this.components = {
      menu,
      email,
      lutris,
    }

    this.root.add(menu.root)
    this.root.add(email.root)
    this.root.add(lutris.root)

    this.clear = mobx.autorun(() => {
      this.update()
    })

    game.refreshUserData()
    game.refreshOutData()
    game.refreshFishingData()

    return this.show().then(() => {
      menu.onDidMount()
      email.onDidMount()

      this.root.on('removed', () => this.clear())
      this.root.on('removed', () => menu.onDestroyed())
      this.root.on('removed', () => email.onDestroyed())
      this.root.on('removed', () => clearInterval(this.countdown))
    })
  }
}
